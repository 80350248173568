import { FAQWrapperType } from '../../types/Types'
import DropDownArrow from './DropDownArrow'

type DropDownWrapperType = {
    content?: FAQWrapperType
}

function DropdownWrapper({ content }: DropDownWrapperType) {
    return (
        <div className="max-w-screen-xl mx-auto w-full py-16">
            <h3 className="text-4xl text-secondary mb-4 px-4 md:px-16 min-[1400px]:px-0 ">{content?.heading}</h3>
            <DropDownArrow faqs={content?.question_and_answer} />
        </div>
    )
}

export default DropdownWrapper
