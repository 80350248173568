import React from 'react';

type CheckboxType = {
    label: React.ReactNode;
    name: string;
    value: string;
    checkedValue: string;
    setCheckedValue: (name: string, value: string) => void;
    dark?: boolean;
};

function Checkbox({
    label,
    name,
    value,
    checkedValue,
    setCheckedValue,
    dark = true,
}: CheckboxType) {

    const checkState = () => checkedValue === value;


    const handleClick = () => {
        // Wenn die Checkbox bereits markiert ist, deaktivieren
        if (checkState()) {
            setCheckedValue(name, '');
        } else {
            // Andernfalls aktivieren und den Wert setzen
            setCheckedValue(name, value);
        }
    };


    return (
        <label className="inline-flex items-center cursor-pointer space-x-3">
            <input
                type="checkbox"
                name={name}
                value={value}
                onChange={handleClick}
                checked={checkState()}
                className="sr-only"
            />
            <div
                className={`min-w-[18px] min-h-[18px] rounded-full border-2 flex justify-center items-center ${
                    checkState()
                        ? 'bg-secondary ' + (dark ? 'border-white' : 'border-black')
                        : dark
                        ? 'bg-primary border-white'
                        : 'bg-white border-black'
                }`}
            >
            </div>
            <span className={`${dark ? 'text-white' : 'text-black'}`}>
                {label}
            </span>
        </label>
    );
}

export default Checkbox;
