import React, { useEffect, useState } from 'react'
import { SliderType, VerstossType } from '../../../types/Types'
import axios from 'axios'
import { API } from '../../../services/constants'

type ScreenMobileAuswahlType = {
    handleSelectScreenType: (e: VerstossType) => void
}

function ScreenMobileAuswahl({ handleSelectScreenType }: ScreenMobileAuswahlType) {
    const [slider, setSlider] = useState<SliderType>()

    useEffect(() => {
        axios
            .get(API.general + '/verstoss-slider?populate[slider_content][populate][icon]=*')
            .then((res) => {
                if (res?.data?.data) {
                    setSlider(res.data.data)
                }
            })
            .catch((err) => console.log(err))
    }, [])

    return (
        <div className="flex w-full h-full p-0 items-center justify-center overflow-y-scroll pl-2">
            <div className="py-4 flex flex-col items-center rounded-md md:overflow-hidden gap-4 w-full h-full grow">
                {slider?.attributes?.slider_content.map((slide) => (
                    <div
                        key={slide.id}
                        className="flex py-2 px-4 border-1 border border-primary rounded-lg space-x-6 cursor-pointer w-full"
                        onClick={() => {
                            console.log('handlescreenselect type', handleSelectScreenType)
                            if (handleSelectScreenType) {
                                handleSelectScreenType(slide.violation_name as VerstossType)
                            }
                        }}>
                        <div className=" flex-shrink-0 flex items-center justify-center active:bg-base-200-focus rounded-md">
                            <img className="w-10 h-10" src={API.img + slide.icon.data.attributes.url} alt={slide.icon.data.attributes.name} />
                        </div>
                        <div className=" float-right w-full rounded-md flex flex-col justify-center font-medium">
                            {slide.icon.data.attributes.caption}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ScreenMobileAuswahl
