import { HashLink } from 'react-router-hash-link'

// Define the menu items as an array of objects
const menuItems = [
    {
        id: 'geschwindigkeitsverstoss',
        link: '/geschwindigkeitsverstoss',
        modalId: 9,
        name: 'Geschwindigkeitsverstoß',
    },
    {
        id: 'alkoholverstoss',
        modalId: 11,
        link: '/alkoholdelikte',
        name: 'Alkoholverstoß',
    },
    {
        id: 'rotlichtverstoss',
        modalId: 3,
        link: '/rotlichtverstoss',
        name: 'Rotlichtverstoß',
    },
    {
        id: 'abstandsverstoss',
        modalId: 4,
        link: '/abstandsverstoss',
        name: 'Abstandsverstoß',
    },
    {
        id: 'handyverstoss',
        modalId: 5,
        link: '/handyverstoss',
        name: 'Handyverstoß',
    },
    {
        id: 'parkundabschleppmassnahmen',
        link: '/park-abschleppmassnahmen',
        modalId: 7,
        name: 'Park- und Abschleppmaßnahmen',
    },
    {
        id: 'illegalesautorennen',
        modalId: 12,
        link: '/illegale-autorennen',
        name: 'Illegale Autorennen',
    },
    {
        id: 'sonstigedelikte',
        modalId: 6,
        link: '/anderer-verstoss',
        name: 'Sonstiger Verstoß',
    },
]

function DropdownMenu() {
    return (
        <div className="absolute pt-3 -right-12">
            <div className="bg-primary text-black shadow-lg rounded-3xl normal-case">
                <ul className="divide-y divide-white">
                    {menuItems.map((item, index) => (
                        <HashLink key={index} to={item.link + '#_'} className="block w-full h-full text-white text-left">
                            <li key={item.id} className="hover:bg-secondary px-4 py-2">
                                {item.name}
                            </li>
                        </HashLink>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default DropdownMenu
