import RadioButton from '../../general/RadioButton'
import { HashLink } from 'react-router-hash-link'
import { MdInfoOutline } from 'react-icons/md'
import Select from '../../general/Select'
import TextArea from '../../general/TextArea'
import { InfoDataType, ScreenType } from '../../../types/Types'
import Checkbox from '../../general/Checkbox'

function ScreenAbstandsverstoss({ infoData, setInfoData, dark }: ScreenType) {
    const setCheckedValue = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenAbstandsverstoss: {
                ...prevInfoData.screenAbstandsverstoss,
                [name]: value,
            },
        }))
    }

    const handlePrivacyAndDeadlineChange = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenPersonendaten: {
                ...prevInfoData.screenPersonendaten,
                [name]: value,
            },
        }))
    }

    const handleSelectChange = (
        selectedValue: string,
        selectName: 'abstandsunterschreitung' | 'geschwindigkeit'
    ) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenAbstandsverstoss: {
                ...prevInfoData.screenAbstandsverstoss,
                [selectName]: selectedValue,
            },
        }))
    }

    const handleTextAreaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { value } = e.target
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenAbstandsverstoss: {
                ...prevInfoData.screenAbstandsverstoss,
                note: value,
            },
        }))
    }

    const selectOptionsAbst = [
        { label: '5/10', value: '5/10' },
        { label: '4/10', value: '4/10' },
        { label: '3/10', value: '3/10' },
        { label: '2/10', value: '2/10' },
        { label: '1/10', value: '1/10' },
    ]

    const selectOptionsGeschw = [
        { label: 'bis 80 km/h', value: '0 bis 80 km/h' },
        { label: '81 - 100 km/h', value: '81 bis 100 km/h' },
        { label: '101 - 130 km/h', value: '101 bis 130 km/h' },
        { label: 'über 130 km/h', value: 'mehr als 130 km/h' },
    ]

    return (
        <div className="pr-2 overflow-y-scroll relative">
            <h5 className="w-full pt-2 text-xl pb-2 font-semibold">
                Angaben zum Verstoß
            </h5>

            <div className="flex flex-col md:grid md:grid-cols-5 md:gap-6 ">
                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Unterschreitung des Abstands?*</p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2 pb-4 md:pb-0">
                    <Select
                        placeholder="Bitte Auswählen ..."
                        options={selectOptionsAbst}
                        handleSelectChange={(value) =>
                            handleSelectChange(value, 'abstandsunterschreitung')
                        }
                        selectedValue={
                            infoData.screenAbstandsverstoss
                                .abstandsunterschreitung || ''
                        }
                        dark={dark}
                    />
                </div>

                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Gefahrene Geschwindigkeit?*</p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2 pb-4 md:pb-0">
                    <Select
                        placeholder="Bitte Auswählen ..."
                        options={selectOptionsGeschw}
                        handleSelectChange={(value) =>
                            handleSelectChange(value, 'geschwindigkeit')
                        }
                        selectedValue={
                            infoData.screenAbstandsverstoss.geschwindigkeit ||
                            ''
                        }
                        dark={dark}
                    />
                </div>

                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Sind Sie beruflich auf den Führerschein angewiesen?*</p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2 pb-2 md:pb-0">
                    <RadioButton
                        label="Ja"
                        name="fuehrerschein"
                        value="Ja"
                        checkedValue={
                            infoData.screenAbstandsverstoss.fuehrerschein || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Nein"
                        name="fuehrerschein"
                        value="Nein"
                        checkedValue={
                            infoData.screenAbstandsverstoss.fuehrerschein || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3"></div>
                <div className="col-span-2 pb-4 md:pb-0">
                    <TextArea
                        placeholder="Liegen Besonderheiten vor ?"
                        handleTextAreaChange={handleTextAreaChange}
                        value={infoData.screenAbstandsverstoss.note || ''}
                        dark={dark}
                    />
                </div>

                <div className="col-span-3  pb-2 md:pb-0">
                    <p>Welche Dokumente wurden Ihnen bereits zugestellt?</p>
                </div>
                <div className="flex flex-col gap-2 ">
                    <Checkbox
                        label="Zeugenfragebogen"
                        name="zeugenfragebogen"
                        value="Zeugenfragebogen"
                        checkedValue={
                            infoData.screenAbstandsverstoss.zeugenfragebogen ||
                            ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Anhörungsbogen"
                        name="anhoerungsbogen"
                        value="Anhörungsbogen"
                        checkedValue={
                            infoData.screenAbstandsverstoss.anhoerungsbogen ||
                            ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Bußgeldbescheid"
                        name="bussgeldbescheid"
                        value="Bußgeldbescheid"
                        checkedValue={
                            infoData.screenAbstandsverstoss.bussgeldbescheid ||
                            ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
            </div>
            <div className="mt-16 space-y-4">
                <div>
                    <RadioButton
                        label={
                            <span>
                                Sie haben unsere{' '}
                                <HashLink
                                    to="/datenschutz"
                                    className={`underline ${
                                        dark
                                            ? 'text-gray-400 visited:text-white'
                                            : 'text-gray-700 visited:text-gray-400'
                                    }" target="_blank" rel="noopener noreferrer`}>
                                    Datenschutzbestimmungen
                                </HashLink>{' '}
                                gelesen *
                            </span>
                        }
                        name="datenschutz"
                        value="datenschutz"
                        checkedValue={
                            infoData.screenPersonendaten.datenschutz || ''
                        }
                        setCheckedValue={handlePrivacyAndDeadlineChange}
                        dark={dark}
                    />
                </div>
                <div>
                    <RadioButton
                        label={
                            <span>
                                Sie haben die Informationen zu unserer{' '}
                                <HashLink
                                    to="/bearbeitungsfrist"
                                    className={`underline ${
                                        dark
                                            ? 'text-gray-400 visited:text-white'
                                            : 'text-gray-700 visited:text-gray-400'
                                    }" target="_blank" rel="noopener noreferrer`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Bearbeitungsfrist
                                </HashLink>{' '}
                                gelesen *
                            </span>
                        }
                        name="bearbeitungsfrist"
                        value="bearbeitungsfrist"
                        checkedValue={
                            infoData.screenPersonendaten.bearbeitungsfrist || ''
                        }
                        setCheckedValue={handlePrivacyAndDeadlineChange}
                        dark={dark}
                    />
                </div>
                <div className="flex items-center">
                    <div className="px-3 scale-125">
                        <MdInfoOutline />
                    </div>
                    <div>*Pflichtangaben</div>
                </div>
            </div>
        </div>
    )
}

export default ScreenAbstandsverstoss
