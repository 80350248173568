import { useKeycloak } from '@react-keycloak/web'

function Button(props: { text: string }) {
    const { keycloak } = useKeycloak()
    return (
        <div
            onClick={() =>
                keycloak.login({
                    redirectUri: window.location.origin + '/portal',
                })
            }
            className="bg-secondary cursor-pointer text-white rounded-2xl py-2 px-6 text-lg active:scale-pressed duration-100 ease-in-out text-center">
            {props.text}
        </div>
    )
}

export default Button
