import dayjs from "dayjs";
import { InfoDataType } from "../../types/Types";

type formatInfoDataType = {
    infoData: InfoDataType;
}
type KeyReplacementType = {
    [key: string]: string;
};

const keyReplacements: KeyReplacementType = {
    // Geschwindigkeitsverstoss 
    ort: "Wo",
    fahrzeugtyp: "Fahrzeugtyp",
    geschwindigkeitsueberschreitung: "Geschwindigkeitsüberschreitung",
    oft: 'In den letzten 12 Monaten mit über 12km/h zu viel geblitzt?',
    fuehrerschein: 'Beruflich Angewiesen?',
    documents: 'Vorliegende Dokumente',
    // Alkoholverstoss 
    artTest: 'Gemessener Wert',
    wert: 'Wert',
    unfall: 'Unfall',
    doks: 'Vorliegende Dokumente',
    fuehrerschein_beschlagnahmt: 'Führerschein beschlagnahmt?',
    fahrerlaubnis: 'Vorläufige Entziehung der Fahrerlaubnis?',
    // Abstandsverstoss 
    abstandsunterschreitung: 'Abstandsunterschreitung',
    geschwindigkeit: 'Gefahrene Geschwindigkeit',
    // personendaten
    erreichbarkeit: 'Erreichbar',
    // Park- und Abschleppmaßnahmen
    documentDate: 'Zustellung der Dokumente',
    // Illegale Autorennen
    fuehrerscheinentzug: "Fühererschein entzogen?",
    entziehungsbeschluss: "Beschluss über vorzeitige Führerscheinentziehung",
    // Dokumente
    zeugenfragebogen: 'Zeugenfragebogen',
    anhoerungsbogen: 'Anhörungsbogen',
    bussgeldbescheid: 'Bußgeldbescheid',
    strafbefehl: 'Strafbefehl',
    anklageschrift: 'Anklageschrift',
    // Rotlichtverstoß
    gefaehrdung: 'Gefährdung von Personen?',
    rot: 'Wie lang war die Ampel rot'

};

const documentKeys = ["zeugenfragebogen", "anhoerungsbogen","bussgeldbescheid","strafbefehl","anklageschrift"]

const skipKeys = [
    "firstName",
    "lastName",
    "phonenumber",
    "email",
    "erreichbarkeit",
    "rechtsschutz",
    "datenschutz",
    "bearbeitungsfrist",
    "street",
    "nr",
    "plz",
    "city"
  ];
  

export function formatInfoData({ infoData }: formatInfoDataType) {
    let formattedInfo: string[] = [];
    let noteValue = "";

    Object.entries(infoData).forEach(([screenKey, screenValues]) => {
        Object.entries(screenValues).forEach(([key, value]) => {
            if (value) {
                if (key === "note") {
                    noteValue = `${value}`;
                } else if (key === "documentDate") {
                    let documentDate = dayjs(value, 'YYYY-MM-DD').format('DD.MM.YYYY')
                    const customString = keyReplacements[key] || key;
                    formattedInfo.push(`${customString}: ${documentDate}`);

                } else if (skipKeys.includes(key)) {
                } else if (documentKeys.includes(key)) {
                    const customString = keyReplacements[key] || key;
                    formattedInfo.push(customString + " erhalten")
                }
                else {
                    const customString = keyReplacements[key] || key;
                    formattedInfo.push(`${customString}: ${value}`);

                }
            }
        });
    });

    return { formattedInfo, noteValue };
}