import AboutSubPage from '../../components/general/AboutSubPage'
import PopUpStart from '../../components/general/PopUpStart'
import Seperator from '../../components/general/Seperator'
import Slider from '../../components/general/Slider'
import HeroSubpage from '../../components/general/HeroSubpage'
import DropdownWrapper from '../../components/general/DropdownWrapper'
import HowItWorks from '../../components/general/HowItWorks'
import SubPagePopup from '../../components/general/SubPagePopup'
import ErrorPage from '../404/ErrorPage'
import Loading from '../../components/common/Loading'
import { useQuery } from '@tanstack/react-query'
import { getAnderesData, getHowItWorksData, getPopUpImgData, getSliderData } from '../../hooks/cmsApiCalls'
import BaseSeo from '../../components/layout/BaseSeo'

function AndererVerstoss() {
    const { error, data, isLoading } = useQuery({
        queryKey: ['anderesData'],
        queryFn: getAnderesData,
    })

    const {
        error: howItWorksError,
        data: howItWorksData,
        isLoading: howItWorksLoading,
    } = useQuery({
        queryKey: ['howItWorksData'],
        queryFn: getHowItWorksData,
    })

    const {
        error: sliderError,
        data: sliderData,
        isLoading: sliderLoading,
    } = useQuery({
        queryKey: ['sliderData'],
        queryFn: getSliderData,
    })

    const {
        error: popupImgError,
        data: popupImgData,
        isLoading: popupImgLoading,
    } = useQuery({
        queryKey: ['popupImgData'],
        queryFn: getPopUpImgData,
    })

    return (
        <>
            {(error || sliderError || howItWorksError || popupImgError) && <ErrorPage />}
            {(isLoading || sliderLoading || howItWorksLoading || popupImgLoading) && <Loading />}
            {data && sliderData && howItWorksData && popupImgData && (
                <>
                    <BaseSeo seo={data?.attributes?.seo} />
                    <HeroSubpage content={data} />
                    <Slider slides={sliderData || []} />
                    <AboutSubPage content={data} />
                    <div className="hidden md:block w-full">
                        <Seperator content={data?.attributes?.separator}/>
                    </div>
                    <DropdownWrapper content={data?.attributes.faq_sonstiges} />
                    <PopUpStart imageUrl={String(popupImgData?.attributes.image.data.attributes.url)} verstoss="sonstigedelikte" />
                    <HowItWorks content={howItWorksData} />
                    <SubPagePopup firstScreenType="sonstigedelikte" />
                </>
            )}
        </>
    )
}

export default AndererVerstoss
