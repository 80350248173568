import { ReactNode, useState } from 'react'
import Header from '../common/Header'
import SidePanel from '../general/SidePanel'
import Footer from '../common/Footer'
import { useQuery } from '@tanstack/react-query'
import { getInheritContent } from '../../hooks/cmsApiCalls'

export type BaseLayoutType = {
    title?: string

    seo?: {
        metaTitle?: string
        metaDescription?: string
        canonicalURL?: string
        keywords?: any
        metaRobots?: any
    }
    children: ReactNode
}

export default function BaseLayout({ seo, children }: BaseLayoutType) {
    const [visibleSidepanel, setVisibleSidepanel] = useState<boolean>(false)

    const { data: content } = useQuery({
        queryFn: getInheritContent,
        queryKey: ['inheritContentData'],
    })

    return (
        <section className="grow w-full flex flex-col justify-center items-center relative">
            <SidePanel condition={visibleSidepanel} handleOpen={setVisibleSidepanel} />
            <Header logos={content} />
            <div className="h-20 hidden md:flex" />
            {children}
            <Footer />
        </section>
    )
}
