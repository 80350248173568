import BasePanelHeadline from '../../components/BasePanelHeadline'
import BasePanelLayout from '../../components/BasePanelLayout'
import { useContext, useEffect, useState } from 'react'
import { adressType, personalType2 } from '../../types/Types'
import BaseInputObjectField from '../../components/BaseInputObjectField'
import { useRequestToDeleteMutation, useUpdatePersonalDataMutation } from '../../api/services'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getOneUser } from '../../api/apiCalls'
import ErrorPage from '../../../pages/404/ErrorPage'
import Loading from '../../../components/common/Loading'
import dayjs from 'dayjs'
import BaseActionCheckModal from '../../components/BaseActionCheckModal'
import { ModalContext } from '../../../components/contexts/ModalContext'
import { useCookies } from 'react-cookie'

export default function UserProfile() {
    const { data, isLoading, error } = useQuery({
        queryKey: ['oneUser'],
        queryFn: getOneUser,
    })
    // set cookie of request to delete
    const [cookies, setCookie] = useCookies(['RequestToDeleteAccount'])
    const [cookieTrue, setcookieTrue] = useState<boolean>(false)
    const setCookieWithExpiry = () => {
        const expires = new Date()
        expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000) // 24 Stunden in Millisekunden

        setCookie('RequestToDeleteAccount', true, { path: '/', expires })
    }

    useEffect(() => {
        const checkCookie = () => {
            const cookieValue = cookies.RequestToDeleteAccount

            if (cookieValue) {
                setcookieTrue(true)
            }
        }

        checkCookie()
    }, [cookies])

    const [userData, setUserData] = useState<personalType2>({
        first_name: '',
        last_name: '',
        mail: '',
        tel: '',
        insurance: false,
        adress: {
            street: '',
            nr: '',
            plz: '',
            city: '',
        },
        callOption: 0,
    })

    const modalCon = useContext(ModalContext)
    const queryClient = useQueryClient()
    const { mutate: sendReq } = useRequestToDeleteMutation(queryClient)
    const { mutate: updateUser } = useUpdatePersonalDataMutation(queryClient)
    const handleRequstToDelete = () => {
        setCookieWithExpiry()
        sendReq({
            topic: 'profile',
            _id: data._id,
            personal: {
                first_name: data.personal.first_name,
                last_name: data.personal.last_name,
            },
        })
        modalCon?.trigger(0)
    }
    const handleUpdateSend = () => {
        updateUser({
            uid: data._id,
            personal: { personal: userData },
        })
    }

    useEffect(() => {
        if (data) {
            setUserData(data?.personal)
        }
    }, [data])

    const updateProfile = (key: keyof personalType2 | keyof adressType, value: string | number, nestedKey?: keyof personalType2) => {
        setUserData((prevData) => {
            if (nestedKey && nestedKey in prevData) {
                return {
                    ...prevData,
                    [nestedKey]: {
                        ...(prevData[nestedKey] as any),
                        [key]: value,
                    },
                }
            }
            return {
                ...prevData,
                [key]: value,
            }
        })
    }

    return (
        <BasePanelLayout>
            {error && <ErrorPage />}
            {isLoading && <Loading />}
            {userData && !error && !isLoading && data && (
                <div className="w-full h-full bg-portal-gray flex flex-col p-8 items-center">
                    <BasePanelHeadline title={'Profil'} />
                    <p className="text-sm text-primary font-semibold text-right w-full max-w-screen-lg">
                        letztes Update: <span>{dayjs(data?.updated_at).format('DD.MM.YYYY HH:mm')} Uhr </span>
                    </p>
                    {/* NAME */}
                    <div className="flex flex-row gap-4 w-full max-w-screen-lg">
                        <BaseInputObjectField
                            classProps="bg-portal-gray"
                            label={'Vorname'}
                            type={'text'}
                            placeholder={'Text'}
                            value={userData?.first_name}
                            setvalue={(e: any) => updateProfile('first_name', e.target.value)}
                        />
                        <BaseInputObjectField
                            classProps="bg-portal-gray"
                            label={'Nachname'}
                            type={'text'}
                            placeholder={'Text'}
                            value={userData.last_name}
                            setvalue={(e: any) => updateProfile('last_name', e.target.value)}
                        />
                    </div>
                    {/* ADRESS */}
                    <div className="flex flex-row gap-4 w-full max-w-screen-lg">
                        <BaseInputObjectField
                            classProps="bg-portal-gray"
                            label={'Straße'}
                            type={'text'}
                            placeholder={'Nachname'}
                            value={userData.adress.street}
                            setvalue={(e: any) => updateProfile('street', e.target.value, 'adress')}
                        />
                        <BaseInputObjectField
                            classProps="bg-portal-gray"
                            label={'Nummer'}
                            type={'text'}
                            placeholder={'Nr. 12'}
                            value={userData.adress.nr}
                            setvalue={(e: any) => updateProfile('nr', Number(e.target.value), 'adress')}
                        />
                        <BaseInputObjectField
                            classProps="bg-portal-gray"
                            label={'Postleitzahl'}
                            type={'text'}
                            placeholder={'01234'}
                            value={userData.adress.plz}
                            setvalue={(e: any) => updateProfile('plz', Number(e.target.value), 'adress')}
                        />
                        <BaseInputObjectField
                            classProps="bg-portal-gray"
                            label={'Stadt'}
                            type={'text'}
                            placeholder={'Stadt'}
                            value={userData.adress.city}
                            setvalue={(e: any) => updateProfile('city', e.target.value, 'adress')}
                        />
                    </div>
                    {/* CONTACT */}
                    <div className="flex flex-row gap-4 w-full max-w-screen-lg">
                        <BaseInputObjectField
                            classProps="bg-portal-gray"
                            label={'Telefon'}
                            type={'text'}
                            placeholder={'0123456789'}
                            value={userData.tel}
                            setvalue={(e: any) => updateProfile('tel', e.target.value)}
                        />
                        <BaseInputObjectField
                            classProps="bg-portal-gray"
                            label={'E-Mail'}
                            type={'text'}
                            placeholder={'max@mustermann.de'}
                            value={userData.mail}
                            setvalue={(e: any) => updateProfile('mail', e.target.value)}
                        />
                    </div>
                    {/* CALL */}
                    <div className="flex flex-row justify-evenly w-full max-w-screen-lg py-8">
                        <div className="flex gap-3 items-center">
                            <input
                                type="radio"
                                name={'callOption'}
                                className="radio radio-primary border-2"
                                checked={userData.callOption === 1}
                                onChange={() => updateProfile('callOption', 1)}
                            />
                            <label>Morgens (8-11 Uhr)</label>
                        </div>
                        <div className="flex gap-3 items-center">
                            <input
                                type="radio"
                                name={'callOption'}
                                className="radio radio-primary border-2"
                                checked={userData.callOption === 2}
                                onChange={() => updateProfile('callOption', 2)}
                            />
                            <label>Mittag (11-14 Uhr)</label>
                        </div>
                        <div className="flex gap-3 items-center">
                            <input
                                type="radio"
                                name={'callOption'}
                                className="radio radio-primary border-2"
                                checked={userData.callOption === 3}
                                onChange={() => updateProfile('callOption', 3)}
                            />
                            <label>Nachmittag (14-17 Uhr)</label>
                        </div>
                        <div className="flex gap-3 items-center">
                            <input
                                type="radio"
                                name={'callOption'}
                                className="radio radio-primary border-2"
                                checked={userData.callOption === 4}
                                onChange={() => updateProfile('callOption', 4)}
                            />
                            <label>Abends (17-20 Uhr)</label>
                        </div>
                    </div>

                    <div className="w-full max-w-screen-lg flex justify-between">
                        <button disabled={cookieTrue} className="btn btn-accent text-base-100" onClick={() => modalCon?.trigger(0)}>
                            Profillöschung beantragen
                        </button>
                        {userData !== data?.personal && (
                            <button className="btn btn-success" onClick={handleUpdateSend}>
                                Speichern
                            </button>
                        )}
                    </div>
                    <BaseActionCheckModal
                        modalId={0}
                        title={'Profillöschung beantragen'}
                        buttonTitle={'Beantragen'}
                        text={
                            'Sind Sie sicher, dass Sie eine Profillöschung beantragen wollen? Im Falle einer Bestätigung werden alle Ihre Fälle und Zugangsdaten gelöscht. Eventuell noch offene Kosten können trotzdem anfallen.'
                        }
                        function={handleRequstToDelete}
                    />
                </div>
            )}
        </BasePanelLayout>
    )
}
