import { BACKEND } from '../../services/constants'
import { axiosInstance } from '../security/axiosInstance'
import { personalType2 } from '../types/Types'

export const getAllUser = async () =>
    axiosInstance.get(BACKEND + '/user/get/all').then((res) => res.data)

export const getOneUser = async () =>
    axiosInstance.get(BACKEND + '/user/get/one').then((res) => res.data)

export const getAllNews = async () =>
    axiosInstance.get(BACKEND + '/news/get/all').then((res) => res.data)

export const deleteNew = async (id: string) =>
    axiosInstance.delete(BACKEND + '/news/read/' + id).then((res) => res.data)

export const getNewCases = async () =>
    axiosInstance.get(BACKEND + '/user/new/cases').then((res) => res.data)

export const updateCaseStatus = async (data: {
    uid: string
    cid: string
    status: string
}) =>
    axiosInstance
        .patch(
            BACKEND + '/user/case/update/status/' + data.uid + '/' + data.cid,
            { status: data.status }
        )
        .then((res) => res.data)

export const updateUserStatus = async (data: { uid: string; status: string }) =>
    axiosInstance
        .patch(BACKEND + '/user/status/update/' + data.uid, {
            status: data.status,
        })
        .then((res) => res.data)

export const addUser = async (data: { formData: FormData }) => {
    return axiosInstance
        .post(BACKEND + '/user/add', data.formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => res.data)
}

export const addCaseToUser = async (data: { formData: FormData }) =>
    axiosInstance
        .patch(BACKEND + '/user/update/cases', data.formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => res.data)

export const deleteCase = async (data: { uid: string; cid: string }) =>
    axiosInstance
        .patch(BACKEND + '/user/case/delete/' + data.uid + '/' + data.cid)
        .then((res) => res.data)

export const denyCase = async (data: { uid: string; cid: string }) =>
    axiosInstance
        .patch(BACKEND + '/user/case/deny/' + data.uid + '/' + data.cid)
        .then((res) => res.data)


export const deleteUser = async (data: { uid: string }) =>
    axiosInstance
        .delete(BACKEND + '/user/delete/' + data.uid)
        .then((res) => res.data)

export const addMessage = async (data: {
    uid: string
    cid: string
    message: { sender: string; message: string; timestamp: string }
}) =>
    axiosInstance
        .patch(
            BACKEND + '/user/chat/message/add/' + data.uid + '/' + data.cid,
            data.message
        )
        .then((res) => res.data)

export const postNewDocToCase = async (data: {
    uid: string
    cid: string
    data: any
}) =>
    axiosInstance
        .patch(
            BACKEND + '/user/upload/doc/' + data.uid + '/' + data.cid,
            data.data,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then((res) => res.data)

export const getDocToDownload = async (
    fileId: string,
    displayFileName: string
) =>
    axiosInstance
        .get(BACKEND + `/documents/download?filename=${fileId}`, {
            responseType: 'blob',
        })
        .then(({ data }) => {
            const url = window.URL.createObjectURL(data)
            const a = document.createElement('a')
            a.href = url
            a.download = displayFileName
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            a.remove()
        })

export const deleteSingleDocument = async (data: {
    cid: string
    fid: string
}) =>
    axiosInstance
        .delete(
            BACKEND + '/documents/delete/' + data.cid + '/fileId/' + data.fid
        )
        .then((res) => res.data)

// ============================ U S E R ============================ //

export const updatePersonalUserData = async (data: {
    personal: { personal: personalType2 }
    uid: string
}) =>
    axiosInstance
        .patch(BACKEND + '/user/personal/update', data.personal)
        .then((res) => res.data)

export const deleteRequest = async (data: {
    topic: 'profile' | 'case'
    _id: string
    personal: {
        first_name: string
        last_name: string
        caseid?: string
    }
}) =>
    axiosInstance
        .post(BACKEND + '/user/request/delete/smth', data)
        .then((res) => res.data)
