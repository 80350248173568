import BaseDataCheck from '../../BaseDataCheck'
import BaseDocsSelection from '../../BaseDocsSelection'
import BaseTextAreaSpecialties from '../../BaseTextAreaSpecialties'
import BaseViolationForm from '../../BaseViolationForm'

type FormScreen2Type = {
    check1: boolean
    setcheck1: (e: boolean) => void
    check2: boolean
    setcheck2: (e: boolean) => void
    date?: string
    setdate?: (e: string) => void
    note: string
    setnote: (e: string) => void
    extend?: boolean
    inputField?: boolean
    setdoc1: (e: string) => void
    setdoc2: (e: string) => void
    setdoc3: (e: string) => void
    setdoc4?: (e: string) => void
    setdoc5?: (e: string) => void
    documents: any[]
    setdocuments: (e: any) => void
}

export default function FormScreen2({
    note,
    setnote,
    check1,
    check2,
    documents,
    date,
    inputField,
    setdate,
    setdocuments,
    setcheck1,
    setcheck2,
    setdoc1,
    setdoc2,
    setdoc3,
    setdoc4,
    setdoc5,
    extend,
}: FormScreen2Type) {
    return (
        <BaseViolationForm>
            {!extend && setnote && (
                <BaseTextAreaSpecialties
                    value={note}
                    onChange={setnote}
                    title={'Liegen Besonderheiten vor?'}
                />
            )}
            <BaseDocsSelection
                documents={documents}
                setdocuments={setdocuments}
                extend={extend ?? false}
                date={date}
                inputField={inputField}
                setdate={setdate ?? (() => {})}
                setdoc1={setdoc1}
                setdoc2={setdoc2}
                setdoc3={setdoc3}
                setdoc4={setdoc4 ?? (() => {})}
                setdoc5={setdoc5 ?? (() => {})}
            />
            <BaseDataCheck
                check1={check1}
                setcheck1={setcheck1}
                check2={check2}
                setcheck2={setcheck2}
            />
        </BaseViolationForm>
    )
}
