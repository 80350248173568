import { HomeContentChildType } from '../../types/Types'
import { API } from '../../services/constants'
import { HashLink } from 'react-router-hash-link'
import RichTextBody from './RichTextBody'

function AboutUs({ content }: HomeContentChildType) {
    // Use curly braces to destructure the 'content' prop

    if (!content) {
        return null
    }
    return (
        <div className="hidden lg:flex max-w-screen-xl items-center py-8 px-8 mb-10 space-x-12">
            <div className="w-1/2">
                <img
                    src={API.img + content.attributes.about_image.data.attributes.url}
                    alt={content.attributes.about_image.data.attributes.alternativeText}
                    className="object-cover"
                />
            </div>
            <div className="w-1/2 space-y-4">
                <h2 className="text-4xl ">{content.attributes.about_headline}</h2>
                <hr className="h-px w-4/6 my-8 bg-black border-0 " />
                <RichTextBody className="pb-4" richText={content?.attributes.about_text} />
                <HashLink to="/about#_">
                    <p className="text-secondary font-semibold">Mehr erfahren</p>
                </HashLink>
            </div>
        </div>
    )
}

export default AboutUs
