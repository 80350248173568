import { motion } from 'framer-motion'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { RBAC } from '../security/shared'
import { ReactComponent as ReqSvg } from '../img/Anfragen.svg'
import { ReactComponent as DashboardSvg } from '../img/Dashboard.svg'
import { ReactComponent as FaelleSvg } from '../img/Mandanten  Faelle.svg'
import { ReactComponent as NavSvg } from '../img/Navigation.svg'
import { ReactComponent as ProfilSvg } from '../img/Profil.svg'

export default function Sidebar() {
    const [sidePanelOpen, setsidePanelOpen] = useState(false)

    const { keycloak, initialized } = useKeycloak()

    const isAnwalt = () =>
        initialized &&
        keycloak.authenticated &&
        keycloak.hasRealmRole(RBAC.anwalt) // Make sure 'anwalt' matches the role name exactly
    const isMandant = () =>
        initialized &&
        keycloak.authenticated &&
        keycloak.hasRealmRole(RBAC.mandant) // Make sure 'anwalt' matches the role name exactly

    return (
        <motion.div
            initial={{
                width: '8rem',
            }}
            animate={{
                width: sidePanelOpen ? '16rem' : '6rem',
            }}
            transition={{
                duration: 0.5,
                type: 'ease',
            }}
            className="h-full bg-secondary flex flex-col items-center justify-between py-8 rounded-none mr-8">
            {/* ICONS ON TOP */}
            <div
                className={`flex flex-col justify-start ${
                    sidePanelOpen && 'w-44'
                }`}>
                <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={() => setsidePanelOpen(!sidePanelOpen)}>
                    <NavSvg />
                    {sidePanelOpen && (
                        <p className="text-lg font-semibold">Menü</p>
                    )}
                </div>
                {/* <Link className="flex items-center gap-2 pt-4 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.2} stroke="currentColor" className="w-9 h-9"><path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" /></svg>
                    { sidePanelOpen && <p className="text-lg font-semibold">Suche</p> }
                </Link> */}
                {isAnwalt() && (
                    <Link
                        className="flex items-center gap-2 pt-16 cursor-pointer"
                        to="/portal/dashboard">
                        <DashboardSvg />
                        {sidePanelOpen && (
                            <p className="text-lg font-semibold">Dashboard</p>
                        )}
                    </Link>
                )}
                {isAnwalt() && (
                    <Link
                        className="flex items-center gap-2 cursor-pointer pt-2"
                        to="/portal/anfragen">
                        <ReqSvg />
                        {sidePanelOpen && (
                            <p className="text-lg font-semibold">Mail</p>
                        )}
                    </Link>
                )}
                <Link
                    className="flex items-center gap-2 cursor-pointer pt-2"
                    to={
                        isAnwalt()
                            ? '/portal/mandanten'
                            : isMandant()
                            ? '/portal/faelle'
                            : ''
                    }>
                    <FaelleSvg />
                    {sidePanelOpen && (
                        <p className="text-lg font-semibold">Fälle</p>
                    )}
                </Link>
            </div>
            {/* ICONS ON BOTTOM */}
            {isMandant() && (
                <div className={`flex flex-col ${sidePanelOpen && 'w-44'}`}>
                    <Link
                        className="flex items-center gap-2 pt-4 cursor-pointer"
                        to="/portal/profil">
                        <ProfilSvg />
                        {sidePanelOpen && (
                            <p className="text-lg font-semibold">Profil</p>
                        )}
                    </Link>
                </div>
            )}
        </motion.div>
    )
}
