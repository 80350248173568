import React, { useCallback, useContext, useEffect, useState } from 'react'
import BasePanelChat from '../../components/BasePanelChat'
import BasePanelLayout from '../../components/BasePanelLayout'
import CaseList from '../../components/customer/CaseList'
import { useNavigate, useParams } from 'react-router-dom'
import { caseType2 } from '../../types/Types'
import AddCaseView from '../../components/customer/AddCaseView'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getOneUser } from '../../api/apiCalls'
import ErrorPage from '../../../pages/404/ErrorPage'
import Loading from '../../../components/common/Loading'
import BaseCaseInterface from '../../components/BaseCaseInterface'
import { ModalContext } from '../../../components/contexts/ModalContext'
import AddDocModal from '../../components/modal/AddDocModal'
import BaseActionCheckModal from '../../components/BaseActionCheckModal'
import { useRequestToDeleteMutation } from '../../api/services'
import { useCookies } from 'react-cookie'

export default function UserScreen1() {
    const { id } = useParams() // NOTE noch benötigt?

    const { data, isLoading, error } = useQuery({
        queryKey: ['oneUser'],
        queryFn: getOneUser,
    })

    const [selectedCase, setselectedCase] = useState<caseType2 | undefined>(data?.cases[0])
    useEffect(() => {
        if (data) {
            setselectedCase(data?.cases?.find((obj: caseType2) => obj._id === id) ?? data.cases[0])
        }
    }, [data, id])

    const [addCaseView, setaddCaseView] = useState<boolean>(false)
    const modalCon = useContext(ModalContext)

    // set cookie of request to delete
    const navigate = useNavigate()
    const [cookies, setCookie] = useCookies([`RequestToDeleteCase:${id}`])
    const setCookieWithExpiry = async () => {
        await new Promise<void>((resolve) => {
            const expires = new Date()
            expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000)
            setCookie(`RequestToDeleteCase:${id}`, id, { path: '/', expires })
            resolve()
        })
    }

    const [stateCookie, setstateCookie] = useState<string>()
    const checkCookie = useCallback(() => {
        if (data) {
            return (
                cookies[`RequestToDeleteCase:${id !== '1' ? id : data?.cases[0]._id}`] === (id !== '1' ? id : data?.cases[0]._id) &&
                cookies[`RequestToDeleteCase:${id !== '1' ? id : data?.cases[0]._id}`] !== undefined
            )
        }
    }, [data, id, cookies])
    // check if there is a cookie of request to delete
    useEffect(() => {
        if (id === '1' && data) {
            navigate('/portal/faelle/' + data?.cases[0]?._id)
        }
        checkCookie()
    }, [id, data])

    // User - request to delete case
    const queryClient = useQueryClient()
    const { mutate: reqToDeleteCase } = useRequestToDeleteMutation(queryClient)
    const handleDeleteCaseReq = () => {
        setCookieWithExpiry().then(() => setstateCookie(id))
        reqToDeleteCase({
            topic: 'case',
            _id: data._id,
            personal: {
                first_name: data.personal?.first_name ?? '',
                last_name: data.personal?.last_name ?? '',
                caseid: selectedCase?._id ?? '',
            },
        })
        modalCon?.trigger(5)
        checkCookie()
    }

    return (
        <>
            {error && <ErrorPage />}
            {isLoading && <Loading />}
            {!error && !isLoading && data && (
                <BasePanelLayout>
                    <CaseList cases={data.cases} selectedId={id === '1' ? data.cases[0]?._id : id ?? ''} addCase={setaddCaseView} />
                    {addCaseView ? (
                        <AddCaseView
                            setaddCaseView={() => {
                                setaddCaseView(false)
                                checkCookie()
                            }}
                        />
                    ) : (
                        <>
                            <BaseCaseInterface
                                reqDisable={checkCookie() || stateCookie === id}
                                selectedCase={selectedCase}
                                ustatus={data.status}
                                deleteCaseReq={() => modalCon?.trigger(5)}
                                openModal={() => modalCon?.trigger(2)}
                            />
                            <BasePanelChat
                                status={selectedCase?.status !== 'open'}
                                senderName="Alexander Pitzinger"
                                sender="rbac_mandant"
                                chat={selectedCase?.chat ?? []}
                                cid={selectedCase?._id ?? ''}
                                uid={data?._id}
                            />
                        </>
                    )}
                    <AddDocModal mid={2} uid={data?._id ?? ''} cid={selectedCase?._id ?? ''} modalCon={modalCon} />
                    <BaseActionCheckModal
                        modalId={5}
                        title={'Löschung beantragen'}
                        buttonTitle={'Beantragen'}
                        text={'Sind Sie sicher, dass Sie den Fall löschen lassen wollen? Es können bereits entstandene Kosten anfallen.'}
                        function={handleDeleteCaseReq}
                    />
                </BasePanelLayout>
            )}
        </>
    )
}
