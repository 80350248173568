type BaseTextAreaSpecialtiesType = {
    title: string
    heightProps?: string
    value: any
    onChange: (e: any) => void
}

export default function BaseTextAreaSpecialties({
    title,
    heightProps,
    value,
    onChange,
}: BaseTextAreaSpecialtiesType) {
    return (
        <>
            <p className="pt-8">{title}</p>
            <textarea
                onChange={(e) => onChange(e.target.value)}
                value={value}
                className={`textarea mt-2 border-2 border-primary bg-portal-gray w-full ${
                    heightProps ? heightProps : 'max-h-32 h-full'
                }`}
                placeholder="Vergehen..."></textarea>
        </>
    )
}
