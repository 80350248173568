import RadioButton from '../../general/RadioButton'
import { HashLink } from 'react-router-hash-link'
import { MdInfoOutline } from 'react-icons/md'
import Select from '../../general/Select'
import TextArea from '../../general/TextArea'
import { InfoDataType, ScreenType } from '../../../types/Types'
import Checkbox from '../../general/Checkbox'

function ScreenGeschwindigkeitsverstoss({
    infoData,
    setInfoData,
    dark,
}: ScreenType) {
    const setCheckedValue = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenGeschwindigkeitsverstoss: {
                ...prevInfoData.screenGeschwindigkeitsverstoss,
                [name]: value,
            },
        }))
    }

    const handlePrivacyAndDeadlineChange = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenPersonendaten: {
                ...prevInfoData.screenPersonendaten,
                [name]: value,
            },
        }))
    }

    const handleSelectChange = (selectedValue: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenGeschwindigkeitsverstoss: {
                ...prevInfoData.screenGeschwindigkeitsverstoss,
                geschwindigkeitsueberschreitung: selectedValue,
            },
        }))
    }

    const handleTextAreaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { value } = e.target
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenGeschwindigkeitsverstoss: {
                ...prevInfoData.screenGeschwindigkeitsverstoss,
                note: value,
            },
        }))
    }

    const selectOptions = [
        { label: '0 - 15 km/h', value: '0 bis 15' },
        { label: '16 - 20 km/h', value: '16 bis 20' },
        { label: '21 - 30 km/h', value: '21 bis 30' },
        { label: '31 - 40 km/h', value: '31 bis 40' },
        { label: 'mehr als 40 km/h', value: 'mehr als 40' },
    ]

    return (
        <div className="pr-2 overflow-y-scroll relative">
            <h5 className="w-full pt-2 text-xl pb-2 font-semibold">
                Angaben zum Verstoß
            </h5>

            <div className="flex flex-col md:grid grid-cols-5 gap-0 md:gap-6 ">
                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Ort der Geschwindigkeitsüberschreitung*</p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2 pb-4 md:pb-0">
                    <RadioButton
                        label="Innerorts"
                        name="ort"
                        value="innerorts"
                        checkedValue={
                            infoData.screenGeschwindigkeitsverstoss.ort || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Außerorts"
                        name="ort"
                        value="ausserorts"
                        checkedValue={
                            infoData.screenGeschwindigkeitsverstoss.ort || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>

                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Fahrzeugtyp*</p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2 pb-4 md:pb-0">
                    <RadioButton
                        label="PKW/Motorrad"
                        name="fahrzeugtyp"
                        value="PKW/Motorrad"
                        checkedValue={
                            infoData.screenGeschwindigkeitsverstoss
                                .fahrzeugtyp || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="LKW"
                        name="fahrzeugtyp"
                        value="LKW"
                        checkedValue={
                            infoData.screenGeschwindigkeitsverstoss
                                .fahrzeugtyp || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>

                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Geschwindigkeitsüberschreitung*</p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2 pb-4 md:pb-0">
                    <Select
                        placeholder="Bitte Auswählen ..."
                        options={selectOptions}
                        handleSelectChange={handleSelectChange}
                        selectedValue={
                            infoData.screenGeschwindigkeitsverstoss
                                .geschwindigkeitsueberschreitung || ''
                        }
                        dark={dark}
                    />
                </div>

                <div className="col-span-3 pb-2 md:pb-0 ">
                    <p>
                        Wurden Sie innerhalb der letzten 12 Monate bereits{' '}
                        <br />
                        mit mehr als 26 km/h geblitzt?*
                    </p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2 pb-4 md:pb-0">
                    <RadioButton
                        label="Ja"
                        name="oft"
                        value="Ja"
                        checkedValue={
                            infoData.screenGeschwindigkeitsverstoss.oft || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Nein"
                        name="oft"
                        value="Nein"
                        checkedValue={
                            infoData.screenGeschwindigkeitsverstoss.oft || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>

                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Sind Sie beruflich auf den Führerschein angewiesen?*</p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2 pb-2 md:pb-0">
                    <RadioButton
                        label="Ja"
                        name="fuehrerschein"
                        value="Ja"
                        checkedValue={
                            infoData.screenGeschwindigkeitsverstoss
                                .fuehrerschein || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Nein"
                        name="fuehrerschein"
                        value="Nein"
                        checkedValue={
                            infoData.screenGeschwindigkeitsverstoss
                                .fuehrerschein || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3"></div>
                <div className="col-span-2 pb-4 md:pb-0">
                    <TextArea
                        placeholder="Liegen Besonderheiten vor ?"
                        handleTextAreaChange={handleTextAreaChange}
                        value={
                            infoData.screenGeschwindigkeitsverstoss.note || ''
                        }
                        dark={dark}
                    />
                </div>

                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Welche Dokumente wurden Ihnen bereits zugestellt?</p>
                </div>
                <div className="flex flex-col gap-2">
                    <Checkbox
                        label="Zeugenfragebogen"
                        name="zeugenfragebogen"
                        value="Zeugenfragebogen"
                        checkedValue={
                            infoData.screenGeschwindigkeitsverstoss
                                .zeugenfragebogen || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Anhörungsbogen"
                        name="anhoerungsbogen"
                        value="Anhörungsbogen"
                        checkedValue={
                            infoData.screenGeschwindigkeitsverstoss
                                .anhoerungsbogen || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Bußgeldbescheid"
                        name="bussgeldbescheid"
                        value="Bußgeldbescheid"
                        checkedValue={
                            infoData.screenGeschwindigkeitsverstoss
                                .bussgeldbescheid || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
            </div>
            <div className="mt-16 space-y-4 flex flex-col">
                <div>
                    <RadioButton
                        label={
                            <span>
                                Sie haben unsere{' '}
                                <HashLink
                                    to="/datenschutz"
                                    className={`underline ${
                                        dark
                                            ? 'text-gray-400 visited:text-white'
                                            : 'text-gray-700 visited:text-gray-400'
                                    }" target="_blank" rel="noopener noreferrer`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Datenschutzbestimmungen
                                </HashLink>{' '}
                                gelesen *
                            </span>
                        }
                        name="datenschutz"
                        value="datenschutz"
                        checkedValue={
                            infoData.screenPersonendaten.datenschutz || ''
                        }
                        setCheckedValue={handlePrivacyAndDeadlineChange}
                        dark={dark}
                    />
                </div>
                <div>
                    <RadioButton
                        label={
                            <span>
                                Sie haben die Informationen zu unserer{' '}
                                <HashLink
                                    to="/bearbeitungsfrist"
                                    className={`underline ${
                                        dark
                                            ? 'text-gray-400 visited:text-white'
                                            : 'text-gray-700 visited:text-gray-400'
                                    }" target="_blank" rel="noopener noreferrer`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Bearbeitungsfrist
                                </HashLink>{' '}
                                gelesen *
                            </span>
                        }
                        name="bearbeitungsfrist"
                        value="bearbeitungsfrist"
                        checkedValue={
                            infoData.screenPersonendaten.bearbeitungsfrist || ''
                        }
                        setCheckedValue={handlePrivacyAndDeadlineChange}
                        dark={dark}
                    />
                </div>
                <div className="flex items-center">
                    <div className="px-3 scale-125">
                        <MdInfoOutline />
                    </div>
                    <div>*Pflichtangaben</div>
                </div>
            </div>
        </div>
    )
}

export default ScreenGeschwindigkeitsverstoss
