import { ReactNode } from 'react'

type BasePanelLayoutType = {
    children: ReactNode
}

export default function BasePanelLayout({ children }: BasePanelLayoutType) {
    return (
        <div className="grow h-full w-max rounded-none flex flex-row gap-4">
            {children}
        </div>
    )
}
