
// Define the menu items as an array of objects
export const menuItems = [
    { id: 'illegale_autorennen', link: '/illegale-autorennen', name: 'Illegale Autorennen' },
    { id: 'park_abschleppmassnahmen', link: '/park-abschleppmassnahmen', name: 'Park- und Abschleppmaßnahmen' },
    { id: 'alkoholdelikte', link: '/alkoholdelikte', name: 'Alkoholdelikte' },
    { id: 'geschwindigkeitsverstoss', link: '/geschwindigkeitsverstoss', name: 'Geschwindigkeitsverstoß' },
    { id: 'rotlichtverstoss', link: '/rotlichtverstoss', name: 'Rotlichtverstoß' },
    { id: 'abstandsverstoss', link: '/abstandsverstoss', name: 'Abstandsverstoß' },
    { id: 'handyverstoss', link: '/handyverstoss', name: 'Handyverstoß' },
    { id: 'anderer_verstoss', link: '/anderer-verstoss', name: 'Sonstiger Verstoß' },
]

function PopUpVerstossDropdown({ onSelect, nav }: any) {
    return (
        <div className="absolute bg-primary text-black shadow-lg rounded-md normal-case bottom-10">
            <ul ref={nav} className="divide-y divide-white">
                {menuItems.map((item) => (
                    <li
                        key={item.id}
                        className="hover:bg-secondary text-white px-4 py-2"
                        onClick={() => {
                            onSelect(item.name)
                        }}>
                        <div className="block w-full h-full text-white">{item.name}</div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default PopUpVerstossDropdown
