import Hero from '../../components/general/Hero'
import Seperator2 from '../../components/general/Seperator2'
import ContactFormWrapper from '../../components/general/ContactFormWrapper'
import ResponsibilitiesVerkehrsrecht from '../../components/general/ResponsibilitiesVerkehrsrecht'
import ResponsibiliesAdditional from '../../components/general/ResponsibiliesAdditional'
import { getLeistungenData } from '../../hooks/cmsApiCalls'
import { useQuery } from '@tanstack/react-query'
import ErrorPage from '../404/ErrorPage'
import Loading from '../../components/common/Loading'
import BaseSeo from '../../components/layout/BaseSeo'
import DropdownWrapper from '../../components/general/DropdownWrapper'

export type LeistungenType = {
    loadingFunc: () => void
}

function Leistungen() {
    const { error, data, isLoading } = useQuery({
        queryKey: ['leistungenData'],
        queryFn: getLeistungenData,
    })

    return (
        <>
            {error && <ErrorPage />}
            {isLoading && <Loading />}
            {data && !error && !isLoading && (
                <>
                    <BaseSeo seo={data.attributes?.seo} />
                    <Hero
                        heading={String(data.attributes.hero_headline)}
                        subtitle={String(data?.attributes.hero_subtitle)}
                        heroImg={String(data?.attributes?.hero_img?.data?.attributes?.url)}
                    />
                    <div className="w-full flex flex-col lg:space-y-4">
                        <ResponsibilitiesVerkehrsrecht content={data} />
                        <Seperator2 content={data?.attributes?.separator}/>
                        <ResponsibiliesAdditional content={data} />
                        <DropdownWrapper content={data?.attributes?.faq} />
                        <ContactFormWrapper />
                    </div>
                </>
            )}
        </>
    )
}

export default Leistungen
