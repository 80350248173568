import React from 'react'
import { HomeContentChildType } from '../../types/Types'
import { API } from '../../services/constants'

function IconsAndNumbers({ content }: HomeContentChildType) {
    return (
        <div className="max-w-screen-xl hidden md:flex w-full justify-around mt-4 pb-36 px-8 xl:px-0">
            {content?.attributes?.icons_numbers.input_numbers.map((numbers) => (
                <div
                    key={numbers.id}
                    className="flex flex-col items-center justify-center">
                    <img
                        src={API.img + numbers?.icon?.data?.attributes?.url}
                        alt=""
                        className="w-24 h-24"
                    />
                    <p className="text-5xl font-bold pt-2">{numbers?.number}</p>
                    <p className="text-lg font-semibold">
                        {numbers?.description}
                    </p>
                </div>
            ))}
        </div>
    )
}

export default IconsAndNumbers
