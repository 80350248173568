import axios from 'axios'
import { API } from '../services/constants'

// ============= HOMEPAGE ============= //

export const getHomepageData = () =>
    axios
        .get(
            API.general +
                '/home-page?populate[about_image][populate]=attributes&populate[separator1][populate]=attributes&populate[separator2][populate]=attributes&populate[middle_image][populate]=attributes&populate[bottom_image][populate]=attributes&populate[faq][populate]=*&populate[icons_numbers][populate][input_numbers][populate]=*&populate[hero_img][populate]=attributes&populate=seo&populate[news_to_display][populate]=*'
        )
        .then((res) => res.data.data)

// ============= NEWS ============= //

export const getNewsMetaData = () => axios.get(API.general + '/news-page?populate=*').then((res) => res.data.data)

export const getNewsEntries = () =>
    axios.get(API.general + '/news-entries?populate=*&pagination[pageSize]=200&sort=content.date:desc').then((res) => res.data.data)

// ============= GENERAL SLIDER ============= //

export const getSliderData = () => axios.get(API.general + '/verstoss-slider?populate[slider_content][populate][icon]=*').then((res) => res.data.data)

// ============= HOW IT WORKS ============= //

export const getHowItWorksData = () =>
    axios.get(API.general + '/wie-es-funktioniert?populate[how_it_works][populate]=*').then((res) => res.data.data.attributes)

// ============= ÜBER UNS ============= //

export const getAboutUsData = () =>
    axios
        .get(
            API.general +
                '/about-page?populate[about_image][popualte]=data&populate[about_faq][populate]=question_and_answer&populate[separator][populate]=attributes&populate[about_slider][populate]=data&populate[hero_img][populate]=data&populate=seo'
        )
        .then((res) => res.data.data)

// ============= LEISTUNGEN ============= //

export const getLeistungenData = () =>
    axios
        .get(
            API.general +
                '/leistungen-page?populate[responsibilities][populate]=*&populate[about_image][populate]=*&populate[verkehrsrecht][populate]=*&populate[weitere_bereiche][populate]=*&populate[hero_img][populate]=*&populate[seo][populate]=*&populate[faq][populate]=*'
        )
        .then((res) => res.data.data)

// ============= KONTAKT ============= //

export const getKontaktData = () => axios.get(API.general + '/bild-kontaktformular?populate=*').then((res) => res.data.data.attributes)

// ============= FAQ ============= //

export const getFaqData = () =>
    axios.get(API.general + '/faq-page?populate[faq][populate]=*&populate=hero_img&populate[seo][populate]=*').then((res) => res.data.data)

// ============= IMPRESSUM ============= //

export const getImpressumData = () => axios.get(API.general + '/impressum?populate=hero_img&populate=seo').then((res) => res.data.data)

// ============= DATENSCHUTZ ============= //

export const getDatenschutzData = () => axios.get(API.general + '/datenschutz?populate=hero_img&populate=seo').then((res) => res.data.data)

// ============= BEABREITUNGSFRIST ============= //

export const getBearbeitungsfristData = () =>
    axios.get(API.general + '/bearbeitungsfrist?populate=hero_img&populate=seo').then((res) => res.data.data)

// ============= AGB ============= //

export const getAgbData = () => axios.get(API.general + '/agb?populate=hero_img&populate=seo').then((res) => res.data.data)

// ============= POPUP IMG ============= //

export const getPopUpImgData = () => axios.get(API.general + '/popup-image?populate=image').then((res) => res.data.data)

// ============= ROTLICHT VERSTOSS ============= //

export const getRotlichtData = () =>
    axios
        .get(API.general + '/rotlichtverstoss-page?populate[faq][populate]=*&populate[hero_img][populate]=data&populate=seo')
        .then((res) => res.data.data)

// ============= HANDY VERSTOSS ============= //

export const getHandyData = () =>
    axios
        .get(API.general + '/handyverstoss-page?populate[faq][populate]=*&populate[hero_img][populate]=data&populate=seo')
        .then((res) => res.data.data)

// ============= HANDY VERSTOSS ============= //

export const getRaceData = () =>
    axios
        .get(API.general + '/illegale-autorennen-page?populate[faq_race][populate]=*&populate[hero_img][populate]=data&populate=seo')
        .then((res) => res.data.data)

// ============= ABSTAND VERSTOSS ============= //

export const getAbstandData = () =>
    axios
        .get(API.general + '/abstandsverstoss-page?populate[faq][populate]=*&populate[hero_img][populate]=data&populate=seo')
        .then((res) => res.data.data)

// ============= ALKOHOL VERSTOSS ============= //

export const getAlkoholData = () =>
    axios
        .get(API.general + '/alkoholdelikte-page?populate[faq_alkohol][populate]=*&populate[hero_img][populate]=data&populate=seo')
        .then((res) => res.data.data)

// ============= ANDERES VERSTOSS ============= //

export const getAnderesData = () =>
    axios
        .get(API.general + '/anderer-verstoss-page?populate[faq_sonstiges][populate]=*&populate[hero_img][populate]=data&populate=seo')
        .then((res) => res.data.data)

// ============= PACE VERSTOSS ============= //

export const getPaceData = () =>
    axios
        .get(API.general + '/geschwindigkeitsverstoss-page?populate[faq][populate]=*&populate[hero_img][populate]=data&populate=seo')
        .then((res) => res.data.data)

// ============= PARK VERSTOSS ============= //

export const getParkData = () =>
    axios
        .get(API.general + '/park-abschleppmassnahmen-page?populate[faq][populate]=*&populate[hero_img][populate]=data&populate=seo')
        .then((res) => res.data.data)

// ============ INHERITCONTENT ============ //
export const getInheritContent = () =>
    axios.get(API.general + '/inherit-content?populate=logo_white&populate=logo_black&populate=favicon').then((res) => res.data.data)
