import RadioButton from '../../general/RadioButton'
import { MdInfoOutline } from 'react-icons/md'
import { InfoDataType, ScreenPersonenType } from '../../../types/Types'
import { useState } from 'react'

function ScreenPersonendaten({
    infoData,
    setInfoData,
    dark,
    ref,
    documents,
    setdocuments,
}: ScreenPersonenType) {
    const setCheckedValue = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenPersonendaten: {
                ...prevInfoData.screenPersonendaten,
                [name]: value,
            },
        }))
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenPersonendaten: {
                ...prevInfoData.screenPersonendaten,
                [name]: value,
            },
        }))
    }
    const [documentcount, setdocumentcount] = useState<number>(0)

    // add doc to doc-array-useState
    const addDocument = () => {
        setdocuments((prevDocuments: any) => [
            ...prevDocuments,
            new File([], ''),
        ])
    }

    // remove Doc from useState-array
    const removeDocument = (indexToRemove: number) => {
        setdocumentcount(documentcount - 1)
        setdocuments((prevValues: any) =>
            prevValues.filter(
                (_: any, index: number) => index !== indexToRemove
            )
        )
    }

    const handleDocInputChange = (
        index: number,
        key: string,
        newValue?: string | File | null
    ) => {
        setdocuments((prevDocuments: any[]) =>
            prevDocuments.map((item, idx) => {
                return idx === index ? { ...item, [key]: newValue } : item
            })
        )
    }

    return (
        <div ref={ref} className="px-2 overflow-y-scroll relative">
            <h5 className="w-full pt-2 text-xl pb-4 font-semibold">
                Persönliche Daten
            </h5>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col pt-4 pb-2 md:py-0 md:flex-row md:space-x-3 gap-y-2">
                    <input
                        type="text"
                        name="firstName"
                        onChange={handleInputChange}
                        placeholder="Vorname*"
                        value={infoData.screenPersonendaten.firstName || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="text"
                        name="lastName"
                        onChange={handleInputChange}
                        placeholder="Nachname*"
                        value={infoData.screenPersonendaten.lastName || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="text"
                        name="phonenumber"
                        onChange={handleInputChange}
                        placeholder="Telefonnummer*"
                        value={infoData.screenPersonendaten.phonenumber || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="text"
                        name="email"
                        onChange={handleInputChange}
                        placeholder="Email*"
                        value={infoData.screenPersonendaten.email || ''}
                        required
                        className={`border  ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                </div>
                <div className="flex flex-col pb-4 md:pb-0 md:flex-row md:space-x-3 gap-y-2">
                    <input
                        type="text"
                        name="street"
                        onChange={handleInputChange}
                        placeholder="Straße*"
                        value={infoData.screenPersonendaten.street || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="number"
                        name="nr"
                        onChange={handleInputChange}
                        placeholder="Nummer*"
                        value={infoData.screenPersonendaten.nr || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="number"
                        name="plz"
                        onChange={handleInputChange}
                        placeholder="Postleihzahl*"
                        value={infoData.screenPersonendaten.plz || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="text"
                        name="city"
                        onChange={handleInputChange}
                        placeholder="Stadt*"
                        value={infoData.screenPersonendaten.city || ''}
                        required
                        className={`border  ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                </div>
                <div className="space-y-2 gap-y-2">
                    <p className="md:text-xl font-semibold ">
                        Dokumente hochladen
                    </p>
                    <p className="hidden md:block">
                        Bitte laden Sie hier Dokumente hoch, die eine Relevanz
                        für den von Ihnen angegebenen Fall haben.
                    </p>
                    <div className="flex flex-wrap gap-4">
                        {documents.map((item: any, index: number) => (
                            <div
                                key={index}
                                className="flex flex-row gap-2 items-end">
                                <div className="flex mt-2 items-center gap-2 relative">
                                    <input
                                        type="file"
                                        className={`file-input file-input-bordered w-[19rem] file-input-sm ${
                                            dark
                                                ? 'file-input-white text-black'
                                                : 'file-input-primary'
                                        }`}
                                        required
                                        onChange={(e) => {
                                            const file =
                                                e.target.files &&
                                                e.target.files[0] &&
                                                e.target.files[0]
                                            handleDocInputChange(
                                                index,
                                                'fileUpload',
                                                file
                                            )
                                        }}
                                    />
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className={`w-7 h-7 cursor-pointer absolute  ${
                                            dark
                                                ? 'bg-none -right-6 -top-3'
                                                : 'bg-white -top-3 -right-3'
                                        }`}
                                        onClick={() => removeDocument(index)}>
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        ))}
                        <div
                            className="flex pl-4 text-sm items-center gap-3 cursor-pointer justify-center mt-2"
                            onClick={() => {
                                addDocument()
                                setdocumentcount(documentcount + 1)
                            }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-7 h-7">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            <p>Dokument hinzufügen</p>
                        </div>
                    </div>
                </div>
                <div className=" space-y-0 md:space-y-4">
                    <p className="text-xl font-semibold pb-2 md:pb-0">
                        Rückruf*
                    </p>
                    <div className=" grid grid-cols-2 md:flex gap-x-4 gap-y-2">
                        <RadioButton
                            label="Früh (8-11 Uhr)"
                            name="erreichbarkeit"
                            value="1"
                            checkedValue={
                                infoData.screenPersonendaten.erreichbarkeit ||
                                ''
                            }
                            setCheckedValue={setCheckedValue}
                            dark={dark}
                        />
                        <RadioButton
                            label="Mittag (11-14 Uhr)"
                            name="erreichbarkeit"
                            value="2"
                            checkedValue={
                                infoData.screenPersonendaten.erreichbarkeit ||
                                ''
                            }
                            setCheckedValue={setCheckedValue}
                            dark={dark}
                        />
                        <RadioButton
                            label="Nachmittag (14-17 Uhr)"
                            name="erreichbarkeit"
                            value="3"
                            checkedValue={
                                infoData.screenPersonendaten.erreichbarkeit ||
                                ''
                            }
                            setCheckedValue={setCheckedValue}
                            dark={dark}
                        />
                        <RadioButton
                            label="Abend (17-20 Uhr)"
                            name="erreichbarkeit"
                            value="4"
                            checkedValue={
                                infoData.screenPersonendaten.erreichbarkeit ||
                                ''
                            }
                            setCheckedValue={setCheckedValue}
                            dark={dark}
                        />
                    </div>
                </div>
                <div className="space-y-4">
                    <h5 className="text-xl font-semibold ">
                        Rechtsschutzversicherung*
                    </h5>
                    <div className="flex flex-col gap-2">
                        <RadioButton
                            label="Ja, ich habe eine Rechtsschutzversicherung"
                            name="rechtsschutz"
                            value="ja"
                            checkedValue={
                                infoData.screenPersonendaten.rechtsschutz || ''
                            }
                            setCheckedValue={setCheckedValue}
                            dark={dark}
                        />
                        <RadioButton
                            label="Nein, ich habe keine Rechtsschutzversicherung"
                            name="rechtsschutz"
                            value="nein"
                            checkedValue={
                                infoData.screenPersonendaten.rechtsschutz || ''
                            }
                            setCheckedValue={setCheckedValue}
                            dark={dark}
                        />
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="px-3 scale-125">
                        <MdInfoOutline />
                    </div>
                    <div>*Pflichtangaben</div>
                </div>
            </div>
        </div>
    )
}

export default ScreenPersonendaten
