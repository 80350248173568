import { useContext, useState } from 'react'
import { API } from '../../services/constants'
import ContactForm from './ContactForm'
import { VerstossType } from '../../types/Types'
import Modal from '../modal/Modal'
import { ModalContext } from '../contexts/ModalContext'
import { getKontaktData } from '../../hooks/cmsApiCalls'
import { useQuery } from '@tanstack/react-query'
import ErrorPage from '../../pages/404/ErrorPage'
import Loading from '../common/Loading'

function ContactFormWrapper() {
    const modalCon = useContext(ModalContext)
    const [currentFirstScreenType, setCurrentFirstScreenType] = useState<VerstossType | null>(null)

    const handleViolationClick = (violation: VerstossType) => {
        setCurrentFirstScreenType(violation)
        modalCon?.trigger(20)
    }

    const { error, data, isLoading } = useQuery({
        queryKey: ['kontaktData'],
        queryFn: getKontaktData,
    })

    return (
        <>
            {error && <ErrorPage />}
            {isLoading && <Loading />}
            {data && (
                <>
                    <Modal
                        modalId={20}
                        closeModal={() => modalCon?.trigger(20)}
                        firstScreenType={currentFirstScreenType || 'sonstigedelikte'}
                        screen
                        setCurrentFirstScreenType={setCurrentFirstScreenType}
                    />
                    <div className="hidden lg:flex w-full">
                        <div className="relative w-5/12 h-[500px]">
                            <img
                                src={API.img + data?.kontaktbild.data.attributes?.url}
                                alt="bottom img"
                                className="object-cover w-full h-full bg-gray-300"
                            />
                            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-10"></div>
                        </div>

                        <div className="w-7/12 flex flex-col">
                            <div className="w-full bg-base-200 px-24 py-12 flex justify-between gap-4 items-center">
                                {/* Red Box Content */}
                                <p className="text-xl w-80 font-medium ">{data?.hinweistext}</p>
                                <button
                                    className="bg-secondary text-white rounded-2xl btn font-normal px-6 text-lg active:scale-pressed duration-100 ease-in-out normal-case"
                                    onClick={() => handleViolationClick('overview')}>
                                    Verstoß melden!
                                </button>
                            </div>
                            <div className="w-full">
                                {/* Blue Box Content for 'lg' screens and above */}
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                    <div className="lg:hidden w-full h-fit">
                        <div
                            className="relative w-full bg-base-200 h-40 bg-cover"
                            style={{
                                backgroundImage: `url(${API.img + data?.kontaktbild.data.attributes?.url})`,
                            }}>
                            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-10"></div>
                        </div>
                        <div className="flex h-fit items-center justify-between py-4 px-6 sm:px-12 md:px-16 gap-4 pr-16">
                            <p className="text-sm font-medium w-80 ">{data?.hinweistext}</p>
                            <button
                                className="btn-secondary btn-xs h-fit text-white rounded-lg active:scale-pressed duration-100 ease-in-out py-1 px-3"
                                onClick={() => handleViolationClick('overview')}>
                                Verstoß melden!
                            </button>
                        </div>
                        <ContactForm />
                    </div>
                </>
            )}
        </>
    )
}

export default ContactFormWrapper
