import { useContext } from 'react'
import { caseType2, userType } from '../types/Types'
import { ReactComponent as EdgeArrow } from './../../res/News Edge Icon.svg'
import dayjs from 'dayjs'
import { ModalContext } from '../../components/contexts/ModalContext'
import PlanMeetingModal from './admin/PlanMeetingModal'
import { getDocToDownload } from '../api/apiCalls'

type CaseInterfaceType = {
    ustatus: string
    reqDisable?: boolean
    selectedCase?: caseType2
    adminView?: boolean
    openModal: () => void
    denyCase?: () => void
    deleteExistingCase?: () => void
    acceptCase?: () => void
    closeCase?: () => void
    deleteCaseReq?: () => void
    setdoc?: (e: string) => void
    deleteDocument?: () => void
    customer?: userType
}

export default function CaseInterface({
    ustatus,
    selectedCase,
    adminView,
    reqDisable,
    openModal,
    denyCase,
    deleteExistingCase,
    acceptCase,
    closeCase,
    deleteCaseReq,
    customer,
    setdoc,
    deleteDocument,
}: CaseInterfaceType) {
    // download file

    const handleDownload = (id: string, displayFileName: string) => {
        getDocToDownload(id, displayFileName).catch((err) => {
            alert(
                'Fehler beim Download - Bitte versuchen Sie es später erneut...'
            )
            console.log(err)
        })
    }

    // Modal Context
    const modalCon = useContext(ModalContext)

    return (
        <div className="grow flex flex-col p-16 rounded-none bg-portal-gray gap-4 relative">
            <EdgeArrow className="absolute top-0 left-0 h-8 w-8" />
            <div className="flex flex-col leading-5">
                <p className="text-lg font-semibold">
                    Fall {dayjs(selectedCase?.created_at).format('DD.MM.YYYY')}
                </p>
                <p className="text-sm">
                    Letztes Update:{' '}
                    {dayjs(selectedCase?.updated_at).format('DD.MM.YYYY HH:mm')}{' '}
                    Uhr
                </p>
                <p
                    className={
                        adminView ? 'text-lg font-semibold pt-4' : 'text-sm'
                    }>
                    Frist:{' '}
                    {dayjs(selectedCase?.deadline).isValid()
                        ? dayjs(selectedCase?.deadline).format('DD.MM.YYYY')
                        : 'keine Angabe'}
                </p>
                <p className={adminView ? '' : 'text-lg font-medium pt-8'}>
                    {selectedCase?.title}
                </p>
                {/* TODO callOption kommt von persönlichen Infos -> als einzelne Var übergeben */}
                {/* <p className='text-lg font-medium pt-4 pb-4'>Rückruf: {selectedCase?.callOption}</p> */}
                {selectedCase?.info?.map((item, index) => (
                    <p key={index} className={`${index === 0 && 'pt-4'}`}>
                        {item}
                    </p>
                ))}
                {selectedCase?.note && (
                    <p className="pt-4">Bemerkung: {selectedCase?.note}</p>
                )}
                {!selectedCase?.documents ||
                selectedCase.documents.length === 0 ? (
                    <p className="pt-4">Keine Dokumente hochgeladen</p>
                ) : (
                    selectedCase?.documents?.map((item, index) => (
                        <div
                            key={index}
                            className={`flex items-center justify-between ${
                                index === 0 ? 'pt-4' : 'pt-1'
                            }`}>
                            <div className="flex items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6 text-primary mr-2">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                                    />
                                </svg>
                                <p>{item.title}</p>
                            </div>
                            <div className="flex gap-4">
                                <svg
                                    onClick={() =>
                                        handleDownload(
                                            item.filename,
                                            item.title
                                        )
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6 text-primary cursor-pointer">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                    />
                                </svg>
                                {selectedCase?.status === 'open' &&
                                    adminView && (
                                        <svg
                                            onClick={() => {
                                                if (setdoc && deleteDocument) {
                                                    setdoc(item._id)
                                                    deleteDocument()
                                                }
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6 text-primary cursor-pointer hover:text-red-600">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                            />
                                        </svg>
                                    )}
                            </div>
                        </div>
                    ))
                )}
            </div>
            <div className="grow" />
            {ustatus !== 'taken' ? (
                <p className="font-semibold uppercase text-center text-primary/20 text-sm">
                    {adminView && 'Der User wurde noch nicht angenommen'}
                </p>
            ) : (
                <>
                    {selectedCase?.status !== 'done' && (
                        <>
                            {adminView && ustatus === 'taken' && (
                                <>
                                    {selectedCase?.status === 'new' && (
                                        <>
                                            <button
                                                className="btn btn-secondary w-full text-base-100"
                                                onClick={acceptCase}>
                                                Fall annehmen
                                            </button>
                                            <button
                                                className="btn btn-primary w-full relative"
                                                onClick={() =>
                                                    modalCon?.trigger(11)
                                                }>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className=" absolute left-4 w-6 h-6">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                                                    />
                                                </svg>
                                                Telefonat im Kalender planen
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                            {selectedCase?.status === 'open' && (
                                <button
                                    className="btn btn-secondary w-full text-base-100"
                                    onClick={openModal}>
                                    Dokument hochladen
                                </button>
                            )}
                            {selectedCase?.status === 'open' && adminView && (
                                <button
                                    className="btn btn-primary w-full"
                                    onClick={closeCase}>
                                    Fall schließen
                                </button>
                            )}
                            {selectedCase?.status !== 'open' && adminView && (
                                <button
                                    className="btn bg-gray-300 text-base-100 w-1/2 hover:bg-gray-400 hover:border-gray-400"
                                    onClick={denyCase}>
                                    Anfrage löschen
                                </button>
                            )}
                        </>
                    )}
                    {selectedCase?.status !== 'new' && adminView && (
                        <button
                            className="btn bg-gray-300 text-base-100 w-1/2 hover:bg-gray-400 hover:border-gray-400"
                            onClick={deleteExistingCase}>
                            Fall löschen
                        </button>
                    )}
                </>
            )}
            {/* Button only shows on User Screen */}
            {!adminView && (
                <button
                    disabled={reqDisable}
                    className="btn bg-gray-300 text-base-100 w-1/2 hover:bg-gray-400 hover:border-gray-400"
                    onClick={deleteCaseReq}>
                    Löschen Beantragen
                </button>
            )}
            <PlanMeetingModal modalId={11} customer={customer} />
        </div>
    )
}
