import { ReactNode, useContext } from 'react'
import { motion } from 'framer-motion'
import { ModalContext } from '../contexts/ModalContext'
import { ReactComponent as RotesPlus } from '../../res/RotesPlus.svg'

export type BaseModalType = {
    title?: string
    big?: boolean
    children: ReactNode
    bottomRow?: ReactNode
    modalId?: number
    size?: boolean
    hiddenCross?: boolean
}

export default function BaseModal({
    children,
    bottomRow,
    modalId,
    size,
    big,
    hiddenCross,
}: BaseModalType) {
    const modalCon = useContext(ModalContext)

    return (
        <div
            className={`fixed top-0 left-0 inset-0 w-full h-screen overflow-hidden bg-primary/70 z-[10000]  ${
                modalCon?.modalId === modalId && modalCon?.open ? '' : 'hidden'
            }`}>
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: modalCon?.animation ? 1 : 0,
                }}
                transition={{
                    duration: 0.2,
                    type: 'ease',
                }}
                className="hidden lg:block w-full  h-full  z-0"
                onClick={() => modalCon?.trigger(0)}
            />
            <motion.div
                initial={{
                    scale: 0,
                }}
                animate={{
                    scale: modalCon?.animation ? 1 : 0,
                }}
                transition={{
                    duration: 0.2,
                    type: 'ease',
                }}
                className={`${
                    size && !big
                        ? 'w-fit h-fit pt-12 overflow-hidden '
                        : !size && !big
                        ? 'w-full max-w-screen-lg h-[calc(100dvh)] py-8 px-8 sm:px-16 md:px-32  md:h-[48rem] md:max-h-[95%]'
                        : 'w-[90%] h-[90%] min-h-fit min-w-fit'
                } z-10 rounded-xl bg-white shadow-lg absolute inset-0 m-0 md:m-auto flex flex-col pt-8 pb-4 px-8`}>
                <RotesPlus
                    className={`absolute w-28 z-[1000000000] -top-14 left-6 ${
                        hiddenCross ? 'hidden' : 'hidden md:block'
                    }`}
                />

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 absolute top-4 right-4 cursor-pointer"
                    onClick={() => modalCon?.trigger(0)}>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
                <div className="grow w-full h-max overflow-x-hidden overflow-y-scroll">
                    {children}
                </div>
                {bottomRow && (
                    <div className="w-full border-t pt-2">{bottomRow}</div>
                )}
            </motion.div>
        </div>
    )
}
