import React from 'react'
import { InfoDataType, ScreenType } from '../../../types/Types'

function SonstigerVerstoss({ infoData, setInfoData, dark }: ScreenType) {
    const handleTextAreaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenSonstigeDelikte: {
                ...prevInfoData.screenSonstigeDelikte,
                [name]: value,
            },
        }))
    }

    return (
        <div className="pr-2">
            <h5 className="w-full pt-2 text-xl pb-4 font-semibold">
                Angaben zum Vorwurf
            </h5>
            <div className="w-full h-full">
                <p className="pb-2">Was wird Ihnen vorgeworfen?*</p>
                <textarea
                    placeholder="schreiben..."
                    value={infoData.screenSonstigeDelikte.note || ''}
                    name="note"
                    rows={12}
                    className={`${
                        dark ? 'bg-primary border-white/70' : ''
                    } p-2 w-full rounded-md border focus:border-none`}
                    onChange={handleTextAreaChange}></textarea>
            </div>
        </div>
    )
}

export default SonstigerVerstoss
