import { motion } from 'framer-motion'
import { useState } from 'react'
import { FAQType } from '../../types/Types'
import RichTextBody from './RichTextBody'

type AccordionsType = {
    faqs?: FAQType[]
    distance?: boolean
}

export default function DropDownArrow({ faqs, distance }: AccordionsType) {
    const [openedAcc, setOpenedAcc] = useState<number | null>(null) // Change the type to number | null

    const handleOpen = (accId: number) => {
        // Change the parameter type to number
        setOpenedAcc(accId === openedAcc ? null : accId)
    }

    return (
        <section className={`w-full flex justify-center ${distance ? 'pt-16 pb-24' : 'pb-12'}`}>
            <div className="w-full max-w-screen-xl px-4 md:px-16  min-[1400px]:px-0">
                {faqs &&
                    faqs.map((acc: FAQType) => (
                        <SimpleAccord
                            key={acc?.id}
                            bool={acc?.id === openedAcc}
                            func={() => handleOpen(acc?.id)}
                            headline={acc?.question}
                            text={acc?.answer}
                        />
                    ))}
            </div>
        </section>
    )
}

type SimpleAccordType = {
    bool: boolean
    func: () => void
    headline: string
    text: string
}

function SimpleAccord(props: SimpleAccordType) {
    return (
        <div className="w-full flex flex-col overflow-hidden static overflow-y-hidden" onClick={props.func}>
            <div className="h-4 z-10 bg-base-100" />
            <div className="w-full flex flex-row justify-between items-center p-4 font-medium z-10 bg-white cursor-pointer gap-4 border-b border-black ">
                <h4 className="text-lg font-medium w-[85%] sm:w-[90%]">{props.headline}</h4>
                {!props.bool ? (
                    <div className="w-6 h-6 sm:h-7 sm:w-7 flex items-center justify-center text-lg bg-secondary font-semibold text-white rounded-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                ) : (
                    <div className="w-6 h-6 sm:h-7 sm:w-7 flex items-center justify-center text-lg bg-secondary font-semibold text-white rounded-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                        </svg>
                    </div>
                )}
            </div>

            <motion.div
                animate={{
                    height: props.bool ? '0%' : '',
                }}
                transition={{
                    duration: 0.25,
                    type: 'ease',
                }}
                className="h-0">
                <RichTextBody className="relative w-full max-w-4xl  p-4" richText={props.text} />
            </motion.div>
        </div>
    )
}
