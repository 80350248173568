import { useState } from 'react'
import BaseCaseForm from '../../../BaseCaseForm'
import DistanceViolationScreen1 from './DistanceViolationScreen1'
import FormScreen2 from '../FormScreen2'

export type DistanceViolationType = {
    distance: string
    setdistance: (e: string) => void
    necessary: string
    setnecessary: (e: string) => void
    pace: string
    setpace: (e: string) => void
    note?: string
    check1?: boolean
    setcheck1?: (e: boolean) => void
    check2?: boolean
    setcheck2?: (e: boolean) => void
    setnote?: (e: string) => void
    addButtonClick?: () => void
    setdoc1?: (e: string) => void
    setdoc2?: (e: string) => void
    setdoc3?: (e: string) => void
    documents?: any
    setdocuments?: (e: any) => void
}

export default function DistanceViolation({
    necessary,
    setnecessary,
    distance,
    setdistance,
    pace,
    setpace,
    note,
    check1,
    check2,
    documents,
    setdocuments,
    setdoc1,
    setdoc2,
    setdoc3,
    setcheck1,
    setcheck2,
    setnote,
    addButtonClick,
}: DistanceViolationType) {
    const [screen, setscreen] = useState(1)

    return (
        <BaseCaseForm
            buttonRow={
                <div className="pl-12 w-full">
                    {screen === 1 ? (
                        <button
                            className="btn bg-black/20 hover:bg-black/30 hover:border-none w-full text-base-100"
                            onClick={() => setscreen(2)}
                            disabled={distance === '' || pace === ''}>
                            Nächste Seite
                        </button>
                    ) : (
                        <div className="flex gap-2 w-full">
                            <button
                                className="btn btn-ghost"
                                onClick={() => setscreen(1)}>
                                zurück
                            </button>
                            <button
                                onClick={addButtonClick}
                                className="btn bg-accent grow text-base-100 hover:bg-accent/90"
                                disabled={!check1 || !check2}>
                                Fall melden
                            </button>
                        </div>
                    )}
                </div>
            }>
            {screen === 1 ? (
                <DistanceViolationScreen1
                    necessary={necessary}
                    setnecessary={setnecessary}
                    distance={distance}
                    setdistance={setdistance}
                    pace={pace}
                    setpace={setpace}
                />
            ) : (
                <FormScreen2
                    documents={documents}
                    setdocuments={setdocuments || (() => {})}
                    note={note ?? ''}
                    setnote={setnote ?? (() => {})}
                    check1={check1 ?? false}
                    check2={check2 ?? false}
                    setcheck1={setcheck1 ?? (() => {})}
                    setcheck2={setcheck2 ?? (() => {})}
                    setdoc1={setdoc1 ?? (() => {})}
                    setdoc2={setdoc2 ?? (() => {})}
                    setdoc3={setdoc3 ?? (() => {})}
                />
            )}
        </BaseCaseForm>
    )
}
