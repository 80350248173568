import ContactFormWrapper from '../../components/general/ContactFormWrapper'
import Hero from '../../components/general/Hero'
import NewsBody from '../../components/general/NewsBody'
import { getInheritContent, getNewsEntries, getNewsMetaData } from '../../hooks/cmsApiCalls'
import { useQuery } from '@tanstack/react-query'
import ErrorPage from '../404/ErrorPage'
import Loading from '../../components/common/Loading'
import BaseSeo from '../../components/layout/BaseSeo'

export type NewsType = {
    loadingFunc: () => void
}

function News() {
    const { error, data, isLoading } = useQuery({
        queryKey: ['newsMetaData'],
        queryFn: getNewsMetaData,
    })

    const {
        error: errorListOfEntries,
        data: listOfEntries,
        isLoading: listOfEntriesLoading,
    } = useQuery({
        queryKey: ['newsListData'],
        queryFn: getNewsEntries,
    })

    console.log(listOfEntries)
    const { data: content } = useQuery({
        queryKey: ['inheritContentData'],
        queryFn: getInheritContent,
    })

    return (
        <>
            {(error || errorListOfEntries) && <ErrorPage />}
            {(isLoading || listOfEntriesLoading) && <Loading />}
            {data && (
                <>
                    <BaseSeo seo={data?.attributes?.seo} />
                    <Hero
                        heading={String(data?.attributes.hero_headline)}
                        subtitle={String(data?.attributes.hero_subtitle)}
                        heroImg={String(data?.attributes.hero_img.data.attributes.url)}
                    />
                    <NewsBody content={data} entries={listOfEntries} logos={content} />
                    <ContactFormWrapper />
                </>
            )}
        </>
    )
}

export default News
