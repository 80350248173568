import React from 'react'

type RadioButtonType = {
    label: React.ReactNode
    name: string
    value: string
    checkedValue: string
    setCheckedValue: (name: string, value: string) => void
    dark?: boolean
}

function RadioButton({
    label,
    name,
    value,
    checkedValue,
    setCheckedValue,
    dark = true,
}: RadioButtonType) {
    return (
        <label className="inline-flex items-center cursor-pointer space-x-3">
            <input
                type="radio"
                name={name}
                value={value}
                className="sr-only"
                onChange={() => {
                    value !== '' && setCheckedValue(name, value)
                }}
                checked={checkedValue === value}
            />
            <div
                className={`min-w-[18px] min-h-[18px] rounded-full border-2 flex justify-center items-center ${
                    checkedValue === value
                        ? 'bg-secondary ' +
                          (dark ? 'border-white' : 'border-black')
                        : dark
                        ? 'bg-primary border-white'
                        : 'bg-white border-black'
                }`}></div>
            <span className={`${dark ? 'text-white' : 'text-black'}`}>
                {label}
            </span>
        </label>
    )
}

export default RadioButton
