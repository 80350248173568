import { validateEmail } from '../../../services/checks'
import { isValidPhoneNumber } from 'libphonenumber-js'

const requiredFieldsValid = (screenData: any, required: string[]) => {
    let valid = true
    if (!screenData) return false
    required.forEach((field) => {
        if (screenData[field] === '' || screenData[field] === null) {
            valid = false
        }
    })
    return valid
}

// const verstossDocumentsValid = (verstossData: any) => {
//     return (
//         verstossData.bussgeldbescheid !== '' ||
//         verstossData.anhoerungsbogen !== '' ||
//         verstossData.zeugenfragebogen !== ''
//     )
// }

const verstossDataValid = (screenType: string, data: any) => {
    if (!data) return false

    const validationMapping: any = {
        sonstigedelikte: {
            data: data['screenSonstigeDelikte'],
            fields: ['note'],
        },
        geschwindigkeitsverstoss: {
            data: data['screenGeschwindigkeitsverstoss'],
            fields: [
                'fahrzeugtyp',
                'ort',
                'oft',
                'geschwindigkeitsueberschreitung',
                'fuehrerschein',
            ],
        },
        abstandsverstoss: {
            data: data['screenAbstandsverstoss'],
            fields: [
                'abstandsunterschreitung',
                'geschwindigkeit',
                'fuehrerschein',
            ],
        },
        alkoholverstoss: {
            data: data['screenAlkoholverstoss'],
            fields: [
                'artTest',
                'fahrerlaubnis',
                'fuehrerschein_beschlagnahmt',
                'unfall',
                'wert',
            ],
        },
        rotlichtverstoss: {
            data: data['screenRotlichtverstoss'],
            fields: ['fuehrerschein', 'unfall', 'rot', 'gefaehrdung'],
        },
        parkundabschleppmassnahmen: {
            data: data['screenParkAbschleppmassnahmen'],
            fields: ['note'],
        },
        illegaleautorennen: {
            data: data['screenIllegaleAutorennen'],
            fields: ['unfall', 'fuehrerscheinentzug', 'entziehungsbeschluss'],
        },
        handyverstoss: {
            data: data['screenHandyverstoss'],
            fields: ['fahrzeugtyp', 'fuehrerschein', 'unfall'],
        },
    }
    const fields = requiredFieldsValid(
        validationMapping[screenType]?.data,
        validationMapping[screenType]?.fields
    )

    const documents = true //verstossDocumentsValid(validationMapping[screenType].data)
    const datenschutz_frist = requiredFieldsValid(data['screenPersonendaten'], [
        'datenschutz',
        'bearbeitungsfrist',
    ])

    return fields && documents && datenschutz_frist
}

const personalDataValid = (data: any) => {
    if (!data || !data['screenPersonendaten']) return false

    const fieldsNotEmpty = requiredFieldsValid(data['screenPersonendaten'], [
        'firstName',
        'lastName',
        'phonenumber',
        'email',
        'erreichbarkeit',
        'rechtsschutz',
        'street',
        'nr',
        'plz',
        'city',
    ])

    const emailValid = validateEmail(data['screenPersonendaten'].email)
    const phoneNumberValid = isValidPhoneNumber(
        data['screenPersonendaten'].phonenumber,
        'DE'
    )

    return fieldsNotEmpty && emailValid && phoneNumberValid
}

export { personalDataValid, verstossDataValid }
