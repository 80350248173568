import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { RBAC } from './shared'

type UseAnwaltSecuredProps = {
    target: React.ReactElement
}

export const useAnwaltSecuredElement = ({ target }: UseAnwaltSecuredProps) => {
    const { keycloak, initialized } = useKeycloak()
    if (!keycloak.authenticated) keycloak.login()

    const isAuthorized = () =>
        initialized &&
        keycloak.authenticated &&
        keycloak.hasRealmRole(RBAC.anwalt) // Make sure 'anwalt' matches the role name exactly
    return isAuthorized() ? (
        target
    ) : (
        <Navigate to={PortalRoutes.common.portal_entry} />
    )
}

export const useMandantenSecuredElement = ({
    target,
}: UseAnwaltSecuredProps) => {
    const { keycloak, initialized } = useKeycloak()
    if (!keycloak.authenticated) keycloak.login()

    const isAuthorized = () =>
        initialized &&
        keycloak.authenticated &&
        keycloak.hasRealmRole(RBAC.mandant) // Make sure 'anwalt' matches the role name exactly

    return isAuthorized() ? (
        target
    ) : (
        <Navigate to={PortalRoutes.common.portal_entry} />
    )
}

export const NavigateBasedOnRole = ({
    fallbackOnUnauthorized = '/',
}: {
    fallbackOnUnauthorized?: string
}) => {
    const { keycloak, initialized } = useKeycloak()
    if (!keycloak.authenticated) {
        keycloak.login()
    }

    const isAnwalt = () =>
        initialized &&
        keycloak.authenticated &&
        keycloak.hasRealmRole(RBAC.anwalt) // Make sure 'anwalt' matches the role name exactly
    const isMandant = () =>
        initialized &&
        keycloak.authenticated &&
        keycloak.hasRealmRole(RBAC.mandant) // Make sure 'anwalt' matches the role name exactly

    const route = isAnwalt()
        ? relativeRoute(PortalRoutes.anwalt.dashboard)
        : isMandant()
        ? relativeRoute(PortalRoutes.mandant.faelle)
        : fallbackOnUnauthorized

    return <Navigate to={route} />
}

export const PortalRoutes = {
    common: {
        home: '/',
        portal_entry: '/portal',
    },
    anwalt: {
        dashboard: '/dashboard',
        anfragen: '/anfragen',
        anfrage_by_id: '/anfragen/:id/:caseid',
        mandanten: '/mandanten',
        mandanten_by_id: '/mandanten/:id/:caseid?',
    },
    mandant: {
        faelle: '/faelle',
        faelle_by_id: '/faelle/:id',
        profil: '/profil',
        settings: '/einstellungen',
    },
}
export const relativeRoute = (route: string): string =>
    route.startsWith('/') ? route.slice(1) : route
