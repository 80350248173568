import { Splide, SplideSlide } from '@splidejs/react-splide'
import NewsCollapsedHome from './NewsCollapsedHome'
import { useRef } from 'react'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import { NewsContentParentType, NewsElementType } from '../../types/Types'

type NewsBodyType = {
    text?: string
    content: NewsElementType[]
}

function NewsWrapper({ text, content }: NewsBodyType) {
    const splideRef = useRef<any>(null)
    console.log(content[0])
    const handlePrev = () => {
        if (splideRef.current) {
            splideRef?.current?.splide.go('-1') // Go to the previous slide
        }
    }

    const handleNext = () => {
        if (splideRef?.current) {
            splideRef?.current?.splide.go('+1') // Go to the next slide
        }
    }

    return (
        <div className="w-full max-w-screen-xl mb-16 sm:mb-24 md:mb-36 h-fit">
            <div className="px-4 md:mx-16 text-center mt-10 md:my-10 flex flex-col justify-center items-center">
                <h3 className="text-4xl text-center">News</h3>
                <hr className="h-px my-3 mb-5 mx-auto w-3/5 bg-gray-200 border-0 dark:bg-gray-500"></hr>
                <p className="text-sm md:text-base max-w-screen-lg">{text}</p>
            </div>

            {/* For xl screens */}
            <div className="hidden xl:flex w-full sm:mt-16 md:mt-28 justify-evenly px-4 md:px-0 overflow-x-hidden">
                {content?.slice(0, 3).map((item: any) => (
                    <NewsCollapsedHome
                        key={item.id}
                        slug={item.attributes.slug}
                        heading={item.attributes.content.headline}
                        text={item.attributes.content.content}
                        category={item.attributes.content.category}
                    />
                ))}
            </div>

            {/* For lg screens */}
            <div className="hidden md:flex xl:hidden w-full sm:mt-16 md:mt-28 justify-evenly px-4 md:px-0 overflow-x-hidden">
                {content?.slice(0, 2).map((item: any) => (
                    <NewsCollapsedHome
                        key={item.id}
                        slug={item.attributes.slug}
                        heading={item.attributes.content.headline}
                        text={item.attributes.content.content}
                        category={item.attributes.content.category}
                    />
                ))}
            </div>

            {/* For screens smaller than lg */}
            <div className="md:hidden flex justify-between flex-row w-full mt-12 sm:mt-16 md:mt-28 px-4 overflow-x-hidden">
                <button className="text-5xl text-secondary p-2" aria-label="Previous" onClick={handlePrev}>
                    <MdArrowBackIos />
                </button>
                <div className="w-60 sm:w-80">
                    <Splide
                        options={{
                            type: 'slides',
                            perPage: 1, // Show one post at a time on small screens
                            perMove: 1,
                            autoplay: false,
                            drag: false,
                            gap: '2rem',
                            pagination: false,
                            arrows: false, // You can hide or show built-in arrows
                            breakpoints: {
                                640: {
                                    // Adjust for your specific breakpoints
                                    perPage: 1,
                                },
                            },
                        }}
                        ref={splideRef}>
                        {content?.slice(0, 3).map((item: any) => (
                            <SplideSlide key={item.id}>
                                <div>
                                    <NewsCollapsedHome
                                        key={item.id}
                                        slug={item.attributes.slug}
                                        heading={item.attributes.content.headline}
                                        text={item.attributes.content.content}
                                        category={item.attributes.content.category}
                                    />
                                </div>
                            </SplideSlide>
                        ))}
                    </Splide>
                </div>
                <button className="text-5xl text-secondary p-2" aria-label="Next" onClick={handleNext}>
                    <MdArrowForwardIos />
                </button>
            </div>
        </div>
    )
}

export default NewsWrapper
