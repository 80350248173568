import { API } from '../../services/constants'
import { HowItWorksType } from '../../types/Types'
import RichTextBody from './RichTextBody'

type HowItWorksChildType = {
    content?: HowItWorksType
    homepage?: boolean
    bottomDistanceToBig?: boolean
}

function HowItWorks({ content, homepage, bottomDistanceToBig }: HowItWorksChildType) {
    return (
        <div className={`${bottomDistanceToBig ? 'pb-16' : 'pb-32'} pt-8 px-8 sm:px-4 md:px-8 max-w-screen-xl mx-auto`}>
            <>
                <h3 className={`text-center text-4xl w-full sm:pt-8 md:pt-16 sm:w-fit ${homepage ? 'mx-auto' : ''} `}>{content?.heading}</h3>
                <hr className={`h-px mx- w-3/12 mr-auto mt-2 mb-8 bg-black border-0 ${homepage ? 'mx-auto' : 'mx-auto sm:mx-0'} `} />
                <RichTextBody
                    className={`text-left max-w-screen-lg ${homepage ? 'text-center' : 'text-center sm:text-left'}`}
                    richText={content?.subheading}
                />
            </>
            <div className="mt-8 grid grid-cols-2 gap-y-8 md:gap-y-0 md:gap-x-16 items-center max-w-screen-lg md:mx-auto">
                {content?.how_it_works.map((step) => (
                    <div key={step.id} className="flex flex-col md:flex-row items-center">
                        <img
                            className="h-16 md:w-24 md:h-32 py-0 md:py-8 order-first md:order-2"
                            src={API.img + step?.icon?.data?.attributes?.url}
                            alt="Step img"
                        />
                        <div className="flex flex-col w-full pr-0 md:pr-4">
                            <hr className="hidden md:block h-px bg-black border-0 my-3" />
                            <div className="flex flex-col items-center space-x-0 md:space-x-4 md:items-start md:flex lg:flex-row text-center md:text-left">
                                <p className="text-base sm:text-xl md:text-4xl text-secondary whitespace-nowrap">{step.step}</p>
                                <p className="mt-1 text-xs md:text-base h-12">{step.step_name}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HowItWorks
