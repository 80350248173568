import React from "react";

type BaseToggleType  ={
    title: string, 
    label1: string,
    label2: string, 
    group: string,
    checked?: string, // TODO required
    value1: string, // TODO required
    value2: string, // TODO required
    onChange: (e: string) => void // TODO required
}

export default function BaseToggle({ title, label1, label2, group, checked, value1, value2, onChange }: BaseToggleType) {
  return (
    <>
      <p className="pt-8">
        {title}
      </p>
      <div className="flex justify-evenly w-full pt-2">
        <div className="flex gap-3 items-center">
          <input type="radio" name={group} className="radio radio-primary border-2" checked={checked === value1} onChange={() => onChange(value1)}/>
          <label>{label1}</label>
        </div>
        <div className="flex gap-3 items-center">
          <input type="radio" name={group} className="radio radio-primary border-2" checked={checked === value2} onChange={() => onChange(value2)}/>
          <label>{label2}</label>
        </div>
      </div>
    </>
  );
}
