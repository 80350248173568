import { Helmet } from 'react-helmet'
import { useQuery } from '@tanstack/react-query'
import { getInheritContent } from '../../hooks/cmsApiCalls'
import { API } from '../../services/constants'

type BaseSeoType = {
    seo?: {
        metaTitle?: string
        metaDescription?: string
        canonicalURL?: string
        keywords?: any
        metaRobots?: any
        structuredData?: any
    }
}

export default function BaseSeo(props: BaseSeoType) {
    const { data: content } = useQuery({
        queryFn: getInheritContent,
        queryKey: ['inheritContentData'],
    })
    return (
        <Helmet>
            <title>{props.seo?.metaTitle}</title>
            {content && <link rel="icon" href={API.img + content.attributes?.favicon?.data?.attributes?.url} />}
            {content && <link rel="icon" type="image/png" href={API.img + content.attributes?.favicon?.data?.attributes?.url} />}
            {content && <link rel="apple-touch-icon" href={API.img + content.attributes?.favicon?.data?.attributes?.url} />}
            {props.seo?.canonicalURL && <link rel="canonical" href={props.seo?.canonicalURL} />}
            {props.seo?.metaDescription && <meta name="description" content={props.seo?.metaDescription} />}
            {props.seo?.metaDescription && <meta name="og:description" content={props.seo?.metaDescription} />}
            {props.seo?.keywords && <meta name="keywords" content={props.seo?.keywords} />}
            {props.seo?.metaRobots && <meta name="robots" content={props.seo?.metaRobots} />}
            {props.seo?.structuredData && <script type="application/ld+json">{JSON.stringify(props.seo?.structuredData)}</script>}
        </Helmet>
    )
}
