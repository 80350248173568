import React from 'react'
import RadioButton from '../../general/RadioButton'
import { InfoDataType, ScreenPersonenType } from '../../../types/Types'

function Personendaten({
    infoData,
    setInfoData,
    dark,
    documents,
}: ScreenPersonenType) {
    const setCheckedValue = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenPersonendaten: {
                ...prevInfoData.screenPersonendaten,
                [name]: value,
            },
        }))
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenPersonendaten: {
                ...prevInfoData.screenPersonendaten,
                [name]: value,
            },
        }))
    }

    return (
        <div className="pr-2">
            <h5 className="w-full text-xl text-secondary pb-4 font-semibold">
                Persönliche Daten
            </h5>
            <div className="space-y-12 pb-6">
                <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-3">
                    <input
                        type="text"
                        name="firstName"
                        onChange={handleInputChange}
                        placeholder="Vorname*"
                        value={infoData.screenPersonendaten.firstName || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="text"
                        name="lastName"
                        onChange={handleInputChange}
                        placeholder="Nachname*"
                        value={infoData.screenPersonendaten.lastName || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="text"
                        name="phonenumber"
                        onChange={handleInputChange}
                        placeholder="Telefonnummer*"
                        value={infoData.screenPersonendaten.phonenumber || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="text"
                        name="email"
                        onChange={handleInputChange}
                        placeholder="Email*"
                        value={infoData.screenPersonendaten.email || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                </div>
                <div className="flex flex-col pb-4 md:pb-0 space-y-4 md:space-y-0 md:flex-row md:space-x-3">
                    <input
                        type="text"
                        name="street"
                        onChange={handleInputChange}
                        placeholder="Straße*"
                        value={infoData.screenPersonendaten.street || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="text"
                        name="nr"
                        onChange={handleInputChange}
                        placeholder="Nummer*"
                        value={infoData.screenPersonendaten.nr || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="text"
                        name="plz"
                        onChange={handleInputChange}
                        placeholder="Postleitzahl*"
                        value={infoData.screenPersonendaten.plz || ''}
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                    <input
                        type="text"
                        name="city"
                        onChange={handleInputChange}
                        placeholder="Stadt*"
                        value={infoData.screenPersonendaten.city || ''}
                        required
                        className={`border  ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                </div>
                <hr />
                <div className="space-y-2">
                    <h5 className="md:text-xl text-secondary font-semibold ">
                        Ihre Dokumente
                    </h5>
                    <div className="flex flex-col p-2">
                        {documents[0]?.hasOwnProperty('fileUpload') &&
                            documents.map(
                                (
                                    item: {
                                        fileUpload: {
                                            name:
                                                | string
                                                | number
                                                | boolean
                                                | React.ReactElement<
                                                      any,
                                                      | string
                                                      | React.JSXElementConstructor<any>
                                                  >
                                                | Iterable<React.ReactNode>
                                                | React.ReactPortal
                                                | null
                                                | undefined
                                        }
                                    },
                                    index: React.Key | null | undefined
                                ) => (
                                    // { item.hasOwn
                                    <div className="flex" key={index}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                                            />
                                        </svg>
                                        <p className="pl-2">
                                            {item.fileUpload.name}
                                        </p>
                                    </div>
                                    // }
                                )
                            )}

                        {!documents[0]?.hasOwnProperty('fileUpload') && (
                            <p>Keine Dokumente hochgeladen</p>
                        )}
                    </div>
                </div>
                <hr />

                <div className="space-y-4">
                    <p className="text-xl text-secondary font-semibold ">
                        Rückruf*
                    </p>
                    <div className=" grid grid-cols-2 md:flex gap-4">
                        <RadioButton
                            label="Früh (8-11 Uhr)"
                            name="erreichbarkeit"
                            value="1"
                            checkedValue={
                                infoData.screenPersonendaten.erreichbarkeit ||
                                ''
                            }
                            setCheckedValue={setCheckedValue}
                            dark={dark}
                        />
                        <RadioButton
                            label="Mittag (11-14 Uhr)"
                            name="erreichbarkeit"
                            value="2"
                            checkedValue={
                                infoData.screenPersonendaten.erreichbarkeit ||
                                ''
                            }
                            setCheckedValue={setCheckedValue}
                            dark={dark}
                        />
                        <RadioButton
                            label="Nachmittag (14-17 Uhr)"
                            name="erreichbarkeit"
                            value="3"
                            checkedValue={
                                infoData.screenPersonendaten.erreichbarkeit ||
                                ''
                            }
                            setCheckedValue={setCheckedValue}
                            dark={dark}
                        />
                        <RadioButton
                            label="Abend (17-20 Uhr)"
                            name="erreichbarkeit"
                            value="4"
                            checkedValue={
                                infoData.screenPersonendaten.erreichbarkeit ||
                                ''
                            }
                            setCheckedValue={setCheckedValue}
                            dark={dark}
                        />
                    </div>
                </div>
            </div>
            <hr />
        </div>
    )
}

export default Personendaten
