

type BaseInputFieldType = {
  label: string;
  type: string;
  placeholder: string;
  value: string;
  setvalue: (e: any) => void;
  required?: boolean;
};
export default function BaseInputField(props: BaseInputFieldType) {
  return (
    <label className="form-control w-full">
      <div className="label">
        <span className="label-text">{props.label}</span>
      </div>
      <input
        type={props.type}
        placeholder={props.placeholder}
        className="input input-bordered input-secondary w-64"
        value={props.value}
        onChange={(e) => props.setvalue(e.target.value)}
        required={props.required}
      />
    </label>
  );
}
