import Hero from '../../components/general/Hero'
import DropDownArrow from '../../components/general/DropDownArrow'
import ContactFormWrapper from '../../components/general/ContactFormWrapper'
import { getFaqData } from '../../hooks/cmsApiCalls'
import { useQuery } from '@tanstack/react-query'
import ErrorPage from '../404/ErrorPage'
import Loading from '../../components/common/Loading'
import BaseSeo from '../../components/layout/BaseSeo'

function FAQ() {
    const { error, data, isLoading } = useQuery({
        queryKey: ['faqData'],
        queryFn: getFaqData,
    })

    return (
        <>
            {error && <ErrorPage />}
            {isLoading && <Loading />}
            {data && (
                <>
                    <BaseSeo seo={data.attributes?.seo} />
                    <Hero
                        heading={String(data.attributes.hero_headline)}
                        subtitle={String(data.attributes.hero_subtitle)}
                        heroImg={String(data.attributes.hero_img?.data.attributes?.url)}
                    />
                    <DropDownArrow faqs={data?.attributes.faq?.question_and_answer} distance />
                    <ContactFormWrapper />
                </>
            )}
        </>
    )
}

export default FAQ
