import { RefObject, useEffect, useRef, useState } from 'react'
import { ReactComponent as RotesPlus } from '../../res/RotesPlus.svg'
import { MdArrowBackIos, MdInfoOutline } from 'react-icons/md'
import PopUpVerstossDropdown from './PopUpVerstossDropdown'
import RadioButton from './RadioButton'
import { HashLink } from 'react-router-hash-link'
import axios from 'axios'
import { CDS } from '../../services/constants'

function ContactForm() {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)

    // const hideDropdown = () => setIsDropdownVisible(false)

    const [checkedValues, setCheckedValues] = useState({
        erreichbarkeit: '',
        documents: '',
        data_protection: '',
    })

    const setCheckedValue = (name: string, value: string) => {
        setCheckedValues((prevState) => ({ ...prevState, [name]: value }))
    }

    const [name, setname] = useState('')
    const [mail, setmail] = useState('')
    const [telnr, settelnr] = useState('')
    const [selectedItem, setSelectedItem] = useState('')
    const [showErrorMsg, setShowErrorMsg] = useState(false)
    const [errorText, setErrorText] = useState(undefined)
    const [thankYouScreen, setThankYouScreen] = useState(false)

    const sendMessage = async () => {
        if (
            name === '' ||
            mail === '' ||
            telnr === '' ||
            checkedValues.data_protection === '' ||
            checkedValues.documents === '' ||
            checkedValues.erreichbarkeit === ''
        ) {
            setShowErrorMsg(true)
            return
        }
        const body = {
            name: name,
            email: mail,
            phone: telnr,
            documents: checkedValues.documents,
            availability: checkedValues.erreichbarkeit,
            violation: selectedItem,
        }

        await axios
            .post(CDS.URL, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth': CDS.API_Key,
                },
            })
            .then((_) => {
                setShowErrorMsg(false)
                setThankYouScreen(true)
            })
            .catch((err) => {
                setShowErrorMsg(true)
                setErrorText(err.response.data.DisplayMessage ?? 'Kontaktformular konnte nicht gesendet werden.')
            })
    }

    const navRef1: RefObject<HTMLDivElement> = useRef(null)
    const navRef2: RefObject<HTMLUListElement> = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            if (
                isDropdownVisible &&
                navRef1.current &&
                !navRef1.current.contains(event.target) &&
                navRef2.current &&
                !navRef2.current.contains(event.target)
            ) {
                setIsDropdownVisible(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isDropdownVisible])

    return (
        <>
            <div className="relative w-full h-fit bg-primary py-4 lg:py-8">
                <div className="absolute -top-8 md:-top-10 right-4 md:right-2 w-16 md:w-20 h-16 md:h-20 lg:-top-16 lg:-left-16 lg:w-32 lg:h-32">
                    <RotesPlus />
                </div>
                {!thankYouScreen ? (
                    <div className="my-8 mx-4 md:mx-12 lg:my-16 lg:mx-24">
                        <h5 className="text-white text-2xl lg:text-4xl">Rückruf vereinbaren</h5>
                        <hr className="h-px my-3 mb-5 mr-auto w-3/5 bg-gray-500 border-0"></hr>
                        <div className="text-white space-y-6 md:space-y-3">
                            <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row md:space-x-2">
                                <label className="md:w-1/2 ">
                                    <input
                                        type="text"
                                        className="w-full bg-primary rounded-lg border-[0.15rem] border-secondary p-2 placeholder-white"
                                        name="name"
                                        onChange={(e) => setname(e.target.value)}
                                        placeholder="Name*"></input>
                                </label>
                                <label className="md:w-1/2">
                                    <input
                                        type="text"
                                        className="w-full bg-primary rounded-lg border-[0.15rem] border-secondary p-2 placeholder-white"
                                        name="email"
                                        onChange={(e) => setmail(e.target.value)}
                                        placeholder="E-Mail*"></input>
                                </label>
                            </div>
                            <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row md:space-x-2">
                                <label className="md:w-1/2 ">
                                    <input
                                        type="text"
                                        className="w-full bg-primary rounded-lg border-[0.15rem] border-secondary p-2 placeholder-white"
                                        name="telefon"
                                        onChange={(e) => settelnr(e.target.value)}
                                        placeholder="Telefon*"></input>
                                </label>
                                <label className="md:w-1/2 relative hover:cursor-pointer">
                                    <div
                                        ref={navRef1}
                                        className="w-full bg-primary rounded-lg border-[0.15rem] border-secondary p-2 placeholder-white text-white appearance-none"
                                        onClick={() => {
                                            setIsDropdownVisible(!isDropdownVisible)
                                        }}>
                                        {selectedItem || 'Verstoß*'}
                                        <div
                                            className={`absolute  right-0 ${
                                                isDropdownVisible ? 'rotate-90 top-1 ' : '-rotate-90 -top-2'
                                            } w-fit h-fit p-3 text-2xl text-secondary`}>
                                            <MdArrowBackIos />
                                        </div>
                                    </div>
                                    {isDropdownVisible && (
                                        <PopUpVerstossDropdown
                                            nav={navRef2}
                                            onSelect={(itemName: string) => {
                                                setSelectedItem(itemName)
                                                setIsDropdownVisible(false)
                                            }}
                                        />
                                    )}
                                </label>
                            </div>
                            <div className="space-y-2">
                                <p>Erreichbarkeit*</p>
                                <div className="grid grid-cols-2 gap-2">
                                    <RadioButton
                                        label="Früh (8-11 Uhr)"
                                        name="erreichbarkeit"
                                        value="Früh (8-11 Uhr)"
                                        checkedValue={checkedValues.erreichbarkeit}
                                        setCheckedValue={setCheckedValue}
                                    />
                                    <RadioButton
                                        label="Mittag (11-14 Uhr)"
                                        name="erreichbarkeit"
                                        value="Mittag (11-14 Uhr)"
                                        checkedValue={checkedValues.erreichbarkeit}
                                        setCheckedValue={setCheckedValue}
                                    />
                                    <RadioButton
                                        label="Nachmittag (14-17 Uhr)"
                                        name="erreichbarkeit"
                                        value="Nachmittag (14-17 Uhr)"
                                        checkedValue={checkedValues.erreichbarkeit}
                                        setCheckedValue={setCheckedValue}
                                    />
                                    <RadioButton
                                        label="Abend (17-20 Uhr)"
                                        name="erreichbarkeit"
                                        value="Abend (17-20 Uhr)"
                                        checkedValue={checkedValues.erreichbarkeit}
                                        setCheckedValue={setCheckedValue}
                                    />
                                </div>
                            </div>
                            <div className="space-y-2">
                                <p>Liegen Dokumente vor?*</p>
                                <div className="grid grid-cols-2 gap-2">
                                    <RadioButton
                                        label="Ja"
                                        name="documents"
                                        value="ja"
                                        checkedValue={checkedValues.documents}
                                        setCheckedValue={setCheckedValue}
                                    />
                                    <RadioButton
                                        label="Nein"
                                        name="documents"
                                        value="nein"
                                        checkedValue={checkedValues.documents}
                                        setCheckedValue={setCheckedValue}
                                    />
                                </div>
                            </div>
                            <div className="pt-4">
                                <RadioButton
                                    label={
                                        <span>
                                            Sie haben unsere{' '}
                                            <HashLink
                                                to="/datenschutz"
                                                className="underline text-white visited:text-gray-400"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                Datenschutzbestimmungen
                                            </HashLink>{' '}
                                            gelesen *
                                        </span>
                                    }
                                    name="data_protection"
                                    value="read"
                                    checkedValue={checkedValues.data_protection}
                                    setCheckedValue={setCheckedValue}
                                />
                            </div>
                            <div className="flex items-center">
                                <div className="px-3 scale-125">
                                    <MdInfoOutline />
                                </div>
                                <div>*Pflichtangaben</div>
                            </div>
                            <div className="pt-4">
                                {showErrorMsg && (
                                    <p className="text-error font-semibold pb-2 text-sm">{errorText ?? 'Bitte alle Pflichtangaben ausfüllen!'}</p>
                                )}
                                <button
                                    onClick={sendMessage}
                                    className="w-fit rounded-lg px-4 py-2 border-2 border-secondary uppercase hover:bg-secondary active:scale-95">
                                    Senden
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col justify-center items-center h-96 text-white">
                        <p className="text-3xl uppercase font-bold underline pb-2">Danke!</p>
                        <p className="text-lg max-w-md text-center">
                            Wir werden Ihre Anfrage schnellstmöglich bearbeiten und uns umgehend bei Ihnen melden.
                        </p>
                    </div>
                )}
            </div>
        </>
    )
}

export default ContactForm
