import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ModalContextProvider } from './components/contexts/ModalContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import Loading from './components/common/Loading'
import { eventLogger, tokenLogger } from './platform/security/kcEventListener'
import { CookiesProvider } from 'react-cookie'

/**
 * This is the keycloak configuration object.
 */

// the keycloak object is created here but not connected to the server yet!! --> see index.tsx
const keycloakConfig = new Keycloak({
    url: process.env.REACT_APP_KC_URL!, // change to https://auth.zentrale.wicode.io
    realm: process.env.REACT_APP_KC_REALM!, // change to anwalt-test
    clientId: process.env.REACT_APP_KC_CLIENT_ID!,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <QueryClientProvider client={new QueryClient()}>
        <CookiesProvider>
            <ModalContextProvider>
                <BrowserRouter>
                    <ReactKeycloakProvider
                        authClient={keycloakConfig}
                        LoadingComponent={<Loading />}
                        autoRefreshToken={true}
                        onEvent={eventLogger}
                        onTokens={tokenLogger}
                        initOptions={{
                            onLoad: 'check-sso',
                        }}>
                        <App />
                    </ReactKeycloakProvider>
                </BrowserRouter>
            </ModalContextProvider>
        </CookiesProvider>
    </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
