import { useContext, useEffect, useMemo, useState } from 'react'
import BaseModal from '../layout/BaseModal'
import ScreenPersonendaten from './screens/ScreenPersonendaten'
import { caseType2, personalType2 } from '../../platform/types/Types'
import Personendaten from './componentsOverviewModal/Personendaten'
import { ReactComponent as Check } from '../../res/check.svg'
import { ReactComponent as Alert } from '../../res/alert.svg'
import dayjs from 'dayjs'
import { formatInfoData } from './formatInfoData'
import ScreenMobileAuswahl from './screens/ScreenMobileAuswahl'
import { VerstossType } from '../../types/Types'
import { useAddUserMutation } from '../../platform/api/services'
import { useQueryClient } from '@tanstack/react-query'
import { ModalContext } from '../contexts/ModalContext'
import { screenConfig } from './modalObjects'
import LoadingUpload from '../common/LoadingUpload'
import { AxiosError } from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
import initialInfoData from './helper/initialModalData'
import { personalDataValid, verstossDataValid } from './helper/validation'
type ModalType = {
    scrollToRef?: any
    modalId: number
    firstScreenType: VerstossType
    closeModal: () => void
    setCurrentFirstScreenType?: (e: VerstossType) => void
    isSubPage?: boolean
    screen?: boolean
}

function Modal({
    scrollToRef,
    modalId,
    closeModal,
    firstScreenType,
    isSubPage,
    screen,
    setCurrentFirstScreenType,
}: ModalType) {
    const [currentScreen, setCurrentScreen] = useState<number>(screen ? 0 : 1)
    const [reCaptchaData, setReCaptchaData] = useState<string | undefined>(
        undefined
    )

    const [infoData, setInfoData] = useState(initialInfoData)

    // reset the data in the data object
    const clearInput = () => {
        setInfoData(initialInfoData)
        setdocuments([])
    }

    // clears input every time modal opens and sets initial screen to 1, changes automatically to 0 if "overview" is detected
    useEffect(() => {
        setCurrentScreen(1)
        clearInput()
    }, [firstScreenType])

    // custom close modal function to set the current screen to 1 after closing the modal
    const closeModalResetDataAndScreen = () => {
        closeModal()
        setCurrentScreen(1)
    }

    /**
     * The recaptche with all its logic
     * @returns the recaptcha block
     */
    const recaptchaBlock = () => (
        <div className="w-full flex justify-center items-center p-6">
            <ReCAPTCHA
                sitekey="6LeuVM8pAAAAAA_M47hi3kouCEPKdanRix6Q8nbE"
                //ref={reCaptchaRef}
                onChange={(e) => {
                    if (e) setReCaptchaData(e)
                }}
            />
        </div>
    )

    const handleSelectScreenType = (screenType: VerstossType) => {
        if (setCurrentFirstScreenType) {
            setCurrentFirstScreenType(screenType)
        }
        setCurrentScreen(1)
    }

    const FirstScreenComponent = screenConfig[firstScreenType]?.component
    const FirstScreenSumComponent = screenConfig[firstScreenType]?.sumComponent

    // the Bottom Row of the modal
    const BottomRow = () => {
        if (isSubPage && currentScreen === 4) {
            return null
        }

        return (
            <>
                {status !== 'pending' && (
                    <div className="flex text-lg gap-x-4 ">
                        {!(currentScreen === 1) && !(currentScreen === 4) && (
                            <button
                                className={`${
                                    currentScreen === 0 ? 'hidden' : ''
                                } underline mr-auto underline-offset-2 active:scale-95 py-2`}
                                onClick={handleBack}>
                                Zurück
                            </button>
                        )}
                        <button
                            disabled={!areAllRequiredFieldsValid}
                            className={`${
                                currentScreen === 0 ? 'hidden' : ''
                            } ml-auto px-4 py-2 btn btn-sm bg-secondary uppercase text-white rounded-lg active:scale-95`}
                            onClick={handleForward}>
                            {forwardButtonText}
                        </button>
                    </div>
                )}
            </>
        )
    }

    // check if there are any blanks where we need inputs
    const areAllRequiredFieldsValid = useMemo(() => {
        switch (currentScreen) {
            case 1:
                return verstossDataValid(firstScreenType, infoData)

            case 2:
                return personalDataValid(infoData)
            case 3:
                return (
                    reCaptchaData !== undefined &&
                    personalDataValid(infoData) &&
                    verstossDataValid(firstScreenType, infoData)
                )
            case 4:
                return true
            default:
                return false
        }
    }, [currentScreen, infoData, firstScreenType, reCaptchaData])

    // handle modal navigation page back
    const handleBack = () => {
        if (currentScreen === 4 || currentScreen === 5) {
            setCurrentScreen(3)
        } else if (currentScreen === 3) {
            setCurrentScreen(2)
        } else if (currentScreen === 2) {
            setCurrentScreen(1)
        }
    }

    // add documents to user
    const [documents, setdocuments] = useState<{ fileUpload: File }[]>([])
    const queryClient = useQueryClient()

    // add user mutation
    const modalCon = useContext(ModalContext)
    const onErrorFunction = (err: AxiosError) => {
        if (err.response?.status === 409) {
            setCurrentScreen(5)
            return
        }
        console.log(err, err.response?.status)
        alert('reCAPTCHA überprüfen')

        if (!isSubPage) {
            modalCon?.trigger(modalId)
        }
    }
    const onSuccessFunction = (props: any) => {
        setCurrentScreen(4)
    }

    // add user mutation
    const { mutate: addUser, status } = useAddUserMutation(
        queryClient,
        onSuccessFunction,
        onErrorFunction
    )

    // handle modal navigation page forward & post new user in the end
    const handleForward = async () => {
        if (currentScreen === 1) {
            setCurrentScreen(2)
            if (isSubPage) scrollToRef()
        } else if (currentScreen === 2) {
            setCurrentScreen(3)
            if (isSubPage) scrollToRef()
        } else if (currentScreen === 3) {
            const { formattedInfo, noteValue } = formatInfoData({
                infoData: infoData,
            })
            const title =
                screenConfig[firstScreenType]?.title || 'Unbekannter Verstoß'
            const currentInfoSet = new Set<string>([])
            formattedInfo.forEach((item) => {
                return currentInfoSet.add(item)
            })
            const newCase: caseType2 = {
                title: title,
                created_at: dayjs().toString(),
                updated_at: dayjs().toString(),
                deadline: 'keine Angabe',
                status: 'new',
                info: Array.from(currentInfoSet),
                note: noteValue,
            }
            const newUserObject: {
                personal: personalType2
                cases: caseType2[]
            } = {
                personal: {
                    first_name: infoData.screenPersonendaten.firstName,
                    last_name: infoData.screenPersonendaten.lastName,
                    mail: infoData.screenPersonendaten.email,
                    tel: infoData.screenPersonendaten.phonenumber,
                    insurance: false,
                    callOption: Number(
                        infoData.screenPersonendaten.erreichbarkeit
                    ),
                    adress: {
                        street: infoData.screenPersonendaten.street,
                        nr: infoData.screenPersonendaten.nr,
                        plz: infoData.screenPersonendaten.plz,
                        city: infoData.screenPersonendaten.city,
                    },
                },
                cases: [newCase],
            }
            const formData = new FormData()
            formData.append('recaptcha', reCaptchaData!)
            documents.forEach((doc) => {
                if (doc && doc.fileUpload instanceof File) {
                    formData.append('documents', doc.fileUpload)
                }
            })
            formData.append('personal', JSON.stringify(newUserObject.personal))
            formData.append('cases', JSON.stringify(newUserObject.cases))

            addUser({ formData })
        } else if (currentScreen === 4 || currentScreen === 5) {
            setCurrentScreen(1)
            closeModalResetDataAndScreen()
            clearInput()
        }
    }

    const forwardButtonText =
        currentScreen === 1
            ? 'Weiter'
            : currentScreen === 2
            ? 'Daten Prüfen'
            : currentScreen === 3
            ? 'Absenden'
            : 'Zurück zur Website'

    return (
        <>
            {isSubPage ? (
                <div className="min-h-16">
                    {status === 'pending' ? (
                        <LoadingUpload dark hfull />
                    ) : (
                        <>
                            {currentScreen === 1 && (
                                <FirstScreenComponent
                                    infoData={infoData}
                                    setInfoData={setInfoData}
                                    dark={true}
                                    handleSelectScreenType={
                                        handleSelectScreenType
                                    }
                                    documents={undefined}
                                    setdocuments={function (e?: any): void {
                                        throw new Error(
                                            'Function not implemented.'
                                        )
                                    }}
                                />
                            )}
                            {currentScreen === 2 && (
                                <ScreenPersonendaten
                                    infoData={infoData}
                                    setInfoData={setInfoData}
                                    dark={true}
                                    documents={documents}
                                    setdocuments={setdocuments}
                                />
                            )}
                            {currentScreen === 3 && (
                                <div className="overflow-y-scroll relative">
                                    <FirstScreenSumComponent
                                        documents={undefined}
                                        setdocuments={() => {}}
                                        infoData={infoData}
                                        setInfoData={setInfoData}
                                        dark={true}
                                        handleSelectScreenType={
                                            handleSelectScreenType
                                        }
                                    />
                                    <hr className="my-6" />
                                    <Personendaten
                                        documents={documents}
                                        setdocuments={() => {}}
                                        setInfoData={setInfoData}
                                        infoData={infoData}
                                        dark={true}
                                    />
                                    <div className="py-2" />
                                    {recaptchaBlock()}
                                    {/* <hr />
                                <Requirements
                                    setInfoData={setInfoData}
                                    infoData={infoData}
                                    dark={true}
                                /> */}
                                </div>
                            )}
                            {currentScreen === 4 && (
                                <div className="flex flex-col justify-center items-center h-full space-y-8">
                                    <Check className="w-20" />
                                    <p className="text-center text-3xl">
                                        Vielen Dank für Ihr Vertrauen,
                                        <br /> Wir werden uns in Kürze bei Ihnen
                                        melden!
                                    </p>
                                </div>
                            )}
                            {currentScreen === 5 && (
                                <div className="flex flex-col justify-center items-center h-full space-y-8">
                                    <Alert className="w-20" />
                                    <p className="text-center text-3xl">
                                        Ein Mandantenprofil mit dieser Email
                                        existiert bereits.
                                        <br /> Bitte melden Sie sich an und
                                        melden Sie Ihren Fall in der Plattform!
                                    </p>
                                </div>
                            )}
                            <BottomRow />
                        </>
                    )}
                </div>
            ) : (
                <BaseModal modalId={modalId} bottomRow={<BottomRow />}>
                    {status === 'pending' ? (
                        <LoadingUpload hfull />
                    ) : (
                        <>
                            {currentScreen === 0 && (
                                <ScreenMobileAuswahl
                                    handleSelectScreenType={
                                        handleSelectScreenType
                                    }
                                />
                            )}
                            {currentScreen === 1 && (
                                <FirstScreenComponent
                                    infoData={infoData}
                                    setInfoData={setInfoData}
                                    dark={false}
                                    handleSelectScreenType={
                                        handleSelectScreenType
                                    }
                                    documents={undefined}
                                    setdocuments={function (e?: any): void {
                                        throw new Error(
                                            'Function not implemented.'
                                        )
                                    }}
                                />
                            )}
                            {currentScreen === 2 && (
                                <ScreenPersonendaten
                                    infoData={infoData}
                                    setInfoData={setInfoData}
                                    dark={false}
                                    documents={documents}
                                    setdocuments={setdocuments}
                                />
                            )}
                            {currentScreen === 3 && (
                                <div className="overflow-y-scroll relative">
                                    <FirstScreenSumComponent
                                        infoData={infoData}
                                        setInfoData={setInfoData}
                                        dark={false}
                                        handleSelectScreenType={
                                            handleSelectScreenType
                                        }
                                        documents={undefined}
                                        setdocuments={() => {}}
                                    />
                                    <hr className="my-6" />
                                    <Personendaten
                                        documents={documents}
                                        setdocuments={() => {}}
                                        setInfoData={setInfoData}
                                        infoData={infoData}
                                        dark={false}
                                    />
                                    {recaptchaBlock()}
                                </div>
                            )}
                            {currentScreen === 4 && (
                                <div className="flex flex-col justify-center items-center h-full space-y-8">
                                    <Check className="w-20" />
                                    <p className="text-center text-3xl">
                                        Vielen Dank für Ihr Vertrauen,
                                        <br /> Wir werden uns in Kürze bei Ihnen
                                        melden!
                                    </p>
                                </div>
                            )}
                            {currentScreen === 5 && (
                                <div className="flex flex-col justify-center items-center h-full space-y-8">
                                    <Alert className="w-20" />
                                    <p className="text-center text-3xl">
                                        Ein Mandantenprofil mit dieser Email
                                        existiert bereits.
                                        <br /> Bitte melden Sie sich an und
                                        melden Sie Ihren Fall in der Plattform!
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                </BaseModal>
            )}
        </>
    )
}

export default Modal
