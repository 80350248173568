import ReactMarkdown from 'react-markdown'
import { customComponentsGreenFont } from '../layout/customComponents'
import gfm from 'remark-gfm'


type SeparatorType = {
    content: string
}

function Seperator({ content }: SeparatorType) {
    return (
        <>
            <div className="w-full h-fit bg-primary relative flex items-center justify-center">
                {/* <!-- Outer container to span the full width with background color --> */}

                <div className="max-w-screen-xl mx-auto w-full flex flex-col lg:flex-row items-center justify-center">
                    {/* <!-- Inner container with max width and centered content --> */}

                    <div className="absolute -top-8 md:-top-10 left-0 h-16 w-16 md:w-20 md:h-20 lg:h-32 lg:w-32 lg:-top-16 lg:left-36 ">
                        <svg
                            id="Ebene_1"
                            data-name="Ebene 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 337.87 337.87">
                            <path
                                className="fill-[#db612f]"
                                d="m41.43,187.24c-1.18-2.75-2.17-5.61-2.95-8.56-.79-2.95-1.18-6.4-1.18-10.33,0-6.69,1.86-11.71,5.6-15.06,3.74-3.33,8.76-5.02,15.06-5.02h238.48c.79,2.36,1.67,5.12,2.66,8.27.98,3.16,1.48,6.5,1.48,10.03,0,6.3-1.78,11.32-5.31,15.06-3.54,3.74-8.66,5.61-15.35,5.61H41.43Zm149.94,113.92c-2.76,1.18-5.71,2.17-8.86,2.95-3.15.79-6.89,1.18-11.21,1.18-7.88,0-13.68-1.78-17.42-5.31-3.74-3.54-5.61-9.64-5.61-18.3V36.71c3.15-1.18,6.4-2.16,9.75-2.95,3.33-.79,7.38-1.18,12.1-1.18,7.47,0,12.88,1.78,16.23,5.31,3.34,3.54,5.02,9.65,5.02,18.3v244.98Z"
                            />
                        </svg>
                    </div>

                    <div className="text-white text-xl sm:text-2xl py-8 px-8 lg:py-14 lg:text-4xl md:tracking-wide text-center md:text-start leading-6">
                        <ReactMarkdown
                            components={customComponentsGreenFont}
                            remarkPlugins={[gfm]}>
                            {content}
                        </ReactMarkdown>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Seperator
