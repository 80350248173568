export const customComponentsOrangeFont = {
    strong: ({ children }: any) => (
        <span className="text-accent">{children}</span>
    ),
}

export const customComponentsGreenFont = {
    strong: ({ children }: any) => (
        <span className="text-secondary">{children}</span>
    ),
}
