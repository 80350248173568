import React from 'react'
import '../../style/markdown.css'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

type RichTextType = {
    rText?: string
    optionBoolean?: boolean
}

function RichText({ rText, optionBoolean }: RichTextType) {
    return (
        <div className={`max-w-screen-xl p-4 xl:px-0 ${optionBoolean && 'w-[22rem] sm:w-full overflow-x-hidden'}`}>
            <ReactMarkdown remarkPlugins={[remarkGfm]} children={rText} className={'markdown'} />
        </div>
    )
}

export default RichText
