import AbstandsVerstoss from "./componentsOverviewModal/AbstandsVerstoss";
import AlkoholVerstoss from "./componentsOverviewModal/AlkoholVerstoss";
import GeschwindigkeitsVerstoss from "./componentsOverviewModal/GeschwindigkeitsVerstoss";
import Handyverstoss from "./componentsOverviewModal/Handyverstoss";
import IllegaleAutorennen from "./componentsOverviewModal/IllegaleAutorennen";
import ParkAbschleppmassnahmen from "./componentsOverviewModal/ParkAbschleppmassnahmen";
import Personendaten from "./componentsOverviewModal/Personendaten";
import RotlichtVerstoss from "./componentsOverviewModal/RotlichtVerstoss";
import SonstigerVerstoss from "./componentsOverviewModal/SonstigerVerstoss";
import ScreenAbstandsverstoss from "./screens/ScreenAbstandsverstoss";
import ScreenAlkoholverstoss from "./screens/ScreenAlkoholverstoss";
import ScreenGeschwindigkeitsverstoss from "./screens/ScreenGeschwindigkeitsverstoss";
import ScreenHandyverstoss from "./screens/ScreenHandyverstoss";
import ScreenIllegaleAutorennen from "./screens/ScreenIllegaleAutorennen";
import ScreenMobileAuswahl from "./screens/ScreenMobileAuswahl";
import ScreenParkAbschleppmassnahmen from "./screens/ScreenParkAbschleppmassnahmen";
import ScreenPersonendaten from "./screens/ScreenPersonendaten";
import ScreenRotlichtverstoss from "./screens/ScreenRotlichtverstoss";
import ScreenSonstigeDelikte from "./screens/ScreenSonstigeDelikte";

export const screenConfig = {
    geschwindigkeitsverstoss: {
        component: ScreenGeschwindigkeitsverstoss,
        sumComponent: GeschwindigkeitsVerstoss,
        title: 'Geschwindigkeitsverstoß',
    },
    alkoholverstoss: {
        component: ScreenAlkoholverstoss,
        sumComponent: AlkoholVerstoss,
        title: 'Alkoholverstoß',
    },
    handyverstoss: {
        component: ScreenHandyverstoss,
        sumComponent: Handyverstoss,
        title: 'Handyverstoß',
    },
    rotlichtverstoss: {
        component: ScreenRotlichtverstoss,
        sumComponent: RotlichtVerstoss,
        title: 'Rotlichtverstoß',
    },
    personendaten: {
        component: ScreenPersonendaten,
        sumComponent: Personendaten,
        title: 'Personendaten',
    },
    abstandsverstoss: {
        component: ScreenAbstandsverstoss,
        sumComponent: AbstandsVerstoss,
        title: 'Abstandsverstoß',
    },
    sonstigedelikte: {
        component: ScreenSonstigeDelikte,
        sumComponent: SonstigerVerstoss,
        title: 'Sonstiger Verstoß',
    },
    parkundabschleppmassnahmen: {
        component: ScreenParkAbschleppmassnahmen,
        sumComponent: ParkAbschleppmassnahmen,
        title: 'Park- und Abschleppmaßnahmen',
    },
    overview: {
        component: ScreenMobileAuswahl,
        sumComponent: ScreenMobileAuswahl,
        title: '',
    },
    illegaleautorennen: {
        component: ScreenIllegaleAutorennen,
        sumComponent: IllegaleAutorennen,
        title: 'Illegale Autorennen',
    },
}