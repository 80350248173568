import BaseToggle from '../../../BaseToggle'
import BaseViolationForm from '../../../BaseViolationForm'
import { RaceViolationType } from './RaceViolation'

export default function RaceViolationScreen1({
    crash,
    personindanger,
    necessary,
    setcrash,
    setnecessary,
    setpersonindanger,
}: RaceViolationType) {
    return (
        <BaseViolationForm>
            <BaseToggle
                value1={'Unfall: Ja'}
                value2={'Unfall: Nein'}
                onChange={setcrash}
                checked={crash}
                group="crash"
                title="Kam es zu einem Unfall?"
                label1="Ja"
                label2="Nein"
            />
            <BaseToggle
                value1={'Beruflich Angewiesen: Ja'}
                value2={'Beruflich Angewiesen: Nein'}
                checked={necessary}
                onChange={setnecessary}
                group="necessary"
                title="Sind Sie beruflich auf den Führerschein angewiesen?"
                label1="Ja"
                label2="Nein"
            />
            <BaseToggle
                checked={personindanger}
                value1="vorläufige Entziehung der Fahrerlaubnis?: Ja"
                value2="vorläufige Entziehung der Fahrerlaubnis?: Nein"
                onChange={setpersonindanger}
                group="maystilldrivesssssssss"
                title={
                    'Beschluss über vorläufige Entziehung der Fahrerlaubnis?'
                }
                label1={'Ja'}
                label2={'Nein'}
            />
        </BaseViolationForm>
    )
}
