import BaseToggle from "../../../BaseToggle";
import BaseViolationForm from "../../../BaseViolationForm";
import { PaceViolationType } from "./PaceViolation";

export default function PaceViolationScreen1({
  location,
  vehicle,
  timeline,
  necessary,
  pace,
  setlocation,
  setvehicle,
  settimeline,
  setnecessary,
  setpace
}: PaceViolationType) {
  return (
    <BaseViolationForm>
      <BaseToggle
        group="location"
        title={"Ort der Geschwindigkeitsübertretung"}
        label1="Innerorts"
        label2="Außerorts"
        checked={location}
        value1="Wo: innerorts"
        value2="Wo: außerorts"
        onChange={setlocation}
      />
      <BaseToggle
        group="vehicle-type"
        title={"Fahrzeugtyp"}
        label1="PKW/Motorrad"
        label2="LKW"
        checked={vehicle}
        value1="Fahrzeugtyp: PKW/Motorrad"
        value2="Fahrzeugtyp: LKW"
        onChange={setvehicle}
      />
      <p className="pt-8">Geschwindkeitsüberschreitung</p>
      <select value={pace === '' ? undefined : pace} onChange={(e) => setpace(e.target.value)} className="select select-bordered w-full bg-gray-100 select-primary border-2">
        <option disabled selected>
          Bitte auswählen...
        </option>
        <option value={'Geschwindkeitsüberschreitung: 0 - 15 km/h'}>0 - 15 km/h</option>
        <option value={'Geschwindkeitsüberschreitung: 16 - 20 km/h'}>16 - 20 km/h</option>
        <option value={'Geschwindkeitsüberschreitung: 21 - 30 km/h'}>21 - 30 km/h</option>
        <option value={'Geschwindkeitsüberschreitung: 31 - 40 km/h'}>31 - 40 km/h</option>
        <option value={'Geschwindkeitsüberschreitung: mehr als 40 km/h'}>mehr als 40 km/h</option>
      </select>

      <BaseToggle
        group="timeline"
        title={
          "Wurden Sie in den letzten 12 Monaten bereits mit mehr als 26 km/h geblitzt?"
        }
        label1="Ja"
        label2="Nein"
        checked={timeline}
        value1="in den letzten 12 Monaten mit über 12km/h zu viel geblitzt?: Ja"
        value2="in den letzten 12 Monaten mit über 12km/h zu viel geblitzt?: Nein"
        onChange={settimeline}
      />
      <BaseToggle
        group="necessary"
        title={"Sind Sie beruflich auf den Führerschein angewiesen?"}
        label1="Ja"
        label2="Nein"
        checked={necessary}
        value1="Beruflich Angewiesen?: Ja"
        value2="Beruflich Angewiesen?: Nein"
        onChange={setnecessary}
      />
    </BaseViolationForm>
  );
}
