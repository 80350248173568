import React from "react";
import BaseCaseForm from "../../../BaseCaseForm";
import BaseTextAreaSpecialties from "../../../BaseTextAreaSpecialties";
import BaseDataCheck from "../../../BaseDataCheck";

type DifferentViolationType = {
  check1: boolean;
  setcheck1: (e: boolean) => void;
  check2: boolean;
  setcheck2: (e: boolean) => void;
  addCaseClick: () => void;
  note: string;
  setnote: (e: string) => void;
};

export default function DifferentViolation({
  addCaseClick,
  note,
  setnote,
  check1,
  check2,
  setcheck1,
  setcheck2,
}: DifferentViolationType) {
  return (
    <BaseCaseForm
      buttonRow={
        <div className="flex justify-end">
          <button
            onClick={addCaseClick}
            className="btn bg-accent hover:bg-accent/90 grow text-base-100"
            disabled={!check1 || !check2 || note === ''}
            >
            Fall melden
          </button>
        </div>
      }
    >
      <BaseTextAreaSpecialties
        title={"Was wird Ihnen vorgeworfen?"}
        heightProps="h-80"
        value={note}
        onChange={setnote}
      />
      <BaseDataCheck
        check1={check1}
        setcheck1={setcheck1}
        check2={check2}
        setcheck2={setcheck2}
      />
    </BaseCaseForm>
  );
}
