import { HashLink } from 'react-router-hash-link'
import { ReactComponent as EdgeArrow } from '../../res/News Edge Icon.svg'
import { useMemo } from 'react'

type NewsCollapsedType = {
    heading: string
    text: string
    slug: string
    category: string
}

function NewsCollapsedHome({ heading, text, slug, category }: NewsCollapsedType) {
    return (
        <div className="relative max-w-xs border md:max-w-sm border-gray-400">
            <div className="absolute -left-[0.05rem] z-10">
                <EdgeArrow className="h-8 w-8" />
            </div>
            <div className="py-8 px-6 md:px-12 flex flex-col h-full">
                <div className="bg-white flex-grow overflow-hidden relative">
                    <h4 className="font-semibold text-secondary">
                        <HashLink
                            to={'/news/' + slug}
                            // to={{
                            //     pathname: '/news',
                            //     hash: `#${id}`,
                            //     state: { selectedPostId: id },
                            // }}
                        >
                            {heading}
                        </HashLink>
                    </h4>
                    <hr className="h-px my-3 mb-5 bg-gray-200 border-0 dark:bg-gray-500"></hr>
                    <p className="truncate-multiline mb-5 line-clamp-4">{text}</p>
                </div>
                <div className="flex-shrink-0 text-secondary text-lg">
                    <HashLink to={'/news/' + slug}>...mehr</HashLink>
                </div>
            </div>
        </div>
    )
}

export default NewsCollapsedHome
