// types
import { SliderType } from '../../types/Types'
import { API } from '../../services/constants'
import { HashLink } from 'react-router-hash-link'
import { MdInfoOutline } from 'react-icons/md'

type SliderComponentType = {
    slides: SliderType
}

function ViolationOverview({ slides }: SliderComponentType) {
    console.log(slides)
    return (
        <div className="py-6 px-2 max-w-screen-xl w-full mb-8 md:hidden">
            <div className="bg-base-200 py-4 px-4 rounded-md">
                {slides.attributes.slider_content.map((slide) => (
                    <HashLink key={slide.id} to={'/' + slide.violation_name + '#_'}>
                        <div className="flex py-2 px-4 bg-base-200 border-1 border border-primary rounded-lg my-3 space-x-6">
                            <div className=" flex-shrink-0 flex items-center justify-center bg-base-200 active:bg-base-200-focus  rounded-md">
                                <img className="w-12 h-12" src={API.img + slide.icon.data.attributes.url} alt={slide.icon.data.attributes.name} />
                            </div>
                            <p className=" float-right w-full bg-base-200 rounded-md flex flex-col justify-center font-medium">
                                {slide.icon.data.attributes.caption}
                            </p>
                        </div>
                    </HashLink>
                ))}
                <div className="flex space-x-4 pt-2 text-xs md:text-sm items-center">
                    <MdInfoOutline size={26} />
                    <p className="w-3/4 md:w-fit">{slides.attributes.info_phrase}</p>
                </div>
            </div>
        </div>
    )
}

export default ViolationOverview
