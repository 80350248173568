import React, { useState } from 'react'

type BaseDocsSelectionType = {
    extend?: boolean
    inputField?: boolean
    date?: string
    documents: any[]
    setdate: (e: any) => void
    setdoc1: (e: string) => void
    setdoc2: (e: string) => void
    setdoc3: (e: string) => void
    setdoc4: (e: string) => void
    setdoc5: (e: string) => void
    setdocuments: (e: any) => void
}

export default function BaseDocsSelection(props: BaseDocsSelectionType) {
    const handleChangeCheckbox = (
        event: React.ChangeEvent<HTMLInputElement>,
        func: number
    ) => {
        if (func === 1) {
            props.setdoc1(
                event.target.checked ? 'Zeugenfragebogen erhalten' : ''
            )
        } else if (func === 2) {
            props.setdoc2(event.target.checked ? 'Anhörungsbogen erhalten' : '')
        } else if (func === 3) {
            props.setdoc3(
                event.target.checked ? 'Bußgeldbescheid erhalten' : ''
            )
        } else if (func === 4) {
            props.setdoc4(event.target.checked ? 'Strafbefehl erhalten' : '')
        } else if (func === 5) {
            props.setdoc5(event.target.checked ? 'Anklageschrift erhalten' : '')
        }
    }
    const [documentcount, setdocumentcount] = useState<number>(0)

    // add doc to doc-array-useState
    const addDocument = () => {
        props.setdocuments((prevDocuments: any) => [
            ...prevDocuments,
            new File([], ''),
        ])
    }

    // remove Doc from useState-array
    const removeDocument = (indexToRemove: number) => {
        setdocumentcount(documentcount - 1)
        props.setdocuments((prevValues: any) =>
            prevValues.filter(
                (_: any, index: number) => index !== indexToRemove
            )
        )
    }

    const handleDocInputChange = (
        index: number,
        key: string,
        newValue?: string | File | null
    ) => {
        props.setdocuments((prevDocuments: any[]) =>
            prevDocuments.map((item, idx) => {
                return idx === index ? { ...item, [key]: newValue } : item
            })
        )
    }

    return (
        <>
            <p className="pt-8">Welche Dokumente wurden Ihnen zugestellt?</p>
            <div className="flex gap-3 pt-1">
                <input
                    type="checkbox"
                    className="checkbox"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeCheckbox(e, 1)
                    }
                />
                <label>Zeugenfragebogen</label>
            </div>
            <div className="flex gap-3 pt-1">
                <input
                    type="checkbox"
                    className="checkbox"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeCheckbox(e, 2)
                    }
                />
                <label>Anhörungsbogen</label>
            </div>
            <div className="flex gap-3 pt-1">
                <input
                    type="checkbox"
                    className="checkbox"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeCheckbox(e, 3)
                    }
                />
                <label>Bußgeldbescheid</label>
            </div>
            {props.extend && (
                <>
                    <div className="flex gap-3 pt-1">
                        <input
                            type="checkbox"
                            className="checkbox"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => handleChangeCheckbox(e, 4)}
                        />
                        <label>Strafbefehl</label>
                    </div>
                    <div className="flex gap-3 pt-1">
                        <input
                            type="checkbox"
                            className="checkbox "
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => handleChangeCheckbox(e, 5)}
                        />
                        <label>Anklageschrift</label>
                    </div>
                </>
            )}
            {props.inputField && (
                <div>
                    <p className="pt-4">Zustellungsdatum</p>
                    <input
                        type="date"
                        value={props.date}
                        placeholder="Zustellungsdatum"
                        onChange={(e) => props.setdate(e.target.value)}
                        className="input input-bordered w-full max-w-xs border-primary border-2 bg-portal-gray"
                    />
                </div>
            )}
            <div className="w-full border-b-2 border-primary pt-4 mb-2" />
            {props.documents.map((item: any, index: number) => (
                <div key={index} className="flex flex-row gap-2 items-end">
                    <div className="flex mt-2 items-center gap-2 relative">
                        <input
                            type="file"
                            className="file-input file-input-bordered w-[19rem] file-input-primary file-input-sm bg-portal-gray"
                            required
                            onChange={(e) => {
                                const file =
                                    e.target.files &&
                                    e.target.files[0] &&
                                    e.target.files[0]
                                handleDocInputChange(index, 'fileUpload', file)
                            }}
                        />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-7 h-7 cursor-pointer absolute -top-3 -right-3 bg-portal-gray"
                            onClick={() => removeDocument(index)}>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>
                </div>
            ))}
            <div
                className="flex pl-4 text-sm items-center gap-3 cursor-pointer justify-center mt-2"
                onClick={() => {
                    addDocument()
                    setdocumentcount(documentcount + 1)
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-7 h-7">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
                <p>Weiteres Dokument hinzufügen</p>
            </div>
        </>
    )
}
