
type BasePanelHeadlineType = {
    title: string,
    classProps?: string
}

export default function BasePanelHeadline({ title, classProps }: BasePanelHeadlineType) {
    return (
        <div className={`w-full flex flex-col items-center justify-center ${classProps}`}>
            <h3 className="text-2xl font-normal uppercase text-primary">{title}</h3>
            <div className="border-t-2 border-secondary w-12 mt-1"/>
        </div>
    )
}
