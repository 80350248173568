import { QueryClient, useMutation } from '@tanstack/react-query'
import { personalType2 } from '../types/Types'
import {
    addCaseToUser,
    addMessage,
    addUser,
    deleteCase,
    deleteNew,
    deleteRequest,
    deleteSingleDocument,
    deleteUser,
    denyCase,
    postNewDocToCase,
    updateCaseStatus,
    updatePersonalUserData,
    updateUserStatus,
} from './apiCalls'

// add user via website
export const useAddUserMutation = (
    queryClient: QueryClient,
    successFunction: (userid: string) => void,
    errorFunction: (err: any) => void
) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { formData: FormData }>({
        mutationFn: addUser,
        onSuccess: (data: any) => {
            // NOTE -> get userId from here and start upload documents in a second call
            // alert?.alertAnimation('add', 'Angebot')
            queryClient.invalidateQueries({ queryKey: ['userData'] })
            successFunction(data)
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
            errorFunction(err)
        },
    })
}

// delete new by clicking on it
export const useDeleteNewMutation = (queryClient: QueryClient) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, string>({
        mutationFn: deleteNew,
        onSuccess: () => {
            // alert?.alertAnimation('add', 'Angebot')
            queryClient.invalidateQueries({ queryKey: ['newsData'] })
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
        },
    })
}

// change status of a case via portal from anwalt
export const useUpdateCaseStatusMutation = (queryClient: QueryClient) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        { cid: string; uid: string; status: string }
    >({
        mutationFn: updateCaseStatus,
        onSuccess: () => {
            // alert?.alertAnimation('add', 'Angebot')
            queryClient.invalidateQueries({ queryKey: ['userData'] })
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
        },
    })
}

// change status of a user via portal from anwalt
export const useUpdateUserStatusMutation = (queryClient: QueryClient) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { uid: string; status: string }>({
        mutationFn: updateUserStatus,
        onSuccess: () => {
            // alert?.alertAnimation('add', 'Angebot')
            queryClient.invalidateQueries({ queryKey: ['userData'] })
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
        },
    })
}

// delete a case in portal from anwalt
export const useDeleteCaseMutation = (queryClient: QueryClient) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { uid: string; cid: string }>({
        mutationFn: deleteCase,
        onSuccess: () => {
            // alert?.alertAnimation('add', 'Angebot')
            queryClient.invalidateQueries({ queryKey: ['userData'] })
            queryClient.invalidateQueries({ queryKey: ['newCasesData'] })
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
        },
    })
}

// deny a case in portal from anwalt
export const useDenyCaseMutation = (queryClient: QueryClient) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { uid: string; cid: string }>({
        mutationFn: denyCase,
        onSuccess: () => {
            // alert?.alertAnimation('add', 'Angebot')
            queryClient.invalidateQueries({ queryKey: ['userData'] })
            queryClient.invalidateQueries({ queryKey: ['newCasesData'] })
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
        },
    })
}

// delete a user via portal from anwalt
export const useDeleteUserMutation = (queryClient: QueryClient) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { uid: string }>({
        mutationFn: deleteUser,
        onSuccess: () => {
            // alert?.alertAnimation('add', 'Angebot')
            queryClient.invalidateQueries({ queryKey: ['userData'] })
            queryClient.invalidateQueries({ queryKey: ['newCasesData'] })
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
        },
    })
}

// add message to chat
export const useAddMessageMutation = (queryClient: QueryClient) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        {
            uid: string
            cid: string
            message: { sender: string; message: string; timestamp: string }
        }
    >({
        mutationFn: addMessage,
        onSuccess: () => {
            // alert?.alertAnimation('add', 'Angebot')
            // TODO ggf. noch ein boolean der entweder userData oder oneData reloaded
            queryClient.invalidateQueries({ queryKey: ['userData'] })
            queryClient.invalidateQueries({ queryKey: ['oneUser'] })
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
        },
    })
}

// upload document(s) to employee // TODO loading animation as long as there
export const useAddDocToCaseMutation = (
    queryClient: QueryClient,
    modalCon?: any,
    modalConId?: number,
    userUploaded?: boolean
) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { uid: string; cid: string; data: any }>(
        {
            mutationFn: postNewDocToCase,
            onSuccess: () => {
                // alert?.alertAnimation('add', 'Dokument')
                if (userUploaded) {
                    queryClient.invalidateQueries({ queryKey: ['oneUser'] })
                }
                queryClient.invalidateQueries({ queryKey: ['userData'] })
                modalCon?.trigger(modalConId)
            },
            onError: (err) => {
                // alert?.alertAnimation('add', 'Dokument', true)
                console.log(err)
            },
        }
    )
}

// delete a file via portal from anwalt
export const useDeleteSingleFileMutation = (queryClient: QueryClient) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { cid: string; fid: string }>({
        mutationFn: deleteSingleDocument,
        onSuccess: () => {
            // alert?.alertAnimation('add', 'Angebot')
            queryClient.invalidateQueries({ queryKey: ['userData'] })
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
        },
    })
}

// ============================ U S E R ============================ //

// user requests to delete a case/profile
export const useRequestToDeleteMutation = (queryClient: QueryClient) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        {
            topic: 'profile' | 'case'
            _id: string
            personal: {
                first_name: string
                last_name: string
                caseid?: string
            }
        }
    >({
        mutationFn: deleteRequest,
        onSuccess: () => {
            // alert?.alertAnimation('add', 'Angebot')
            queryClient.invalidateQueries({ queryKey: ['newsData'] })
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
        },
    })
}

// user updates personal data
export const useUpdatePersonalDataMutation = (queryClient: QueryClient) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        {
            personal: {
                personal: personalType2
            }
            uid: string
        }
    >({
        mutationFn: updatePersonalUserData,
        onSuccess: () => {
            // alert?.alertAnimation('add', 'Angebot')
            queryClient.invalidateQueries({ queryKey: ['oneUser'] })
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
        },
    })
}

// add case via portal to a user
export const useAddCaseMutation = (queryClient: QueryClient) => {
    // const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { formData: FormData }>({
        mutationFn: addCaseToUser,
        onSuccess: () => {
            // alert?.alertAnimation('add', 'Angebot')
            queryClient.invalidateQueries({ queryKey: ['oneUser'] })
        },
        onError: (err) => {
            // alert?.alertAnimation('add', 'Angebot', true)
            console.log(err)
        },
    })
}
