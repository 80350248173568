import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from '@azure/msal-react'
import BasePanelHeadline from '../BasePanelHeadline'
import { ReactComponent as EdgeArrow } from './../../../res/News Edge Icon.svg'
import { ReactComponent as LogoutIcon } from './../../../res/logout.svg'
import { msTopenScopes } from './MircrosoftAuthWrapper'
import { MicrosoftLoginButton } from 'react-social-login-buttons'
import { CalendarEntries } from './CalendarEntries'
import { Tooltip } from 'react-daisyui'

export default function CallbackList() {
    const { instance } = useMsal()

    const loginToMsal = async () =>
        await instance
            .loginPopup({
                scopes: msTopenScopes,
                redirectUri: window.location.origin,
            })
            .catch((e) => {
                console.error(e)
            })

    const logout = async () =>
        await instance
            .logoutRedirect({
                account: instance.getActiveAccount(),
                onRedirectNavigate: () => {
                    return false
                },
            })
            .catch((e) => {
                console.error(e)
            })

    return (
        <div className="flex flex-col py-4 px-16 rounded-none h-full w-full bg-portal-gray relative">
            <EdgeArrow className="absolute top-0 left-0 h-8 w-8" />
            <BasePanelHeadline title={'Rückrufe'} />
            <UnauthenticatedTemplate>
                <div className="w-72 m-auto">
                    <MicrosoftLoginButton onClick={loginToMsal} align='center'>
                        <span>Anmelden bei Outlook</span>
                    </MicrosoftLoginButton>
                </div>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Tooltip
                    className="absolute top-8 right-12 h-6 w-6"
                    message="Bei Outlook abmelden">
                    <LogoutIcon
                        className="text-secondary hover:cursor-pointer"
                        onClick={logout}
                    />
                </Tooltip>
                <CalendarEntries />
            </AuthenticatedTemplate>
        </div>
    )
}
