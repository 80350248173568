import React, { useRef } from 'react'
import { ImageType } from '../../types/Types'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { API } from '../../services/constants'

type ImageSliderType = {
    content?: {
        data: ImageType[]
    }
}

function AboutSlider({ content }: ImageSliderType) {
    const splideRef = useRef<any>(null)

    const handlePrev = () => {
        if (splideRef.current) {
            splideRef?.current?.splide.go('-1') // Go to the previous slide
        }
    }

    const handleNext = () => {
        if (splideRef?.current) {
            splideRef?.current?.splide.go('+1') // Go to the next slide
        }
    }
    return (
        <div className="max-w-screen-xl px-4 md:px-0 sm:mx-auto flex md:space-x-6 pt-32 pb-16 w-[90%] sm:w-full justify-center items-center">
            <button className="text-4xl text-secondary sm:p-3" aria-label="Previous" onClick={handlePrev}>
                <MdArrowBackIos />
            </button>

            <Splide
                style={{ width: '90%' }}
                options={{
                    type: 'loop',
                    perPage: 3,
                    perMove: 1,
                    navigation: true,
                    arrows: false,
                    gap: '3rem',
                    breakpoints: {
                        640: {
                            // Adjust for your specific breakpoints
                            perPage: 1,
                        },
                        1280: {
                            // Adjust for your specific breakpoints
                            perPage: 2,
                        },
                    },
                }}
                ref={splideRef}
                aria-label="My Favorite Images">
                {content?.data.map((image) => (
                    <SplideSlide key={image.id}>
                        <img src={API.img + image.attributes.url} alt={image.attributes.name} className="w-full h-auto" />
                    </SplideSlide>
                ))}
            </Splide>

            <button className="text-4xl text-secondary sm:p-3" aria-label="Next" onClick={handleNext}>
                <MdArrowForwardIos />
            </button>
        </div>
    )
}

export default AboutSlider
