import React, { useState } from 'react'
import RichTextBody from './RichTextBody'

type AboutSubPageType = {
    content?: {
        id: number
        attributes: {
            hero_headline: string
            hero_subtitle: string
            about_heading: string
            about_text: string
            createdAt: string
            updatedAt: string
            publishedAt: string
        }
    } | null
}

function AboutSubPage({ content }: AboutSubPageType) {
    const [isClamped, setIsClamped] = useState(true)

    if (!content) {
        return null
    }

    return (
        <div className="hidden md:block w-full px-8 max-w-screen-xl pt-8 pb-16 lg:py-20">
            <h2 className="text-3xl lg:text-4xl">{content?.attributes?.about_heading}</h2>
            <hr className="h-px my-3 w-3/4 mt-2 lg:mb-6 lg:w-2/6 bg-black border-0 "></hr>
            <div className="lg:flex lg:space-x-8">
                <div className="w-full max-w-screen-lg">
                    <RichTextBody className={`${isClamped ? 'line-clamp-6' : ''} lg:line-clamp-none`} richText={content.attributes.about_text} />
                </div>
            </div>
            <p className="text-secondary text-lg font-medium lg:hidden cursor-pointer mt-2" onClick={() => setIsClamped(!isClamped)}>
                {isClamped ? '...mehr' : '...weniger'}
            </p>
        </div>
    )
}

export default AboutSubPage
