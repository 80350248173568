import { Link } from 'react-router-dom'
import BasePanelHeadline from '../BasePanelHeadline'
import { caseType2 } from '../../types/Types'
import { ReactComponent as EdgeArrow } from './../../../res/News Edge Icon.svg'
import dayjs from 'dayjs'

type CaseListType = {
    cases: caseType2[]
    selectedId: string
    addCase: (e: boolean) => void
}

export default function CaseList({ cases, selectedId, addCase }: CaseListType) {
    const displayedCases = cases.sort((a, b) => {
        return dayjs(a.created_at).isAfter(dayjs(b.created_at)) ? -1 : 1
    })

    return (
        <div className="w-1/3 max-w-[22rem] h-full bg-portal-gray rounded-none flex flex-col relative items-center py-4">
            <EdgeArrow className="absolute top-0 left-0 h-8 w-8" />
            <BasePanelHeadline title={'Fälle'} classProps="pb-4" />
            <div className="flex flex-col w-full h-full overflow-y-scroll">
                <div className="text-lg text-accent flex items-center justify-start gap-2 px-8 pb-2 cursor-pointer" onClick={() => addCase(true)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>

                    <p className="font-medium">Fall melden</p>
                </div>
                {displayedCases.map((item, index) => (
                    <ListElement key={index} selectedId={selectedId ?? -1} caseData={item} func={() => addCase(false)} />
                ))}
            </div>
        </div>
    )
}

type ListElementType = {
    caseData: caseType2
    selectedId: string
    func: () => void
}

function ListElement({ caseData, selectedId, func }: ListElementType) {
    return (
        <Link to={'/portal/faelle/' + caseData._id} className={`flex flex-col w-full `} onClick={func}>
            <div
                className={`flex flex-row items-center justify-start px-8 py-1 gap-2 hover:bg-primary/10 hover:border-l-4 border-primary ${
                    selectedId === caseData._id && 'bg-primary/10 border-l-4'
                }`}>
                <div
                    className={`h-4 w-4 rounded-full ${
                        caseData.status === 'open' ? 'bg-secondary' : caseData.status === 'done' ? 'bg-gray-400' : 'bg-none border border-gray-400'
                    }`}
                />
                <div className="pl-2 text-lg">{dayjs(caseData.created_at).format('DD.MM.YYYY')}</div>
                <div className="grow" />
            </div>
        </Link>
    )
}
