import ReactMarkdown from 'react-markdown'
import { customComponentsOrangeFont } from '../layout/customComponents'
import gfm from 'remark-gfm'

type TextType = {
    text?: string
}

function Seperator3({ text }: TextType) {
    return (
        <div className="bg-base-200 max-w-sm sm:max-w-md md:max-w-screen-xl px-6 md:px-20 py-4 md:py-10 mx-auto">
            <p className="text-center text-xl sm:text-2xl font-medium max-w-4xl lg:text-4xl lg:font-normal mx-auto">
                <ReactMarkdown
                    components={customComponentsOrangeFont}
                    remarkPlugins={[gfm]}>
                    {text}
                </ReactMarkdown>
            </p>
        </div>
    )
}

export default Seperator3
