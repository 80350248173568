import { useEffect, useState } from 'react'
import { ReactComponent as EdgeArrow } from '../../res/News Edge Icon.svg'
import { ReactComponent as Logo } from '../../res/Logo schwarz.svg'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'

type NewsType = {
    headline: string
    content: string
    date?: string
    logo: string
    id?: string
}

function NewsCollapsed({ headline, content, date, logo, id }: NewsType) {
    const [collapsed, setCollapsed] = useState(true)
    const location = useLocation()
    useEffect(() => {
        const hash = location.hash.replace('#', '')
        if (hash) {
            if (hash.toString() === id?.toString()) {
                setCollapsed(false)
            }
        }
    }, [location, id])
    return (
        <div className="relative border border-black ">
            <div className="absolute -right-0.5 rotate-90 -top-0.5">
                <EdgeArrow className="w-8 h-8" />
            </div>
            <div className="flex flex-col space-y-4 px-4 py-6">
                <div className="text-accent">{dayjs(date).format('DD.MM.YYYY')}</div>
                <h4 className="text-primary text-xl underline-offset-1 underline">{headline}</h4>
                <p className={`${collapsed ? 'line-clamp-6' : ''}`}>{content}</p>
                <div className="flex justify-between items-center">
                    <button onClick={() => setCollapsed(!collapsed)} className=" bg-secondary text-white rounded-xl px-4 py-2 h-fit">
                        {collapsed ? <p>Mehr</p> : <p>Schließen</p>}
                    </button>
                    <div className="flex flex-col justify-center">
                        <img src={logo} alt="Logo" className="h-10 w-32" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsCollapsed
