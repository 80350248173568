import React from 'react'
import RadioButton from '../../general/RadioButton'
import TextArea from '../../general/TextArea'
import { InfoDataType, ScreenType } from '../../../types/Types'
import Checkbox from '../../general/Checkbox'

function Handyverstoss({ infoData, setInfoData, dark }: ScreenType) {
    const setCheckedValue = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenHandyverstoss: {
                ...prevInfoData.screenHandyverstoss,
                [name]: value,
            },
        }))
    }

    const handleTextAreaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { value } = e.target
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenHandyverstoss: {
                ...prevInfoData.screenHandyverstoss,
                note: value,
            },
        }))
    }

    return (
        <div className="pr-2">
            <h5 className="w-full pt-2 text-xl pb-2 font-semibold text-secondary">
                Angaben zum Vorwurf
            </h5>
            <div className="flex flex-col md:grid md:grid-cols-5 gap-6">
                <div className="col-span-3 row-span-2">
                    <p>Fahrzeugtyp*</p>
                </div>
                <div className="col-span-2 grid grid-cols-2 gap-2">
                    <RadioButton
                        label="PKW/Motorrad"
                        name="fahrzeugtyp"
                        value="PKW/Motorrad"
                        checkedValue={
                            infoData.screenHandyverstoss.fahrzeugtyp || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="LKW"
                        name="fahrzeugtyp"
                        value="LKW"
                        checkedValue={
                            infoData.screenHandyverstoss.fahrzeugtyp || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3">
                    <p>Kam es zu einem Unfall?*</p>
                </div>
                <div className="col-span-2 grid grid-cols-2 gap-2">
                    <RadioButton
                        label="Ja"
                        name="unfall"
                        value="Ja"
                        checkedValue={infoData.screenHandyverstoss.unfall || ''}
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Nein"
                        name="unfall"
                        value="Nein"
                        checkedValue={infoData.screenHandyverstoss.unfall || ''}
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>

                <div className="col-span-3">
                    <p>Sind Sie beruflich auf den Führerschein angewiesen?*</p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2">
                    <RadioButton
                        label="Ja"
                        name="fuehrerschein"
                        value="Ja"
                        checkedValue={
                            infoData.screenHandyverstoss.fuehrerschein || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Nein"
                        name="fuehrerschein"
                        value="Nein"
                        checkedValue={
                            infoData.screenHandyverstoss.fuehrerschein || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3"></div>
                <div className="col-span-2">
                    <TextArea
                        placeholder="Liegen Besonderheiten vor ?"
                        handleTextAreaChange={handleTextAreaChange}
                        value={infoData.screenHandyverstoss.note || ''}
                        dark={dark}
                    />
                </div>

                <div className="col-span-3 row-span-3">
                    <p>Welche Dokumente wurden Ihnen bereits zugestellt?</p>
                </div>
                <div className="col-span-2 row-span-3 grid grid-rows-3 gap-2">
                    <Checkbox
                        label="Zeugenfragebogen"
                        name="zeugenfragebogen"
                        value="Zeugenfragebogen"
                        checkedValue={
                            infoData.screenHandyverstoss.zeugenfragebogen || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Anhörungsbogen"
                        name="anhoerungsbogen"
                        value="Anhörungsbogen"
                        checkedValue={
                            infoData.screenHandyverstoss.anhoerungsbogen || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Bußgeldbescheid"
                        name="bussgeldbescheid"
                        value="Bußgeldbescheid"
                        checkedValue={
                            infoData.screenHandyverstoss.bussgeldbescheid || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
            </div>
        </div>
    )
}

export default Handyverstoss
