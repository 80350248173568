import BaseDataCheck from '../../../BaseDataCheck'
import BaseDocsSelection from '../../../BaseDocsSelection'
import BaseTextAreaSpecialties from '../../../BaseTextAreaSpecialties'
import BaseViolationForm from '../../../BaseViolationForm'
import { ParkingViolationType } from './ParkingViolation'

export default function ParkingViolationScreen1({
    note,
    setnote,
    check1,
    check2,
    date,
    setcheck1,
    setcheck2,
    setdate,
    extend,
    setdoc1,
    setdoc2,
    setdoc3,
    documents,
    setdocuments,
}: ParkingViolationType) {
    return (
        <BaseViolationForm>
            <div className="overflow-y-scroll">
                <BaseTextAreaSpecialties
                    title={'Kurze Erläuterung'}
                    value={note}
                    onChange={setnote}
                />
                <BaseDocsSelection
                    documents={documents}
                    setdocuments={setdocuments}
                    setdoc1={setdoc1}
                    setdoc2={setdoc2}
                    setdoc3={setdoc3}
                    extend={extend}
                    inputField
                    date={date}
                    setdate={setdate}
                    setdoc4={function (e: string): void {
                        throw new Error('Function not implemented.')
                    }}
                    setdoc5={function (e: string): void {
                        throw new Error('Function not implemented.')
                    }}
                />
                <BaseDataCheck
                    check1={check1}
                    setcheck1={setcheck1}
                    check2={check2}
                    setcheck2={setcheck2}
                />
            </div>
        </BaseViolationForm>
    )
}
