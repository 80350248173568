import BaseModal from '../../components/layout/BaseModal'

type BaseActionCheckModalType = {
    modalId: number
    title: string
    buttonTitle: string
    text: string
    function: () => void
}

export default function BaseActionCheckModal(props: BaseActionCheckModalType) {
    return (
        <BaseModal
            hiddenCross
            size
            big={false}
            modalId={props.modalId}
            bottomRow={
                <div className="flex justify-end w-full">
                    <button
                        className="btn btn-sm btn-secondary"
                        onClick={props.function}>
                        {props.buttonTitle}
                    </button>
                </div>
            }>
            <div className="w-80 border-b pb-2 font-semibold text-lg uppercase">
                {props.title}
            </div>
            <p className="w-80 py-2 tracking-wide text-sm">{props.text}</p>
        </BaseModal>
    )
}
