import React, { ChangeEvent } from 'react'

type Option = {
    label: string
    value: string
}

type CustomSelectProps = {
    placeholder: string
    options: Option[]
    handleSelectChange: (value: string) => void
    selectedValue: string
    dark?: boolean
}

function Select({
    placeholder,
    options,
    handleSelectChange,
    selectedValue,
    dark,
}: CustomSelectProps) {
    const placeholderStyle = `${
        dark ? 'text-white/70' : 'text-black/70'
    } opacity-50`
    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        const value = e.target.value
        handleSelectChange(value)
    }

    return (
        <div className="flex">
            <div
                className={`relative mb-2 flex items-center after:w-[8px] after:h-[8px] ${
                    dark ? 'after:border-white/70' : 'after:border-black/70'
                }  after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-3`}>
                <select
                    required
                    value={selectedValue}
                    className={`${
                        dark
                            ? 'bg-primary border-white/70 text-white'
                            : 'bg-white border-gray-800 text-black'
                    } px-3 py-2 transition-all cursor-pointer border rounded-lg appearance-none`}
                    onChange={handleChange}>
                    <option value="" disabled className={placeholderStyle}>
                        {placeholder}
                    </option>
                    {options.map(({ label, value }) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export default Select
