import RadioButton from '../../general/RadioButton'
import Select from '../../general/Select'
import { InfoDataType, ScreenType } from '../../../types/Types'
import Checkbox from '../../general/Checkbox'

function AlkoholVerstoss({ infoData, setInfoData, dark }: ScreenType) {
    const setCheckedValue = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenAlkoholverstoss: {
                ...prevInfoData.screenAlkoholverstoss,
                [name]: value,
            },
        }))
    }

    const handleSelectChange = (selectedValue: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenAlkoholverstoss: {
                ...prevInfoData.screenAlkoholverstoss,
                wert: selectedValue,
            },
        }))
    }

    const selectOptions = [
        { label: '0,25 - 0,4', value: '0,25 bis 0,4' },
        { label: '0,4 - 0,55', value: '0,4 bis 0,55' },
        { label: '>0,55', value: 'mehr als 0,55' },
    ]
    return (
        <div className="pr-2">
            <h5 className="w-full pt-2 text-xl pb-2 font-semibold text-secondary">
                Angaben zum Vorwurf
            </h5>

            <div className="flex flex-col md:grid md:grid-cols-5 gap-6">
                <div className="col-span-3">
                    <p>Gemessener Wert*</p>
                </div>
                <div className="col-span-2 grid grid-cols-2 gap-2">
                    <RadioButton
                        label="Atemalkohol"
                        name="artTest"
                        value="Atemalkohol"
                        checkedValue={
                            infoData.screenAlkoholverstoss.artTest || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Blutalkohol"
                        name="artTest"
                        value="Blutalkohol"
                        checkedValue={
                            infoData.screenAlkoholverstoss.artTest || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3">
                    <p>Wertangabe*</p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2">
                    <Select
                        placeholder="Bitte Auswählen ..."
                        options={selectOptions}
                        handleSelectChange={handleSelectChange}
                        selectedValue={
                            infoData.screenAlkoholverstoss.wert || ''
                        }
                        dark={dark}
                    />
                </div>
                <div className="col-span-3">
                    <p>Kam es zu einem Unfall?*</p>
                </div>
                <div className="col-span-2 grid grid-cols-2 gap-2">
                    <RadioButton
                        label="Ja"
                        name="unfall"
                        value="Ja"
                        checkedValue={
                            infoData.screenAlkoholverstoss.unfall || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Nein"
                        name="unfall"
                        value="Nein"
                        checkedValue={
                            infoData.screenAlkoholverstoss.unfall || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3">
                    <p>Welche Dokumente wurden Ihnen bereits zugestellt?</p>
                </div>
                <div className="col-span-2 grid grid-cols-1 gap-2">
                    <Checkbox
                        label="Zeugenfragebogen"
                        name="zeugenfragebogen"
                        value="Zeugenfragebogen"
                        checkedValue={
                            infoData.screenAlkoholverstoss.zeugenfragebogen ||
                            ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Anhörungsbogen"
                        name="anhoerungsbogen"
                        value="Anhörungsbogen"
                        checkedValue={
                            infoData.screenAlkoholverstoss.anhoerungsbogen || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Bußgeldbescheid"
                        name="bussgeldbescheid"
                        value="bussgeldbescheid"
                        checkedValue={
                            infoData.screenAlkoholverstoss.bussgeldbescheid ||
                            ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Strafbefehl"
                        name="strafbefehl"
                        value="Strafbefehl"
                        checkedValue={
                            infoData.screenAlkoholverstoss.strafbefehl || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Anklageschrift"
                        name="anklageschrift"
                        value="Anklageschrift"
                        checkedValue={
                            infoData.screenAlkoholverstoss.anklageschrift || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3">
                    <p>Wurde der Führerschein beschlagnahmt?*</p>
                </div>
                <div className="col-span-2 grid grid-cols-2 gap-2">
                    <RadioButton
                        label="Ja"
                        name="fuehrerschein_beschlagnahmt"
                        value="Ja"
                        checkedValue={
                            infoData.screenAlkoholverstoss
                                .fuehrerschein_beschlagnahmt || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Nein"
                        name="fuehrerschein_beschlagnahmt"
                        value="Nein"
                        checkedValue={
                            infoData.screenAlkoholverstoss
                                .fuehrerschein_beschlagnahmt || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3">
                    <p>
                        Beschluss über vorläufige Entziehung der Fahrerlaubnis?*
                    </p>
                </div>
                <div className="col-span-2 grid grid-cols-2 gap-2">
                    <RadioButton
                        label="Ja"
                        name="fahrerlaubnis"
                        value="Ja"
                        checkedValue={
                            infoData.screenAlkoholverstoss.fahrerlaubnis || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Nein"
                        name="fahrerlaubnis"
                        value="Nein"
                        checkedValue={
                            infoData.screenAlkoholverstoss.fahrerlaubnis || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
            </div>
        </div>
    )
}

export default AlkoholVerstoss
