import Sidebar from '../components/Sidebar'
import UserScreen1 from './user/UserScreen1'
import AdminScreen1 from './admin/AdminScreen1'
import AdminScreen2 from './admin/AdminScreen2'
import AdminScreen3 from './admin/AdminScreen3'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import BasePortalHeader from '../components/BasePortalHeader'
import UserProfile from './user/UserProfile'
import { MicrosoftAuthWrapper } from '../components/admin/MircrosoftAuthWrapper'
import {
    useAnwaltSecuredElement,
    useMandantenSecuredElement,
    NavigateBasedOnRole,
    PortalRoutes,
} from '../security/RoutingHelper'

export default function Portal() {
    return (
        <section className="grow w-full flex flex-col justify-center items-center relative">
            <BasePortalHeader />
            <div className="lg:hidden flex flex-col px-8 justify-center items-center w-full h-full">
                <p className="text-2xl underline font-bold uppercase">
                    Entschuldigung!
                </p>
                <p className="text-lg tracking-wide font-medium text-center leading-5 pt-2 max-w-sm">
                    Die Plattform ist aus Usability-Gründen auf Ihrer
                    Bildschirmgröße nicht erreichbar.
                </p>
            </div>
            <div className="h-screen min-h-[60rem] w-full max-w-screen-2xl relative hidden lg:flex gap-2 pt-32 pb-8 px-4">
                <Sidebar />
                {/* WICHTIG für die nested Routes */}
                <Outlet />

                <MicrosoftAuthWrapper>
                    <Routes>
                        {/* Entry Point */}
                        <Route
                            path={PortalRoutes.common.home}
                            element={<NavigateBasedOnRole />}
                        />
                        {/* ADMIN VIEW */}

                        <Route
                            path={PortalRoutes.anwalt.dashboard}
                            element={useAnwaltSecuredElement({
                                target: <AdminScreen1 />,
                            })}
                        />

                        <Route
                            path={PortalRoutes.anwalt.anfrage_by_id}
                            element={useAnwaltSecuredElement({
                                target: <AdminScreen2 />,
                            })}
                        />
                        <Route
                            path={PortalRoutes.anwalt.anfragen}
                            element={useAnwaltSecuredElement({
                                target: <AdminScreen2 />,
                            })}
                        />
                        <Route
                            path={PortalRoutes.anwalt.mandanten_by_id}
                            element={useAnwaltSecuredElement({
                                target: <AdminScreen3 />,
                            })}
                        />
                        <Route
                            path={PortalRoutes.anwalt.mandanten}
                            element={<Navigate to="1" />}
                        />
                        {/* USER VIEW */}
                        <Route
                            path={PortalRoutes.mandant.faelle_by_id}
                            element={useMandantenSecuredElement({
                                target: <UserScreen1 />,
                            })}
                        />
                        <Route
                            path={PortalRoutes.mandant.faelle}
                            element={<Navigate to="1" />}
                        />
                        <Route
                            path={PortalRoutes.mandant.profil}
                            element={useMandantenSecuredElement({
                                target: <UserProfile />,
                            })}
                        />
                    </Routes>
                </MicrosoftAuthWrapper>
            </div>
        </section>
    )
}
