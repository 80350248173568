import '../../style/loading.css'

type LoadingUploadType = {
    hfull?: boolean
    dark?: boolean
}

export default function LoadingUpload(props: LoadingUploadType) {
    return (
        <div
            className={`${
                props.hfull ? 'h-[80%]' : 'h-screen top-24'
            } w-full absolute left-0  flex flex-col items-center justify-center ${
                props.dark
                    ? 'bg-none text-white'
                    : 'bg-base-100/70 text-primary'
            }`}>
            <div className="dot-spinner">
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
            </div>
            <p className="uppercase font-semibold underline pt-2 px-2 text-center">
                Fenster nicht schließen, solange der Upload läuft
            </p>
        </div>
    )
}
