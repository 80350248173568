import { useContext, useEffect, useState } from 'react'
import { SliderType, VerstossType } from '../../types/Types'
import axios from 'axios'
import { API } from '../../services/constants'
import { ReactComponent as VerstossMelden } from '../../res/verstoss_melden.svg'
import { ModalContext } from '../contexts/ModalContext'
import Modal from '../modal/Modal'

type SidePanelType = {
    condition: boolean
    handleOpen: (parameter: boolean) => void
}

export default function SidePanel({ condition, handleOpen }: SidePanelType) {
    const modalCon = useContext(ModalContext)
    const [currentFirstScreenType, setCurrentFirstScreenType] = useState<VerstossType | null>(null)

    const handleViolationClick = (violation: VerstossType) => {
        setCurrentFirstScreenType(violation)
        modalCon?.trigger(1)
    }

    const handleViolationClickDirect = (violation: VerstossType) => {
        setCurrentFirstScreenType(violation)
        modalCon?.trigger(2)
    }

    const [slider, setSlider] = useState<SliderType>()

    useEffect(() => {
        axios
            .get(API.general + '/verstoss-slider?populate[slider_content][populate][icon]=*')
            .then((res) => {
                if (res?.data?.data) {
                    setSlider(res.data.data)
                }
            })
            .catch((err) => console.log(err))
    }, [])

    return (
        <>
            <Modal
                modalId={1}
                closeModal={() => modalCon?.trigger(1)}
                firstScreenType={currentFirstScreenType || 'sonstigedelikte'}
                screen
                setCurrentFirstScreenType={setCurrentFirstScreenType}
            />
            <Modal modalId={2} closeModal={() => modalCon?.trigger(2)} firstScreenType={currentFirstScreenType || 'sonstigedelikte'} />
            <div
                className={`hidden md:flex-col md:flex h-fit w-fit max-w-lg fixed p-8 z-[9998] bg-base-200 right-0 top-24 rounded-l-2xl transition-transform ease-in-out duration-500 ${
                    condition ? 'translate-x-0' : 'translate-x-full'
                }`}>
                <div className="relative">
                    <button
                        onClick={() => handleOpen(!condition)}
                        className="w-20 h-48 rounded-2xl bg-base-200 absolute left-[-6rem] top-8 flex items-center justify-center ">
                        <VerstossMelden className="scale-[2.8] -rotate-90 mr-6" />
                    </button>
                </div>
                <div className="grid grid-cols-3 gap-8">
                    {slider?.attributes?.slider_content?.map((icons, index) => (
                        <div className="w-full flex items-center justify-center" key={index}>
                            <button
                                onClick={() => {
                                    handleViolationClickDirect(icons.violation_name as VerstossType)
                                }}>
                                <div className="flex flex-col items-center z-20">
                                    <img
                                        className="w-[50px] h-[50px]"
                                        src={API.img + icons.icon.data.attributes.url}
                                        alt={icons.icon.data.attributes.name}
                                    />
                                    <p className="font-medium text-center text-black z-50 mt-2">{icons.icon.data.attributes.caption}</p>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div className="fixed h-auto bottom-0 w-full flex justify-center mx-auto py-2 md:hidden z-50">
                <div className="absolute h-full w-full bg-secondary opacity-90 top-0 left-0" />
                <button
                    onClick={() => handleViolationClick('overview')}
                    className="w-48 h-14 rounded-2xl bg-base-200 flex items-center justify-center z-50">
                    <VerstossMelden className="scale-[1.8]" />
                </button>
                {/* <button
                    className="btn items-center px-6 border border-black rounded-md text-secondary z-10 font-semibold text-1xl h-full"
                    onClick={() => handleViolationClick('overview')}>
                    Verstoß melden!
                </button> */}
            </div>
        </>
    )
}
