import Hero from '../../components/general/Hero'
import RichText from '../../components/general/RichText'
import { getDatenschutzData } from '../../hooks/cmsApiCalls'
import { useQuery } from '@tanstack/react-query'
import ErrorPage from '../404/ErrorPage'
import Loading from '../../components/common/Loading'
import BaseSeo from '../../components/layout/BaseSeo'

export default function Datenschutz() {
    const { error, data, isLoading } = useQuery({
        queryKey: ['datenschutzData'],
        queryFn: getDatenschutzData,
    })

    return (
        <>
            {error && <ErrorPage />}
            {isLoading && <Loading />}
            {data && (
                <>
                    <BaseSeo seo={data.attributes?.seo} />
                    <Hero
                        heading={String(data.attributes.hero_headline)}
                        subtitle={String(data.attributes.hero_subtitle)}
                        heroImg={String(data.attributes.hero_img.data.attributes.url)}
                    />
                    <RichText optionBoolean rText={data.attributes.richText} />
                </>
            )}
        </>
    )
}
