import { useContext } from "react";
import { ReactComponent as Logo } from "../../res/Logo weiß.svg"
import { API } from "../../services/constants"
import Modal from "../modal/Modal";
import { ModalContext } from "../contexts/ModalContext";
import { VerstossType } from "../../types/Types";

type PopUpStartType = {
  imageUrl: string;
  verstoss?: VerstossType;
}

function PopUpStart({imageUrl, verstoss}: PopUpStartType) {

  const modalCon = useContext(ModalContext)
  const handleViolationClick = () => {
    modalCon?.trigger(20);
  }

  return (
    <>
      <Modal modalId={20} closeModal={() => modalCon?.trigger(20)} firstScreenType={verstoss || "overview"} />
      <div className="flex w-full mb-36 ">
        <div className="relative w-full">
          <img
            src={API.img + imageUrl}
            alt="PopupImage"
            className="w-full object-cover h-[500px]"
          />
          <div className="absolute bg-primary py-8 px-6 space-y-2 right-12 md:right-24 -bottom-24">
            <div className="w-64">
              <Logo />
            </div>
            <button onClick={() => {
                handleViolationClick()}} className="bg-secondary text-white rounded-2xl h-12 px-6 text-lg active:scale-pressed duration-100 ease-in-out">
              Verstoß melden!
            </button>
          </div>

        </div>
      </div>
    </>
  )
}

export default PopUpStart