import { HashLink } from 'react-router-hash-link'
import { InfoDataType, ScreenType } from '../../../types/Types'
import Checkbox from '../../general/Checkbox'
import RadioButton from '../../general/RadioButton'
import { MdInfoOutline } from 'react-icons/md'

function ScreenParkAbschleppmassnahmen({
    infoData,
    setInfoData,
    dark,
}: ScreenType) {
    const setCheckedValue = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenParkAbschleppmassnahmen: {
                ...prevInfoData.screenParkAbschleppmassnahmen,
                [name]: value,
            },
        }))
    }

    const handlePrivacyAndDeadlineChange = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenPersonendaten: {
                ...prevInfoData.screenPersonendaten,
                [name]: value,
            },
        }))
    }

    const handleTextAreaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { value } = e.target
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenParkAbschleppmassnahmen: {
                ...prevInfoData.screenParkAbschleppmassnahmen,
                note: value,
            },
        }))
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenParkAbschleppmassnahmen: {
                ...prevInfoData.screenParkAbschleppmassnahmen,
                [name]: value,
            },
        }))
    }

    return (
        <div className="pr-2 overflow-y-scroll relative">
            <h5 className="w-full pt-2 text-xl pb-2 font-semibold">
                Angaben zum Verstoß
            </h5>
            <div className="w-full h-fit pb-4">
                <p className="pb-2">Was wird Ihnen vorgeworfen?*</p>
                <textarea
                    placeholder="schreiben..."
                    value={infoData.screenParkAbschleppmassnahmen.note || ''}
                    name="note"
                    rows={12}
                    className={`${
                        dark ? 'bg-primary border-white/70' : ''
                    } p-2 w-full h-56 rounded-md border focus:border-none`}
                    onChange={handleTextAreaChange}></textarea>
            </div>
            <div className="flex flex-col md:grid md:grid-cols-5 gap-0 md:gap-6">
                <div className="col-span-3 row-span-3 pb-2 md:pb-0">
                    <p>Welche Dokumente wurden Ihnen bereits zugestellt?</p>
                </div>
                <div className="col-span-2 row-span-3 grid grid-rows-3 gap-2 pb-4 md:pb-0">
                    <Checkbox
                        label="Zeugenfragebogen"
                        name="zeugenfragebogen"
                        value="Zeugenfragebogen"
                        checkedValue={
                            infoData.screenParkAbschleppmassnahmen
                                .zeugenfragebogen || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Anhörungsbogen"
                        name="anhoerungsbogen"
                        value="Anhörungsbogen"
                        checkedValue={
                            infoData.screenParkAbschleppmassnahmen
                                .anhoerungsbogen || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Bußgeldbescheid"
                        name="bussgeldbescheid"
                        value="Bußgeldbescheid"
                        checkedValue={
                            infoData.screenParkAbschleppmassnahmen
                                .bussgeldbescheid || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Wann wurden diese Dokumente zugestellt?</p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2">
                    <input
                        type="date"
                        name="documentDate"
                        onChange={handleInputChange}
                        placeholder="Eingabe Datum*"
                        value={
                            infoData.screenParkAbschleppmassnahmen
                                .documentDate || ''
                        }
                        required
                        className={`border ${
                            dark
                                ? 'bg-primary border-white/70 text-white'
                                : 'border-black'
                        }  rounded-lg px-4 py-2 md:px-2 md:py-1 w-full`}
                    />
                </div>
            </div>
            <div className="mt-16 space-y-4">
                <div>
                    <RadioButton
                        label={
                            <span>
                                Sie haben unsere{' '}
                                <HashLink
                                    to="/datenschutz"
                                    className={`underline ${
                                        dark
                                            ? 'text-gray-400 visited:text-white'
                                            : 'text-gray-700 visited:text-gray-400'
                                    }" target="_blank" rel="noopener noreferrer`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Datenschutzbestimmungen
                                </HashLink>{' '}
                                gelesen *
                            </span>
                        }
                        name="datenschutz"
                        value="datenschutz"
                        checkedValue={
                            infoData.screenPersonendaten.datenschutz || ''
                        }
                        setCheckedValue={handlePrivacyAndDeadlineChange}
                        dark={dark}
                    />
                </div>
                <div>
                    <RadioButton
                        label={
                            <span>
                                Sie haben die Informationen zu unserer{' '}
                                <HashLink
                                    to="/bearbeitungsfrist"
                                    className={`underline ${
                                        dark
                                            ? 'text-gray-400 visited:text-white'
                                            : 'text-gray-700 visited:text-gray-400'
                                    }" target="_blank" rel="noopener noreferrer`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Bearbeitungsfrist
                                </HashLink>{' '}
                                gelesen *
                            </span>
                        }
                        name="bearbeitungsfrist"
                        value="bearbeitungsfrist"
                        checkedValue={
                            infoData.screenPersonendaten.bearbeitungsfrist || ''
                        }
                        setCheckedValue={handlePrivacyAndDeadlineChange}
                        dark={dark}
                    />
                </div>

                <div className="flex items-center">
                    <div className="px-3 scale-125">
                        <MdInfoOutline />
                    </div>
                    <div>*Pflichtangaben</div>
                </div>
            </div>
        </div>
    )
}

export default ScreenParkAbschleppmassnahmen
