import dayjs from 'dayjs'
import { newCaseType } from '../../types/Types'
import { operateWithCallOption } from '../../services/functions'
import { useNavigate } from 'react-router'
import PlanMeetingModal from './PlanMeetingModal'
import { useContext } from 'react'
import { ModalContext } from '../../../components/contexts/ModalContext'

type requestProfileType = {
    request?: newCaseType
    deleteFunc: () => void
}

export default function RequestProfile({
    request,
    deleteFunc,
}: requestProfileType) {
    const modalCon = useContext(ModalContext)
    const navigate = useNavigate()
    return (
        <>
            {request && (
                <div className="h-full w-3/5 rounded-none bg-portal-gray flex flex-col p-16">
                    <h3 className="text-lg font-semibold">{request?.title}</h3>
                    <p className="pt-2 text-sm">
                        {dayjs(request?.updated_at).format('DD.MM.YYYY')},{' '}
                        {request?.first_name} {request?.last_name}
                    </p>
                    <p className="text-sm">
                        Frist:{' '}
                        {dayjs(request?.deadline).isValid()
                            ? dayjs(request?.deadline).format('DD.MM.YYYY')
                            : 'keine Angabe'}
                    </p>
                    <p className="text-sm pt-4">
                        {request?.first_name} {request?.last_name}
                    </p>
                    <p className="text-sm">
                        {request?.adress?.street} {request?.adress?.nr}
                    </p>
                    <p className="text-sm">
                        {request?.adress?.plz} {request?.adress?.city}
                    </p>
                    <p className="pt-4 leading-5 font-semibold">
                        Telefon: {request?.tel}
                    </p>
                    <p className="text-sm">
                        Rückruf: {operateWithCallOption(request.callOption)}
                    </p>
                    {request.info?.map((item, index) => (
                        <p
                            key={index}
                            className={`text-sm ${index === 0 && 'pt-4'}`}>
                            {item}
                        </p>
                    ))}
                    {request.note && (
                        <p className="pt-4 text-sm">
                            Bemerkung: {request.note}
                        </p>
                    )}
                    {!request.documents ? (
                        <p className="pt-4 text-sm">
                            Keine Dokumente hochgeladen
                        </p>
                    ) : (
                        request.documents?.map((item, index) => (
                            <p className={`text-sm ${index === 0 && 'pt-4'}`}>
                                {item.title} hochgeladen
                            </p>
                        ))
                    )}
                    <div className="grow" />
                    <button
                        className="btn btn-primary w-full relative"
                        onClick={() => modalCon?.trigger(1)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className=" absolute left-4 w-6 h-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                            />
                        </svg>
                        Telefonat im Kalender planen
                    </button>
                    <div className="flex gap-2 w-full pt-2">
                        <button
                            className="btn btn-accent text-base-100 w-3/5"
                            onClick={() =>
                                navigate('/portal/mandanten/' + request.userId)
                            }>
                            zum Mandanten
                        </button>
                        <button
                            onClick={deleteFunc}
                            className="btn bg-gray-300 text-base-100 grow">
                            Anfrage löschen
                        </button>
                    </div>
                </div>
            )}
            <PlanMeetingModal modalId={1} />
        </>
    )
}
