import RadioButton from '../../general/RadioButton'
import { MdInfoOutline } from 'react-icons/md'
import Select from '../../general/Select'
import { HashLink } from 'react-router-hash-link'
import { InfoDataType, ScreenType } from '../../../types/Types'
import Checkbox from '../../general/Checkbox'

function ScreenAlkoholverstoss({ infoData, setInfoData, dark }: ScreenType) {
    const setCheckedValue = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenAlkoholverstoss: {
                ...prevInfoData.screenAlkoholverstoss,
                [name]: value,
            },
        }))
    }

    const handlePrivacyAndDeadlineChange = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenPersonendaten: {
                ...prevInfoData.screenPersonendaten,
                [name]: value,
            },
        }))
    }

    const handleSelectChange = (selectedValue: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenAlkoholverstoss: {
                ...prevInfoData.screenAlkoholverstoss,
                wert: selectedValue,
            },
        }))
    }

    const selectOptions = [
        { label: '0,25 - 0,4', value: '0,25 bis 0,4' },
        { label: '0,4 - 0,55', value: '0,4 bis 0,55' },
        { label: '>0,55', value: 'mehr als 0,55' },
    ]

    return (
        <div className="pr-2 overflow-y-scroll relative">
            <h5 className="w-full pt-2 text-xl pb-2 font-semibold">
                Angaben zum Verstoß
            </h5>

            <div className="flex flex-col md:grid md:grid-cols-5 gap-0 md:gap-6">
                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Gemessener Wert*</p>
                </div>
                <div className="col-span-2 grid grid-cols-2 gap-2 pb-4 md:pb-0">
                    <RadioButton
                        label="Atemalkohol"
                        name="artTest"
                        value="Atemalkohol"
                        checkedValue={
                            infoData.screenAlkoholverstoss.artTest || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Blutalkohol"
                        name="artTest"
                        value="Blutalkohol"
                        checkedValue={
                            infoData.screenAlkoholverstoss.artTest || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Wertangabe*</p>
                </div>
                <div className="grid grid-cols-2 col-span-2 gap-2 pb-4 md:pb-0">
                    <Select
                        placeholder="Bitte Auswählen ..."
                        options={selectOptions}
                        handleSelectChange={handleSelectChange}
                        selectedValue={
                            infoData.screenAlkoholverstoss.wert || ''
                        }
                        dark={dark}
                    />
                </div>
                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Kam es zu einem Unfall?*</p>
                </div>
                <div className="col-span-2 grid grid-cols-2 gap-2 pb-4 md:pb-0">
                    <RadioButton
                        label="Ja"
                        name="unfall"
                        value="Ja"
                        checkedValue={
                            infoData.screenAlkoholverstoss.unfall || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Nein"
                        name="unfall"
                        value="Nein"
                        checkedValue={
                            infoData.screenAlkoholverstoss.unfall || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Welche Dokumente wurden Ihnen bereits zugestellt?</p>
                </div>
                <div className="col-span-2 grid grid-cols-1 gap-2 pb-4 md:pb-0">
                    <Checkbox
                        label="Zeugenfragebogen"
                        name="zeugenfragebogen"
                        value="Zeugenfragebogen"
                        checkedValue={
                            infoData.screenAlkoholverstoss.zeugenfragebogen ||
                            ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Anhörungsbogen"
                        name="anhoerungsbogen"
                        value="Anhörungsbogen"
                        checkedValue={
                            infoData.screenAlkoholverstoss.anhoerungsbogen || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Bußgeldbescheid"
                        name="bussgeldbescheid"
                        value="Bußgeldbescheid"
                        checkedValue={
                            infoData.screenAlkoholverstoss.bussgeldbescheid ||
                            ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Strafbefehl"
                        name="strafbefehl"
                        value="Strafbefehl"
                        checkedValue={
                            infoData.screenAlkoholverstoss.strafbefehl || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <Checkbox
                        label="Anklageschrift"
                        name="anklageschrift"
                        value="Anklageschrift"
                        checkedValue={
                            infoData.screenAlkoholverstoss.anklageschrift || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3 pb-2 md:pb-0">
                    <p>Wurde der Führerschein beschlagnahmt?*</p>
                </div>
                <div className="col-span-2 grid grid-cols-2 gap-2 pb-4 md:pb-0">
                    <RadioButton
                        label="Ja"
                        name="fuehrerschein_beschlagnahmt"
                        value="Ja"
                        checkedValue={
                            infoData.screenAlkoholverstoss
                                .fuehrerschein_beschlagnahmt || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Nein"
                        name="fuehrerschein_beschlagnahmt"
                        value="Nein"
                        checkedValue={
                            infoData.screenAlkoholverstoss
                                .fuehrerschein_beschlagnahmt || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
                <div className="col-span-3 pb-2 md:pb-0">
                    <p>
                        Beschluss über vorläufige Entziehung der Fahrerlaubnis?*
                    </p>
                </div>
                <div className="col-span-2 grid grid-cols-2 gap-2">
                    <RadioButton
                        label="Ja"
                        name="fahrerlaubnis"
                        value="Ja"
                        checkedValue={
                            infoData.screenAlkoholverstoss.fahrerlaubnis || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                    <RadioButton
                        label="Nein"
                        name="fahrerlaubnis"
                        value="Nein"
                        checkedValue={
                            infoData.screenAlkoholverstoss.fahrerlaubnis || ''
                        }
                        setCheckedValue={setCheckedValue}
                        dark={dark}
                    />
                </div>
            </div>
            <div className="mt-16 space-y-4 flex flex-col">
                <div>
                    <RadioButton
                        label={
                            <span>
                                Sie haben unsere{' '}
                                <HashLink
                                    to="/datenschutz"
                                    className={`underline ${
                                        dark
                                            ? 'text-gray-400 visited:text-white'
                                            : 'text-gray-700 visited:text-gray-400'
                                    }" target="_blank" rel="noopener noreferrer`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Datenschutzbestimmungen
                                </HashLink>{' '}
                                gelesen *
                            </span>
                        }
                        name="datenschutz"
                        value="datenschutz"
                        checkedValue={
                            infoData.screenPersonendaten.datenschutz || ''
                        }
                        setCheckedValue={handlePrivacyAndDeadlineChange}
                        dark={dark}
                    />
                </div>
                <div>
                    <RadioButton
                        label={
                            <span>
                                Sie haben die Informationen zu unserer{' '}
                                <HashLink
                                    to="/bearbeitungsfrist"
                                    className={`underline ${
                                        dark
                                            ? 'text-gray-400 visited:text-white'
                                            : 'text-gray-700 visited:text-gray-400'
                                    }" target="_blank" rel="noopener noreferrer`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Bearbeitungsfrist
                                </HashLink>{' '}
                                gelesen *
                            </span>
                        }
                        name="bearbeitungsfrist"
                        value="bearbeitungsfrist"
                        checkedValue={
                            infoData.screenPersonendaten.bearbeitungsfrist || ''
                        }
                        setCheckedValue={handlePrivacyAndDeadlineChange}
                        dark={dark}
                    />
                </div>

                <div className="flex items-center">
                    <div className="px-3 scale-125">
                        <MdInfoOutline />
                    </div>
                    <div>*Pflichtangaben</div>
                </div>
            </div>
        </div>
    )
}

export default ScreenAlkoholverstoss
