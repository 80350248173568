import RadioButton from '../../general/RadioButton'
import { HashLink } from 'react-router-hash-link'
import { MdInfoOutline } from 'react-icons/md'
import { InfoDataType, ScreenType } from '../../../types/Types'

function ScreenSonstigeDelikte({ infoData, setInfoData, dark }: ScreenType) {
    const handleTextAreaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenSonstigeDelikte: {
                ...prevInfoData.screenSonstigeDelikte,
                [name]: value,
            },
        }))
    }

    const handlePrivacyAndDeadlineChange = (name: string, value: string) => {
        setInfoData((prevInfoData: InfoDataType) => ({
            ...prevInfoData,
            screenPersonendaten: {
                ...prevInfoData.screenPersonendaten,
                [name]: value,
            },
        }))
    }

    return (
        <div className="pr-2 overflow-y-scroll relative">
            <h5 className="w-full pt-2 text-xl pb-4 font-semibold">
                Angaben zum Vorwurf
            </h5>
            <div className="w-full h-full">
                <p className="pb-2">Was wird Ihnen vorgeworfen?*</p>
                <textarea
                    placeholder="schreiben..."
                    value={infoData.screenSonstigeDelikte.note || ''}
                    name="note"
                    rows={12}
                    className={`${
                        dark ? 'bg-primary border-white/70' : ''
                    } p-2 w-full rounded-md border focus:border-none`}
                    onChange={handleTextAreaChange}></textarea>
            </div>
            <div className="mt-16 space-y-4">
                <div>
                    <RadioButton
                        label={
                            <span>
                                Sie haben unsere{' '}
                                <HashLink
                                    to="/datenschutz"
                                    className={`underline ${
                                        dark
                                            ? 'text-gray-400 visited:text-white'
                                            : 'text-gray-700 visited:text-gray-400'
                                    }" target="_blank" rel="noopener noreferrer`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Datenschutzbestimmungen
                                </HashLink>{' '}
                                gelesen *
                            </span>
                        }
                        name="datenschutz"
                        value="datenschutz"
                        checkedValue={
                            infoData.screenPersonendaten.datenschutz || ''
                        }
                        setCheckedValue={handlePrivacyAndDeadlineChange}
                        dark={dark}
                    />
                </div>
                <div>
                    <RadioButton
                        label={
                            <span>
                                Sie haben die Informationen zu unserer{' '}
                                <HashLink
                                    to="/bearbeitungsfrist"
                                    className={`underline ${
                                        dark
                                            ? 'text-gray-400 visited:text-white'
                                            : 'text-gray-700 visited:text-gray-400'
                                    }" target="_blank" rel="noopener noreferrer`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Bearbeitungsfrist
                                </HashLink>{' '}
                                gelesen *
                            </span>
                        }
                        name="bearbeitungsfrist"
                        value="bearbeitungsfrist"
                        checkedValue={
                            infoData.screenPersonendaten.bearbeitungsfrist || ''
                        }
                        setCheckedValue={handlePrivacyAndDeadlineChange}
                        dark={dark}
                    />
                </div>

                <div className="flex items-center">
                    <div className="px-3 scale-125">
                        <MdInfoOutline />
                    </div>
                    <div>*Pflichtangaben</div>
                </div>
            </div>
        </div>
    )
}

export default ScreenSonstigeDelikte
