import { useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { withOptions } from 'tailwindcss/plugin'

const ScrollToHashElement = () => {
    let location = useLocation()
    const removeHashCharacter = (str: string) => {
        const result = str.slice(1)
        return result
    }

    let hashElement = useMemo(async () => {
        let hash = location.hash

        if (hash === '#_') {
            await new Promise((resolve) => setTimeout(resolve, 100))
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })

            window.history.replaceState(null, document.title, location.pathname + location.search)
        }

        if (hash !== '' && hash !== undefined && hash !== '_') {
            let element = document.getElementById(removeHashCharacter(hash))

            while (element === null) {
                await new Promise((resolve) => setTimeout(resolve, 1000))
                element = document.getElementById(removeHashCharacter(hash))
            }

            return element
        } else {
            return null
        }
    }, [location.pathname, location.hash])

    useEffect(() => {
        ;(async () => {
            if (hashElement) {
                let html = await hashElement
                if (!html) return
                html.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                })
            }
        })()
    }, [hashElement])

    return null
}

export default ScrollToHashElement
