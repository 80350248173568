type TextAreaType = {
    placeholder: string;
    handleTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    value: string;
    dark?: boolean;
}

function TextArea({placeholder, handleTextAreaChange, value, dark}:TextAreaType) {
  return (
    <textarea 
    id="message" rows={4} 
    className={`block p-2.5 w-full text-sm ${dark? 'text-white bg-primary border-white/70':'text-gray-900 border-black'}  rounded-lg border `} 
    placeholder={placeholder}
    value={value}
    onChange={handleTextAreaChange}>

    </textarea>

  )
}

export default TextArea