import dayjs from 'dayjs'
import { userType } from '../types/Types'
import { useQueryClient } from '@tanstack/react-query'
import { useUpdateUserStatusMutation } from '../api/services'
import { operateWithCallOption } from '../services/functions'
import PlanMeetingModal from './admin/PlanMeetingModal'
import { ModalContext } from '../../components/contexts/ModalContext'
import { useContext } from 'react'

type CaseInterfaceType = {
    selectedCustomer?: userType
    deleteNewUser: () => void
    deleteExistingUser: () => void
}

export default function CustomerCaseInterface({
    selectedCustomer,
    deleteNewUser,
    deleteExistingUser,
}: CaseInterfaceType) {
    const queryClient = useQueryClient()
    const { mutate: updateCase } = useUpdateUserStatusMutation(queryClient)
    const handleButtonClick = (status: string) => {
        updateCase({ uid: selectedCustomer?._id ?? '', status: status })
    }

    const modalCon = useContext(ModalContext)

    return (
        <div className="grow flex flex-col p-16 rounded-none bg-portal-gray gap-4">
            {selectedCustomer && (
                <>
                    <div className="flex flex-col">
                        <p className="text-lg font-semibold">
                            {selectedCustomer?.personal.first_name}{' '}
                            {selectedCustomer?.personal.last_name}
                        </p>
                        <p className="text-sm">
                            Letztes Update:
                            {' ' +
                                dayjs(selectedCustomer?.updated_at).format(
                                    'DD.MM.YYYY HH:mm'
                                )}{' '}
                            Uhr
                        </p>
                        <p className="pt-4">
                            {selectedCustomer?.personal.first_name}{' '}
                            {selectedCustomer?.personal.last_name}
                        </p>
                        <p className="pt-0">
                            {selectedCustomer?.personal.adress?.street}{' '}
                            {selectedCustomer?.personal.adress?.nr}
                        </p>
                        <p className="pt-0">
                            {selectedCustomer?.personal.adress?.plz}{' '}
                            {selectedCustomer?.personal.adress?.city}
                        </p>
                        <p className="text-lg font-semibold pt-4">
                            Telefon: {selectedCustomer?.personal.tel}
                        </p>
                        <p>
                            Rückruf:{' '}
                            {operateWithCallOption(
                                selectedCustomer.personal.callOption
                            )}
                        </p>
                    </div>
                    <div className="grow" />
                    {selectedCustomer.status !== 'taken' && (
                        <>
                            {selectedCustomer?.cases.every(
                                (obj) => obj.status === 'new'
                            ) && (
                                <button
                                    className="btn btn-secondary w-full"
                                    onClick={() => handleButtonClick('taken')}>
                                    Mandant bleibt
                                </button>
                            )}
                            <button
                                className="btn btn-primary w-full relative"
                                onClick={() => modalCon?.trigger(11)}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className=" absolute left-4 w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                                    />
                                </svg>
                                Telefonat im Kalender planen
                            </button>
                            <div className="flex gap-4 w-full">
                                {selectedCustomer?.status === 'new' && (
                                    <button
                                        className="btn btn-accent text-base-100 w-1/2"
                                        onClick={() =>
                                            handleButtonClick('waitlist')
                                        }>
                                        Warteliste
                                    </button>
                                )}
                                <button
                                    className="btn bg-gray-300 text-base-100 grow"
                                    onClick={deleteNewUser}>
                                    Anfrage löschen
                                </button>
                            </div>
                        </>
                    )}
                    {selectedCustomer.status === 'taken' && (
                        <button
                            className="btn bg-gray-300 text-base-100"
                            onClick={deleteExistingUser}>
                            Mandant löschen
                        </button>
                    )}
                </>
            )}
            <PlanMeetingModal modalId={11} customer={selectedCustomer}/>
        </div>
    )
}
