import Hero from '../../components/general/Hero'
import Seperator2 from '../../components/general/Seperator2'
import ContactFormWrapper from '../../components/general/ContactFormWrapper'
import Maps from '../../components/general/Maps'
import DropDownArrow2 from '../../components/general/DropDownArrow2'
import AboutImgText from '../../components/general/AboutImgText'
import AboutSlider from '../../components/general/AboutSlider'
import { getAboutUsData, getHowItWorksData } from '../../hooks/cmsApiCalls'
import { useQuery } from '@tanstack/react-query'
import ErrorPage from '../404/ErrorPage'
import Loading from '../../components/common/Loading'
import BaseSeo from '../../components/layout/BaseSeo'
import HowItWorks from '../../components/general/HowItWorks'
import DropdownWrapper from '../../components/general/DropdownWrapper'

export type UeberUnsType = {
    loadingFunc: () => void
}

function UeberUns() {
    const { error, data, isLoading } = useQuery({
        queryKey: ['aboutUsData'],
        queryFn: getAboutUsData,
    })
    const {
        error: howItWorksError,
        data: howItWorksData,
        isLoading: howItWorksLoading,
    } = useQuery({
        queryKey: ['howItWorksData'],
        queryFn: getHowItWorksData,
    })


    return (
        <>
            {(error || howItWorksError) && <ErrorPage />}
            {(isLoading || howItWorksLoading) && <Loading />}
            {data && howItWorksData && (
                <>
                    <BaseSeo seo={data.attributes?.seo} />
                    <Hero
                        heading={String(data.attributes.hero_headline)}
                        subtitle={String(data.attributes.hero_subtitle)}
                        heroImg={String(data.attributes.hero_img.data.attributes.url)}
                    />
                    <AboutImgText content={data.attributes} />
                    <div className="lg:block hidden w-full">
                        <Seperator2 content={data.attributes.separator}/>
                    </div>
                    <HowItWorks content={howItWorksData} homepage={true} bottomDistanceToBig />
                    <DropdownWrapper content={data?.attributes?.about_faq || undefined} />
                    {/* <DropDownArrow2 faqs={data.attributes.about_faq.question_and_answer || []} /> */}
                    <AboutSlider content={data.attributes.about_slider} />
                    <Maps content={data.attributes} />
                    <ContactFormWrapper />
                </>
            )}
        </>
    )
}

export default UeberUns
