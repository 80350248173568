import { Link } from 'react-router-dom'
import BasePanelHeadline from '../BasePanelHeadline'
import dayjs from 'dayjs'
import { newCaseType } from '../../types/Types'
import { operateWithCallOption } from '../../services/functions'
import { ReactComponent as EdgeArrow } from './../../../res/News Edge Icon.svg'

type RequestListType = {
    classProps?: string
    scrollable?: boolean
    fullScreen?: boolean
    currentCaseId?: string
    requests?: newCaseType[]
}

export default function RequestList({
    requests,
    classProps,
    scrollable,
    fullScreen,
    currentCaseId,
}: RequestListType) {
    return (
        <div
            className={`flex flex-col items-center justify-start py-4 bg-portal-gray rounded-none relative ${classProps}`}>
            <EdgeArrow className="absolute top-0 left-0 h-8 w-8" />
            <BasePanelHeadline title={'Anfragen'} />
            <div
                className={`flex flex-col justify-start w-full pt-4 overflow-y-scroll`}>
                {requests?.length === 0 && (
                    <p className="text-center uppercase text-sm font-bold">
                        Keine neuen Anfragen
                    </p>
                )}
                {requests?.length !== 0 &&
                    requests
                        ?.sort((a, b) => {
                            return (
                                new Date(b.created_at).getTime() -
                                new Date(a.created_at).getTime()
                            )
                        })
                        .map((item, index) => (
                            <RequestListElement
                                userId={item.userId}
                                key={index}
                                request={item}
                                fullScreen={fullScreen ?? true}
                                currentCaseId={currentCaseId ?? ''}
                            />
                        ))}
                {requests?.length !== 0 && !scrollable && (
                    <Link
                        to={'/portal/anfragen'}
                        className="btn-sm btn-outline btn-secondary btn w-fit ml-24 mt-2 ">
                        alle Anfragen
                    </Link>
                )}
            </div>
        </div>
    )
}

type RequestListElementType = {
    userId: string
    request: newCaseType
    fullScreen: boolean
    currentCaseId: string
}

function RequestListElement({
    userId,
    request,
    fullScreen,
    currentCaseId,
}: RequestListElementType) {
    return (
        <Link
            to={'/portal/anfragen/' + userId + '/' + request._id}
            className={`flex flex-row relative hover:border-l-4 border-primary hover:bg-primary/10 py-2 justify-start ${
                fullScreen ? 'px-16' : 'px-12'
            } ${request._id === currentCaseId && 'bg-primary/10 border-l-4'}`}>
            <p className="text-3xl font-medium text-accent absolute top-0.5">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                    />
                </svg>
            </p>
            <div className="flex flex-col pl-8">
                <p className="font-semibold leading-4">
                    {dayjs(request.created_at).format('DD.MM.YYYY')} -{' '}
                    {request.title}
                </p>
                <p className="text-sm line-clamp-2">
                    Frist:{' '}
                    {dayjs(request?.deadline).isValid()
                        ? dayjs(request?.deadline).format('DD.MM.YYYY')
                        : 'keine Angabe'}{' '}
                    , Rückruf: {operateWithCallOption(request.callOption)},{' '}
                    {request.first_name} {request.last_name}
                </p>
            </div>
        </Link>
    )
}
