import axios from 'axios'
import { useEffect, useState } from 'react'
import { API } from '../../services/constants'
import { InheritContentParentType } from '../../types/Types'
import { useKeycloak } from '@react-keycloak/web'


export default function BasePortalHeader() {
    const [content, setContent] = useState<InheritContentParentType | null>(
        null
    )
    const { keycloak } = useKeycloak()

    useEffect(() => {
        axios
            .get(
                API.general +
                    '/inherit-content?populate=logo_white&populate=logo_black'
            )
            .then((res) => {
                if (res?.data?.data) {
                    setContent(res.data.data)
                }
            })
            .catch((err) => console.log(err))
    }, [])

    const handleLogout = () => {
        keycloak.logout({
            redirectUri: window.location.origin,
        })
    }

    return (
        <div className="h-24 w-full bg-primary absolute top-0 flex justify-center">
            <div className="h-full w-full max-w-screen-2xl flex justify-between items-center">
                <img
                    src={
                        API.img +
                        content?.attributes?.logo_white?.data?.attributes?.url
                    }
                    alt="Logo"
                    className="w-[180px]"
                />

                <button
                    className="text-2xl text-center uppercase tracking-wider text-secondary flex gap-4 items-center justify-center bg-primary border-none z-10"
                    onClick={handleLogout}>
                    <p className="normal-case text-base-100 font-normal">
                        Hallo, {keycloak?.idTokenParsed?.given_name || ''}
                    </p>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-8 h-8">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9"
                        />
                    </svg>
                </button>
            </div>
        </div>
    )
}
