import { Link } from 'react-router-dom'
import BasePanelHeadline from '../BasePanelHeadline'
import { Key, useEffect, useState } from 'react'
import { caseType2, personalType2, userType } from '../../types/Types'
import { motion } from 'framer-motion'
import { ReactComponent as EdgeArrow } from './../../../res/News Edge Icon.svg'
import dayjs from 'dayjs'

type CustomerListType = {
    selectedId?: string
    caseId?: string
    small?: boolean
    customer?: userType[]
}

export default function CustomerList({ selectedId, caseId, small, customer }: CustomerListType) {
    const displayedCustomer = customer?.sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    })

    return (
        <div className="w-1/3 max-w-[22rem] h-full bg-portal-gray rounded-none flex flex-col relative items-center py-4">
            <EdgeArrow className="absolute top-0 left-0 h-8 w-8" />
            <BasePanelHeadline title={'Mandanten'} classProps="pb-4" />
            <div className="flex flex-col w-full h-full overflow-y-scroll">
                {displayedCustomer?.length === 0 && (
                    <p className="text-center uppercase text-sm font-bold">
                        Noch Keine Fälle/Mandanten
                        <br />
                        <span className="text-xs font-normal normal-case">Aber keine Sorge, das wird schon noch</span>
                    </p>
                )}
                {displayedCustomer?.length !== 0 &&
                    displayedCustomer?.map((item, index) => (
                        <ListElement
                            key={index}
                            small={small}
                            status={item.status}
                            id={item._id}
                            mandant={item.personal}
                            cases={item.cases}
                            selectedId={selectedId ?? ''}
                            caseId={caseId ?? ''}
                        />
                    ))}
            </div>
        </div>
    )
}

type ListElementType = {
    id: string
    status: string
    mandant: personalType2
    cases: caseType2[]
    selectedId: string
    caseId?: string
    small?: boolean
}

function ListElement({ id, status, mandant, cases, selectedId, caseId, small }: ListElementType) {
    const [open, setopen] = useState<boolean>(false)

    const displayedCases = cases.sort((a, b) => {
        return new Date(b.created_at ?? '').getTime() - new Date(a.created_at ?? '').getTime()
    })

    useEffect(() => {
        if (selectedId === id && caseId) {
            setopen(true)
        }
    }, [caseId, id, selectedId])

    return (
        <div className={`flex flex-col w-full `}>
            <div
                className={`flex flex-row items-center justify-start px-8 py-1 gap-2 hover:bg-primary/10 hover:border-l-4 border-primary ${
                    selectedId === id && !caseId && 'bg-primary/10 border-l-4'
                }`}>
                {status === 'waitlist' && <div className={`h-4 w-4 rounded-full bg-accent`} />}
                {displayedCases?.length === 1 && status !== 'waitlist' && (
                    <div
                        className={`h-4 w-4 rounded-full ${
                            displayedCases[0].status === 'open'
                                ? 'bg-secondary'
                                : displayedCases[0].status === 'done'
                                ? 'bg-gray-400'
                                : 'bg-none border border-gray-400'
                        }`}
                    />
                )}
                {displayedCases?.length > 1 && status !== 'waitlist' && (
                    <>
                        {displayedCases.every((obj) => obj.status === 'new') ||
                        displayedCases.every((obj) => obj.status === 'open') ||
                        displayedCases.every((obj) => obj.status === 'done') ? (
                            <div
                                className={`h-4 w-4 rounded-full ${
                                    displayedCases[0].status === 'open'
                                        ? 'bg-secondary'
                                        : displayedCases[0].status === 'done'
                                        ? 'bg-gray-400'
                                        : 'bg-none border border-gray-400'
                                }`}
                            />
                        ) : (
                            <>
                                {displayedCases.find((obj) => obj.status === 'new') ? (
                                    <div className="relative w-4 h-4">
                                        <div className={`absolute h-4 w-4 rounded-full right-1 bg-none border border-gray-400`} />
                                        <div
                                            className={`absolute h-4 w-4 rounded-full left-1 z-10 ${
                                                displayedCases.find((obj) => obj.status === 'open')
                                                    ? 'bg-secondary'
                                                    : displayedCases.find((obj) => obj.status === 'done')
                                                    ? 'bg-gray-400'
                                                    : 'bg-none border border-gray-400'
                                            }`}
                                        />
                                    </div>
                                ) : displayedCases.find((obj) => obj.status === 'open') ? (
                                    <div className="relative w-4 h-4">
                                        <div className={`absolute h-4 w-4 rounded-full right-1 bg-secondary`} />
                                        <div
                                            className={`absolute h-4 w-4 rounded-full left-1 z-10 ${
                                                displayedCases.find((obj) => obj.status === 'new')
                                                    ? 'bg-none border border-gray-400'
                                                    : displayedCases.find((obj) => obj.status === 'done')
                                                    ? 'bg-gray-400'
                                                    : 'bg-secondary'
                                            }`}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </>
                )}
                <Link to={'/portal/mandanten/' + id} className="pl-2 text-lg truncate font-medium">
                    {mandant.first_name} {mandant.last_name}
                </Link>
                <div className="grow" />
                {!small && (
                    <motion.svg
                        initial={{
                            rotate: 0,
                        }}
                        animate={{
                            rotate: open ? 540 : 0,
                        }}
                        transition={{
                            type: 'ease',
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 cursor-pointer"
                        onClick={() => setopen(!open)}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </motion.svg>
                )}
            </div>
            {cases.map((item: caseType2, index: Key | null | undefined) => (
                <CaseListElement key={index} open={open} caseData={item} comparedID={selectedId === id} caseId={caseId ?? ''} mandantId={id} />
            ))}
        </div>
    )
}

type CaseListElementType = {
    open: boolean
    comparedID: boolean
    caseData: caseType2
    caseId: string
    mandantId: string
}

function CaseListElement({ open, comparedID, caseData, caseId, mandantId }: CaseListElementType) {
    return (
        <motion.div
            initial={{
                height: 0,
            }}
            animate={{
                height: open ? '' : 0,
            }}
            className="overflow-hidden">
            <Link
                to={'/portal/mandanten/' + mandantId + '/' + caseData._id}
                className={`flex flex-row w-full gap-2 px-14 items-center justify-start py-1 hover:bg-primary/10 hover:border-l-4 border-primary cursor-pointer ${
                    comparedID && caseId === caseData._id && 'bg-primary/10 border-l-4'
                }`}>
                <div
                    className={`h-4 w-4 rounded-full left-1 z-10 ${
                        caseData.status === 'open' ? 'bg-secondary' : caseData.status === 'done' ? 'bg-gray-400' : 'bg-none border border-gray-400'
                    }`}
                />
                <p className={`truncate ${caseData.status === 'done' && 'text-gray-400'}`}>Fall {dayjs(caseData.created_at).format('DD.MM.YYYY')}</p>
            </Link>
        </motion.div>
    )
}
