const initialInfoData = () => {
    return {
        screenGeschwindigkeitsverstoss: {
            ort: '',
            fahrzeugtyp: '',
            geschwindigkeitsueberschreitung: '',
            oft: '',
            fuehrerschein: '',
            note: '',
            zeugenfragebogen: '',
            anhoerungsbogen: '',
            bussgeldbescheid: '',
            datenschutz: '',
            bearbeitungsfrist: '',
        },
        screenAlkoholverstoss: {
            artTest: '',
            wert: '',
            unfall: '',
            zeugenfragebogen: '',
            anhoerungsbogen: '',
            bussgeldbescheid: '',
            strafbefehl: '',
            anklageschrift: '',
            fuehrerschein_beschlagnahmt: '',
            fahrerlaubnis: '',
            datenschutz: '',
            bearbeitungsfrist: '',
        },
        screenRotlichtverstoss: {
            rot: '',
            unfall: '',
            gefaehrdung: '',
            fuehrerschein: '',
            note: '',
            zeugenfragebogen: '',
            anhoerungsbogen: '',
            bussgeldbescheid: '',
            datenschutz: '',
            bearbeitungsfrist: '',
        },
        screenPersonendaten: {
            firstName: '',
            lastName: '',
            phonenumber: '',
            email: '',
            erreichbarkeit: '',
            rechtsschutz: '',
            datenschutz: '',
            bearbeitungsfrist: '',
            street: '',
            nr: '',
            plz: '',
            city: '',
        },
        screenAbstandsverstoss: {
            abstandsunterschreitung: '',
            geschwindigkeit: '',
            fuehrerschein: '',
            note: '',
            zeugenfragebogen: '',
            anhoerungsbogen: '',
            bussgeldbescheid: '',
            datenschutz: '',
            bearbeitungsfrist: '',
        },
        screenHandyverstoss: {
            fahrzeugtyp: '',
            unfall: '',
            fuehrerschein: '',
            note: '',
            zeugenfragebogen: '',
            anhoerungsbogen: '',
            bussgeldbescheid: '',
            datenschutz: '',
            bearbeitungsfrist: '',
        },
        screenSonstigeDelikte: {
            note: '',
            datenschutz: '',
            bearbeitungsfrist: '',
        },
        screenIllegaleAutorennen: {
            unfall: '',
            fuehrerscheinentzug: '',
            entziehungsbeschluss: '',
            note: '',
            zeugenfragebogen: '',
            anhoerungsbogen: '',
            bussgeldbescheid: '',
            documentDate: '',
        },
        screenParkAbschleppmassnahmen: {
            note: '',
            zeugenfragebogen: '',
            anhoerungsbogen: '',
            bussgeldbescheid: '',
            documentDate: '',
        },
    }
}


export default initialInfoData