import React, { useContext, useEffect, useState } from 'react'
import BasePanelLayout from '../../components/BasePanelLayout'
import CustomerList from '../../components/admin/CustomerList'
import BasePanelChat from '../../components/BasePanelChat'
import { useParams } from 'react-router-dom'
import BaseCustomerCaseInterface from '../../components/BaseCustomerCaseInterface'
import BaseCaseInterface from '../../components/BaseCaseInterface'
import { getAllUser } from '../../api/apiCalls'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import ErrorPage from '../../../pages/404/ErrorPage'
import Loading from '../../../components/common/Loading'
import { caseType2, userType } from '../../types/Types'
import AddDocModal from '../../components/modal/AddDocModal'
import { ModalContext } from '../../../components/contexts/ModalContext'
import BaseActionCheckModal from '../../components/BaseActionCheckModal'
import {
    useDeleteCaseMutation,
    useDeleteSingleFileMutation,
    useDeleteUserMutation,
    useDenyCaseMutation,
    useUpdateCaseStatusMutation,
    useUpdateUserStatusMutation,
} from '../../api/services'

export default function AdminScreen3() {
    const { error, data, isLoading } = useQuery({
        queryKey: ['userData'],
        queryFn: getAllUser,
    })

    const modalCon = useContext(ModalContext)

    const { id, caseid } = useParams()

    const [selectedCustomer, setselectedCustomer] = useState<
        userType | undefined
    >()
    const [selectedCase, setselectedCase] = useState<caseType2 | undefined>()
    useEffect(() => {
        if (data) {
            setselectedCustomer(
                data?.find(
                    (obj: { _id: string | undefined }) => obj._id === id
                ) ?? data[0]
            )
        }
    }, [data, id])
    useEffect(() => {
        setselectedCase(
            selectedCustomer?.cases?.find((obj) => obj._id === caseid) ??
                selectedCustomer?.cases[0]
        )
    }, [caseid, selectedCustomer?.cases])

    // Anwalt - delete Case
    const queryClient = useQueryClient()
    const { mutate: deleteReq } = useDeleteCaseMutation(queryClient)
    const handleDeleteCase = () => {
        deleteReq({
            uid: selectedCustomer?._id ?? '',
            cid: selectedCase?._id ?? '',
        })
        modalCon?.trigger(0)
    }
    const { mutate: denyReq } = useDenyCaseMutation(queryClient)
    const handleDenyCase = () => {
        denyReq({
            uid: selectedCustomer?._id ?? '',
            cid: selectedCase?._id ?? '',
        })
        modalCon?.trigger(0)
    }

    // Anwalt - delete User
    const { mutate: deleteUser } = useDeleteUserMutation(queryClient)
    const handleDeleteUser = () => {
        deleteUser({ uid: selectedCustomer?._id ?? '' })
        modalCon?.trigger(3)
    }

    // Anwalt - update case or user status
    const { mutate: updateCase } = useUpdateCaseStatusMutation(queryClient)
    const { mutate: updateUserStatus } =
        useUpdateUserStatusMutation(queryClient)
    const handleButtonClick = (status: string) => {
        updateCase({
            uid: selectedCustomer?._id ?? '',
            cid: selectedCase?._id ?? '',
            status: status,
        })
        if (selectedCustomer?.status !== 'taken') {
            updateUserStatus({
                uid: selectedCustomer?._id ?? '',
                status: 'taken',
            })
        }
        if (status === 'done') {
            modalCon?.trigger(2)
        }
    }

    // Anwalt - delete Document
    const { mutate: deleteDocument } = useDeleteSingleFileMutation(queryClient)
    const [selectedDoc, setselectedDoc] = useState<string>()
    const handleDeleteDocument = () => {
        deleteDocument({ cid: selectedCase?._id ?? '', fid: selectedDoc ?? '' })
        modalCon?.trigger(6)
    }

    return (
        <>
            {error && <ErrorPage />}
            {isLoading && <Loading />}
            {!error && !isLoading && (
                <BasePanelLayout>
                    <CustomerList
                        selectedId={id === '1' ? data[0]?._id : id ?? ''}
                        caseId={caseid ?? ''}
                        customer={data}
                    />
                    {caseid ? (
                        <>
                            <BaseCaseInterface
                                ustatus={selectedCustomer?.status ?? ''}
                                selectedCase={selectedCase}
                                adminView
                                openModal={() => modalCon?.trigger(1)} // TODO Variablen Namen????
                                denyCase={() => modalCon?.trigger(0)}
                                deleteExistingCase={() => modalCon?.trigger(5)}
                                acceptCase={() => handleButtonClick('open')}
                                closeCase={() => modalCon?.trigger(2)}
                                customer={selectedCustomer}
                                setdoc={setselectedDoc}
                                deleteDocument={() => modalCon?.trigger(6)}
                            />
                            <BasePanelChat
                                senderName={
                                    selectedCustomer?.personal.first_name +
                                    ' ' +
                                    selectedCustomer?.personal.last_name
                                }
                                sender="rbac_anwalt"
                                status={selectedCase?.status !== 'open'}
                                chat={selectedCase?.chat}
                                uid={selectedCustomer?._id ?? ''}
                                cid={selectedCase?._id ?? ''}
                            />
                        </>
                    ) : (
                        <BaseCustomerCaseInterface
                            selectedCustomer={selectedCustomer}
                            deleteNewUser={() => modalCon?.trigger(3)}
                            deleteExistingUser={() => modalCon?.trigger(4)}
                        />
                    )}
                    <BaseActionCheckModal
                        modalId={0}
                        title={'Fall Ablehnen'}
                        buttonTitle={'Ablehnen'}
                        text={
                            'Sind Sie sicher, dass Sie den Fall ablehnen wollen? Der Fall wird unwiederbringlich aus der Datenbank gelöscht.'
                        }
                        function={handleDenyCase}
                    />
                    <AddDocModal
                        mid={1}
                        uid={selectedCustomer?._id ?? ''}
                        cid={selectedCase?._id ?? ''}
                        modalCon={modalCon}
                    />
                    <BaseActionCheckModal
                        modalId={2}
                        title={'Fall Schließen'}
                        buttonTitle={'Schließen'}
                        text={
                            'Sind Sie sicher, dass Sie den Fall schließen wollen? Der User wird über den Abschluss des Falls informiert. Sie können den Fall später nicht mehr öffnen oder Dateien/Nachrichten verändern.'
                        }
                        function={() => handleButtonClick('done')}
                    />
                    <BaseActionCheckModal
                        modalId={3}
                        title={'Mandant Ablehnen'}
                        buttonTitle={'Ablehnen'}
                        text={
                            'Sind Sie sicher, dass Sie den Mandanten ablehnen wollen? Der User wird unwiederbringlich aus der Datenbank gelöscht, inklusive aller Fälle, die er eröffnet hat.'
                        }
                        function={handleDeleteUser}
                    />
                    <BaseActionCheckModal
                        modalId={4}
                        title={'Mandant löschen'}
                        buttonTitle={'Löschen'}
                        text={
                            'Sind Sie sicher, dass Sie den Mandanten löschen wollen? Der User wird unwiederbringlich aus der Datenbank gelöscht, inklusive aller Fälle, die er eröffnet hat/hatte.'
                        }
                        function={handleDeleteUser}
                    />
                    <BaseActionCheckModal
                        modalId={5}
                        title={'Fall löschen'}
                        buttonTitle={'Löschen'}
                        text={
                            'Sind Sie sicher, dass Sie den Fall löschen wollen? Der Fall wird unwiederbringlich aus der Datenbank gelöscht.'
                        }
                        function={handleDeleteCase}
                    />
                    <BaseActionCheckModal
                        modalId={6}
                        title={'Dokument löschen'}
                        buttonTitle={'Löschen'}
                        text={
                            'Sind Sie sicher, dass Sie dieses Dokument löschen wollen? Es wird unwiederbringlich aus der Datenbank und dem File-Speicher entfernt.'
                        }
                        function={handleDeleteDocument}
                    />
                </BasePanelLayout>
            )}
        </>
    )
}
