import { HashLink } from 'react-router-hash-link'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { API } from '../../services/constants'

type footerType = {
    logo: {
        data: {
            id: number
            attributes: {
                name: string
                alternativeText: string
                caption: string
                url: string
            }
        }
    }
    address: string
    phone: string
    mail: string
    facebookUrl: string
    instagramUrl: string
}

export default function Footer() {
    const [footer, setfooter] = useState<footerType | undefined>(undefined)

    useEffect(() => {
        axios
            .get(API.general + '/footer?populate=*')
            .then((res) => setfooter(res?.data?.data?.attributes))
            .catch((err) => console.log(err))
    }, [])

    return (
        <div className="w-full bg-slate-950  flex flex-col text-white pb-28 pt-16 md:py-16 px-6 md:px-16 gap-8">
            <img src={API.img + footer?.logo?.data?.attributes?.url} alt="" className="md:hidden w-[200px]" />
            <div className="w-full flex flex-row justify-between items-center text-sm max-w-screen-xl mx-auto gap-8">
                <div className="hidden md:block ">
                    <img src={API.img + footer?.logo?.data?.attributes?.url} alt="" className="mb-4 w-[200px]" />

                    <div className="flex gap-4">
                        <a href={footer?.facebookUrl} target="_blank" rel="noreferrer">
                            <svg className="w-6 h-6" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 337.87 337.87">
                                <path
                                    className="fill-white"
                                    d="m219.67,77.87h23.47V26.79l-35.35-.04c-50.07,0-73.84,29.8-73.84,72.23v40.19h-39.21v48.52h39.21v123.42h58.82v-123.42h39.21l11.14-48.51h-50.35v-33.13c0-13.57,7.41-28.19,26.9-28.19Z"
                                />
                            </svg>
                        </a>
                        <a href={footer?.instagramUrl} target="_blank" rel="noreferrer">
                            <svg className="w-6 h-6" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 337.87 337.87">
                                <path
                                    className="fill-white"
                                    d="m25.5,102.94c2.52-13.11,4.86-26.27,12.01-37.93,16.3-26.6,41.78-36.81,71.17-37.52,40.07-.98,80.2-.96,120.27.02,27.21.66,51.42,9.62,67.99,33.2,10.66,15.18,14.64,32.56,14.91,50.53.58,38.51.85,77.05-.03,115.56-.71,31.01-11.49,57.47-40.53,73.23-13.41,7.28-28.11,10.45-43.11,10.62-39.56.45-79.15.84-118.7-.06-29.68-.68-55.46-10.7-71.95-37.57-7.15-11.66-9.49-24.82-12.01-37.93V102.94Zm143.54-48.31c-19.65,0-39.32-.37-58.96.09-30.69.72-49.57,15.1-55.15,41.53-1.19,5.61-2.07,11.41-2.1,17.12-.18,37.21-.68,74.44.08,111.63.76,37.02,19.47,56.81,55.18,58.13,40.33,1.48,80.72,1.36,121.06.02,29.74-.98,47.42-15.3,53.11-41,1.02-4.59,1.98-9.31,2-13.98.22-42.42,2.54-84.9-1.28-127.25-2.27-25.08-19.07-41.47-44.07-44.9-23.22-3.19-46.59-.92-69.86-1.4Z"
                                />
                                <path
                                    className="fill-white"
                                    d="m242.96,169.48c-.12,39.97-33.29,72.83-74.02,73.32-40.86.49-74.75-33.24-74.63-74.27.12-40.34,34.55-74.08,74.8-73.32,41.3.78,73.97,33.63,73.85,74.27Zm-27.56-.36c.04-25.44-20.9-46.28-46.65-46.41-25.58-.13-46.84,20.81-46.88,46.19-.04,25.44,20.9,46.28,46.65,46.42,25.58.13,46.84-20.81,46.88-46.19Z"
                                />
                                <path
                                    className="fill-white"
                                    d="m244.98,75.8c9.86-.13,18.2,8.04,18.14,17.76-.05,9.32-8.05,17.31-17.6,17.58-9.83.28-18.34-7.73-18.52-17.42-.18-9.79,7.85-17.79,17.97-17.91Z"
                                />
                            </svg>{' '}
                        </a>
                    </div>
                </div>
                <div className="flex gap-4 sm:gap-16 md:gap-24">
                    <div>
                        <table className="">
                            <tr>
                                <td className="pr-2 flex items-start justify-start">Adresse:</td>
                                <td>{footer?.address}</td>
                            </tr>
                            <tr>
                                <td>Telefon:</td>
                                <td>{footer?.phone}</td>
                            </tr>
                            <tr>
                                <td>E-Mail:</td>
                                <td>
                                    <a href={'mailto:' + footer?.mail} className="underline">
                                        {footer?.mail}
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="hidden md:block text-sm ">
                        <HashLink to={'/datenschutz#_'} className="pr-4">
                            Datenschutz
                        </HashLink>{' '}
                        <br />
                        <HashLink to={'/impressum#_'}>Impressum</HashLink>
                        <br />
                    </div>

                    <div className="pb-4 md:block hidden">
                        <HashLink to={'/news#_'}>News</HashLink> <br />
                        <HashLink to={'/FAQ#_'}>FAQ</HashLink>
                    </div>
                    <div className="md:hidden">
                        <div className="pb-4 block md:hidden">
                            <HashLink to={'/news#_'}>News</HashLink> <br />
                            <HashLink to={'/FAQ#_'}>FAQ</HashLink>
                        </div>
                        <div className="flex md:hidden gap-4">
                            <a href={footer?.facebookUrl} target="_blank" rel="noreferrer">
                                <svg className="w-6 h-6" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 337.87 337.87">
                                    <path
                                        className="fill-white"
                                        d="m219.67,77.87h23.47V26.79l-35.35-.04c-50.07,0-73.84,29.8-73.84,72.23v40.19h-39.21v48.52h39.21v123.42h58.82v-123.42h39.21l11.14-48.51h-50.35v-33.13c0-13.57,7.41-28.19,26.9-28.19Z"
                                    />
                                </svg>{' '}
                            </a>
                            <a href={footer?.instagramUrl} target="_blank" rel="noreferrer">
                                <svg className="w-6 h-6" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 337.87 337.87">
                                    <path
                                        className="fill-white"
                                        d="m25.5,102.94c2.52-13.11,4.86-26.27,12.01-37.93,16.3-26.6,41.78-36.81,71.17-37.52,40.07-.98,80.2-.96,120.27.02,27.21.66,51.42,9.62,67.99,33.2,10.66,15.18,14.64,32.56,14.91,50.53.58,38.51.85,77.05-.03,115.56-.71,31.01-11.49,57.47-40.53,73.23-13.41,7.28-28.11,10.45-43.11,10.62-39.56.45-79.15.84-118.7-.06-29.68-.68-55.46-10.7-71.95-37.57-7.15-11.66-9.49-24.82-12.01-37.93V102.94Zm143.54-48.31c-19.65,0-39.32-.37-58.96.09-30.69.72-49.57,15.1-55.15,41.53-1.19,5.61-2.07,11.41-2.1,17.12-.18,37.21-.68,74.44.08,111.63.76,37.02,19.47,56.81,55.18,58.13,40.33,1.48,80.72,1.36,121.06.02,29.74-.98,47.42-15.3,53.11-41,1.02-4.59,1.98-9.31,2-13.98.22-42.42,2.54-84.9-1.28-127.25-2.27-25.08-19.07-41.47-44.07-44.9-23.22-3.19-46.59-.92-69.86-1.4Z"
                                    />
                                    <path
                                        className="fill-white"
                                        d="m242.96,169.48c-.12,39.97-33.29,72.83-74.02,73.32-40.86.49-74.75-33.24-74.63-74.27.12-40.34,34.55-74.08,74.8-73.32,41.3.78,73.97,33.63,73.85,74.27Zm-27.56-.36c.04-25.44-20.9-46.28-46.65-46.41-25.58-.13-46.84,20.81-46.88,46.19-.04,25.44,20.9,46.28,46.65,46.42,25.58.13,46.84-20.81,46.88-46.19Z"
                                    />
                                    <path
                                        className="fill-white"
                                        d="m244.98,75.8c9.86-.13,18.2,8.04,18.14,17.76-.05,9.32-8.05,17.31-17.6,17.58-9.83.28-18.34-7.73-18.52-17.42-.18-9.79,7.85-17.79,17.97-17.91Z"
                                    />
                                </svg>{' '}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="md:hidden " />
            <div className="text-sm md:hidden">
                <HashLink to={'/datenschutz#_'} className="pr-4">
                    Datenschutz
                </HashLink>
                <HashLink to={'/impressum#_'} className="pr-4">
                    Impressum
                </HashLink>
            </div>
        </div>
    )
}
