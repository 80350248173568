import { useEffect, useMemo, useRef, useState } from 'react'
import { InheritContentParentType, NewsContentParentType, NewsElementType } from '../../types/Types'
import NewsCollapsed from './NewsCollapsed'
import { MdArrowForwardIos } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { API } from '../../services/constants'
import BaseSeo from '../layout/BaseSeo'

export type PostType = {
    id: string
    headline: string
    content: string
    category: string
    date?: string
}

type NewsBodyType = {
    content?: NewsContentParentType
    logos?: InheritContentParentType
    entries?: NewsElementType[]
}

function NewsBody({ content, logos, entries }: NewsBodyType) {
    const [openCategory, setOpenCategory] = useState<string>('')
    const [openCategoryWithoutData, setOpenCategoryWithoutData] = useState<string>('')
    const [parameterUrlLoaded, setparameterUrlLoaded] = useState<boolean>(false)
    const [selectedPostSlug, setSelectedPostSlug] = useState<string | null>(null)
    const newsBodyRef = useRef<HTMLDivElement>(null)

    const { newsSlug } = useParams()
    const navigate = useNavigate()

    const newsByCategory = useMemo(
        () =>
            entries
                ? entries.reduce((acc, item) => {
                      acc[item.attributes.content.category] = acc[item.attributes.content.category] || []
                      acc[item.attributes.content.category].push(item)
                      return acc
                  }, {} as { [category: string]: NewsElementType[] })
                : {},
        [entries]
    )
    useEffect(() => {
        setOpenCategoryWithoutData('')
        setparameterUrlLoaded(false)
    }, [])

    // function, if less than 5 posts left
    const postsToDisplay = useMemo<NewsElementType[]>(() => {
        if (!entries) return []
        const categoryPosts = newsByCategory[openCategory === '' ? openCategoryWithoutData : openCategory] || []
        const selectedIndex = categoryPosts.findIndex((post) => post.attributes.slug === selectedPostSlug)

        if (newsSlug && !parameterUrlLoaded) {
            setparameterUrlLoaded(true)
            setOpenCategoryWithoutData('')
            const foundPost = entries?.find((post) => post.attributes.slug === newsSlug)

            if (foundPost) {
                setSelectedPostSlug(foundPost.attributes.slug)
                setOpenCategoryWithoutData(foundPost.attributes.content.category)
                return [foundPost]
            }
            return []
        }
        // if no category is selected
        if (openCategory === '' && openCategoryWithoutData === '') {
            return entries?.slice(0, 5) || []
        }
        if (selectedIndex >= 0) {
            return [categoryPosts[selectedIndex]]
        }
        return categoryPosts
    }, [newsByCategory, openCategory, openCategoryWithoutData, newsSlug, parameterUrlLoaded, selectedPostSlug, entries])

    useEffect(() => {
        if (newsSlug && postsToDisplay.length === 1) {
            const element = document.getElementById(newsSlug)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [newsSlug, postsToDisplay])

    // sort by date and id in each category
    const toggleCategory = (category: string) => {
        setOpenCategory(openCategory === category ? '' : category)
        setOpenCategoryWithoutData('')
        // Reset the selected post ID when switching categories
        setSelectedPostSlug(null)
    }

    const currentSeoConfig = useMemo(() => {
        if (postsToDisplay.length === 1) {
            return <BaseSeo seo={postsToDisplay[0].attributes.seo ?? content?.attributes?.seo} />
        }
        return <BaseSeo seo={content?.attributes?.seo} />
    }, [postsToDisplay, content])

    return (
        <div className="max-w-screen-xl px-2 w-full mx-auto h-fit mb-16 pb-8" ref={newsBodyRef}>
            {currentSeoConfig}
            <h4 className="text-secondary text-2xl md:text-4xl px-8 xl:px-0 py-12">Artikel aus dem Verkehrsrecht</h4>
            <div className="flex flex-col md:flex-row md:space-x-12 w-full space-y-12 md:space-y-0">
                <div className="w-10/12 md:w-2/5 text-primary text-lg font-medium px-6 md:px-0">
                    {Object.entries(newsByCategory).map(([category, posts]) => (
                        <div key={category}>
                            <button
                                onClick={() => toggleCategory(category)}
                                className="flex w-full py-3 items-center gap-2 transform transition duration-300 ease-in-out border-black/70 border-b text-left">
                                <MdArrowForwardIos
                                    className={`transform transition-transform duration-500 ease-in-out w-6 h-6 text-secondary  ${
                                        openCategory === category || openCategoryWithoutData === category ? 'rotate-90' : ''
                                    }`}
                                />
                                {category} ({posts.length})
                            </button>
                            {(openCategory === category || openCategoryWithoutData === category) && (
                                <ul>
                                    {posts.map((post) => (
                                        <li
                                            key={post.attributes.slug}
                                            onClick={() => {
                                                setSelectedPostSlug(post.attributes.slug)
                                                navigate(`/news/${post.attributes.slug}`)
                                            }}
                                            className={`cursor-pointer line-clamp-1 ${
                                                newsSlug === post.attributes.slug ? 'ml-2 mr-4' : 'ml-6 hover:ml-4 hover:mr-2'
                                            } py-1 border-b transition-transform duration-200 ease-in-out `}>
                                            {post.attributes.content.headline}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>
                <div className="w-full md:w-3/5 space-y-16 px-6 md:px-0 ">
                    {postsToDisplay &&
                        postsToDisplay?.map((post) => (
                            <div key={post.id} id={post.attributes.slug} className="scroll-mt-24">
                                <NewsCollapsed
                                    logo={API.img + logos?.attributes?.logo_black?.data?.attributes?.url}
                                    headline={post.attributes.content.headline}
                                    content={post.attributes.content.content}
                                    date={post.attributes.content.date}
                                    id={post.attributes.slug}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default NewsBody
