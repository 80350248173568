import { ReactNode } from 'react'

type BaseViolationFormType = {
    children: ReactNode
}

export default function BaseViolationForm({ children }: BaseViolationFormType) {
    return (
        <div className="flex flex-col text-lg p-4 pl-12 overflow-y-scroll">
            {children}
        </div>
    )
}
