import React from 'react'
import { HashLink } from 'react-router-hash-link'

export default function ErrorPage() {
    return (
        <div className="flex flex-col w-full grow justify-center items-center p-2">
            <p className="text-xl underline pb-2 ">ErrorPage</p>
            <HashLink to="/">Zurück zur Homepage...</HashLink>
        </div>
    )
}
