export const API = {
    general: (process.env.REACT_APP_CMS_URL ?? 'https://hansolo.cms.wicode.io') + '/api',
    img: process.env.REACT_APP_CMS_URL ?? 'https://hansolo.cms.wicode.io',
}
export const BACKEND = process.env.REACT_APP_BACKEND_URL ?? 'https://hansolo.backend.wicode.io'
//export const BACKEND = 'http://localhost:3030'

export const CDS = {
    URL: process.env.REACT_APP_CDS_URL ?? 'https://cds.wicode.io/contact',
    API_Key: process.env.REACT_APP_CDS_API_KEY ?? 'c7e1e2389b9620f65da33dfa817d224492c31a4142b94b818889557a4e1c9a00',
}
