import React from 'react'
import '../../style/markdown.css'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

type RichTextType = {
    richText?: string
    className?: string
}

function RichTextBody({ richText, className }: RichTextType) {
    return (
        <div className={className}>
            <ReactMarkdown remarkPlugins={[remarkGfm]} children={richText} className={'markdown'} />
        </div>
    )
}

export default RichTextBody
